import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {PagesComponent} from "./pages.component";
import {AuthGuard} from "../core/guards/auth-guard";
import {Role} from "../shared/enums/role.enum";
import {DashboardServicosComponent} from "./dashboard-servicos/dashboard-servicos.component";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'dashboard-servicos',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.DASHBOARD])],
        component: DashboardServicosComponent,
      },
      {
        path: 'real-time',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.TEMPO_REAL])],
        loadChildren: () => import('./real-time/real-time.module').then(m => m.RealTimeModule)
      },
      {
        path: 'pbt-configuration',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.PBT_CONFIG, Role.PBT_CONFIG_ALERT_CONFIG, Role.PBT_CONFIG_ALERT_REPORT])],
        loadChildren: () => import('./pbt-configuration/pbt-configuration.module').then(m => m.PbtConfigurationModule)
      },
      {
        path: 'events',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.EVENTOS])],
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'reports',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.RELATORIOS])],
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'status-monitoring',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.STATUS_EQUIPAMENTOS, Role.INVID])],
        loadChildren: () => import('./status-monitoring/status-monitoring.module').then(m => m.StatusMonitoringModule)
      },
      {
        path: 'user-auth',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR])],
        loadChildren: () => import('./user-auth/user-auth.module').then(m => m.UserAuthModule)
      },
      {
        path: 'monitoring',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.OCORRENCIA])],
        loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule)
      },
      {
        path: 'triagem',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM])],
        loadChildren: () => import('./triagem/triagem.module').then(m => m.TriagemModule)
      },
      {
        path: 'exportacao',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM])],
        loadChildren: () => import('./exportacao/exportacao.module').then(m => m.ExportacaoModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'dashboard',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.TEMPO_REAL])],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'configuracao-equipamento',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR])],
        loadChildren: () => import('./configuracao-equipamento/configuracao-equipamento.module').then(m => m.ConfiguracaoEquipamentoModule)
      },
      {
        path: 'general-config',
        canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR])],
        loadChildren: () => import('./general-config/general-config.module').then(m => m.GeneralConfigModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
