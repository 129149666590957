import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MonitoringRoutingModule} from './monitoring-routing.module';
import { MotiveOccurrenceComponent } from './motive-occurrence/motive-occurrence.component';
import { OccurrenceComponent } from './occurrence/occurrence.component';
import { NewOccurrenceComponent } from './new-occurrence/new-occurrence.component';
import { SharedModule } from "../../shared/shared.module";

import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {TableModule} from 'primeng/table';
import {CardModule} from "primeng/card";
import {FieldsetModule} from "primeng/fieldset";
import {MessageService} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';
import {ToolbarModule} from 'primeng/toolbar';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';


@NgModule({
  declarations: [
    MotiveOccurrenceComponent,
    OccurrenceComponent,
    NewOccurrenceComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MonitoringRoutingModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    DialogModule,
    FormsModule,
    TableModule,
    CardModule,
    FieldsetModule,
    DropdownModule,
    ToolbarModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule
  ],
  providers: [
    MessageService
  ]
})

export class MonitoringModule{ }
