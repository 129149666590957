import { Contract } from 'src/app/models/contract.model';
import { Component } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { ContractService } from 'src/app/service/contract.service';
import { Grupo } from 'src/app/models/grupo.model';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { GruposService } from 'src/app/service/grupos.service';

@Component({
  selector: 'app-register-group',
  templateUrl: './register-group.component.html',
  styleUrls: ['./register-group.component.scss']
})
export class RegisterGroupComponent {

  private _destroy$: Subject<void> = new Subject<void>();

  group: Grupo = new Grupo();

  contracts: Contract[] = [
  ]

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  constructor(
    private breadCrumb: BreadcrumbService,
    private contractService: ContractService,
    private groupService: GruposService,
    private messageService: MessageService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Administrador', icon: 'pi pi-users'},
      {label: 'Cadastrar grupo', icon: 'pi pi-th-large'}
    ]);
    this.getContracts();
  }

  getContracts() {
    this.contractService.getContracts()
      .pipe(
        takeUntil(this._destroy$),
        finalize(() =>{}),
      )
      .subscribe({
        next: (user) => this.contracts = user,
      });
  }

  contractChange(event: any){
    console.group('entrou')
    this.group.contract.id = event.value;
  }

  verifyGroup(group: Grupo): boolean {
    const variablesToCheck = {
      description: group.description,
      contractId: group.contract?.id

    }
    for (const chave in variablesToCheck) {
      if (!variablesToCheck.hasOwnProperty(chave)) {
        continue;
      }
      const valor = variablesToCheck[chave];

      if (valor === undefined || valor === null || valor === '' || valor.length<1) {
        return false;
      }
    }

    return true;
  }

  registerGroup(){
    this.groupService.registerGroup(this.group)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: () => {
        this.messageService.add({
          key: 'toastSuccess',
          severity: 'success',
          detail: 'Grupo cadastrado com sucesso!',
        });
      },
      error: (e) => {
        this.messageService.add({
          key: 'toastError',
          severity: 'error',
          summary: 'Error',
          detail: 'Erro ao tentar cadastrar grupo',
        });
      }
    })
  }

}
