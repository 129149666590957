<div class="px-6">
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-toast position="top-right" key="toastError"></p-toast>
  <div class="grid">
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Nome do grupo</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="group.description"
      pInputText/>
    </div>
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Contratos</h5>
      <p-dropdown
      [options]="contracts"
      (onChange)="contractChange($event)"
      optionLabel="reference"
      optionValue="id"
      placeholder="Selecionar"
      [style]="stylePattern"
      ></p-dropdown>
    </div>
    <div class="col-4">
      <button
      [disabled]="!verifyGroup(group)"
      (click)="registerGroup()"
      pButton
      class="block relative top-100 -translate-y-100"
      type="button"
      label="Salvar" ></button>
    </div>
  </div>
</div>
