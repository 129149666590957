import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DeviceService} from '../../../service/devices.service';

import {Map} from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import * as olProj from 'ol/proj';
import View from 'ol/View';
//@ts-ignore
import Vector3D from 'ol-ext/source/Vector3D';
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'real-time-heat-map3d',
  templateUrl: './heat-map3d.component.html',
  styleUrls: ['./heat-map3d.component.scss']
})
export class HeatMap3dComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  @Input() isInfoWindow: boolean = false;
  @Output() infoWindowData = new EventEmitter<any>();
  @Output() isInfoWindowChange = new EventEmitter<boolean>();

  data: any[] = [];
  features: any =
    {
      type: 'FeatureCollection',
      features: [],
    };
  map: any;
  vectorSource: any;
  vector: any;

  constructor(private deviceService: DeviceService) {
  }

  ngOnInit() {
    this.getData();
    this.loadMap();
    this.readData();
    this.onZoom();
    this.onClick();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  loadMap() {
    const layerOSM = new TileLayer({
      source: new OSM()
    });
    this.map = new Map({
      target: "map3d",
      controls: [],
      layers: [layerOSM],
      view: new View({
        center: [-41.389, -9.514],
        zoom: 6
      })
    });
  }

  getData() {

    this.deviceService.getDeviceMetrics()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => {
        this.data = data;
        for (let i = 0; i < this.data.length; i++) {
          if (Number(this.data[i].latitude) < 10 && Number(this.data[i].latitude) > -53
            && Number(this.data[i].longitude) < -29 && Number(this.data[i].longitude) > -80) {
            this.features.features.push({
                type: 'Feature', geometry: {
                  type: 'Polygon',
                  coordinates: [
                    [[Number(this.data[i].longitude) + 0.00001, Number(this.data[i].latitude) + 0.00001]],
                  ]
                }, properties: {
                  center: [
                    256335.48643200294,
                    6256355.867554382,
                    152.7132774902857
                  ],
                  id_: String(this.data[i].deviceId),
                  nb: Math.ceil(this.data[i].taxaOcupacao)
                }
              },
            );
          }
        }
      })
  }

  readData() {
    const feat = (new GeoJSON()).readFeatures(this.features);
    const map = this.map;
    feat.forEach(function (f) {
      f.getGeometry()?.transform('EPSG:4326', map.getView().getProjection());
    });
    this.map.getView().setCenter(olProj.transform([-40.883605, -20.015304], 'EPSG:4326', 'EPSG:3857'));
    this.vectorSource = new VectorSource();
    this.vectorSource.addFeatures(feat);
    this.vector = new Vector3D({
      source: this.vectorSource,
      style: new Style({
        stroke: new Stroke({
          width: 10,
          color: [255, 0, 0, .5]
        }),
        geometry: function (f) {
          return f.getGeometry();
        }
      }),
      height: 0,
      defaultHeight: 0
    });
    this.map.addLayer(this.vector);
  }

  onZoom() {
    let currentZoom = Number(this.map.getView().getZoom());
    let height: any;
    this.map.on('moveend', (e: any) => {
      let newZoom = this.map.getView().getZoom();
      if (currentZoom != newZoom && newZoom != 0) {
        currentZoom = Number(newZoom);
      }
      if (currentZoom <= 9) {
        height = function (f: any) {
          return f.get('nb') * (1000)
        };
        this.vector.animate({height: height});
      } else if (currentZoom <= 5) {
        height = function (f: any) {
          return f.get('nb') * (2000)
        };
        this.vector.animate({height: height});
      } else if (currentZoom > 9) {
        height = function (f: any) {
          return f.get('nb') * (currentZoom * 10)
        };
        this.vector.animate({height: height});
      }
    })
  }

  onClick() {
    this.map.on('click', (evt: any) => {
      const clickedFeature = this.vectorSource.getClosestFeatureToCoordinate(evt.coordinate);
      this.deviceService.getDeviceMetricsbyId(clickedFeature.get('id_'))
        .pipe(takeUntil(this._destroy$))
        .subscribe(metricsById => {
          this.isInfoWindow = true;
          this.infoWindowData.emit(metricsById);
          this.isInfoWindowChange.emit(this.isInfoWindow);
        })
    })
  }

}
