import {Component, OnDestroy, OnInit} from '@angular/core';
import {Functionality, FunctionalityDTO} from '../../../models/functionality.model';
import {FunctionalityService} from '../../../service/functionality.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../../../service/user.service";
import {User} from "../../../models/user.model";
import {finalize, map, Subject, takeUntil} from "rxjs";
import {RoutesEnum} from "../../../shared/enums/routes.enum";
import { MessageService } from 'primeng/api';
import {BreadcrumbService} from "../../../service/breadcrumb.service";
import { GruposService } from 'src/app/service/grupos.service';
import { Grupo } from 'src/app/models/grupo.model';
import { Role } from 'src/app/shared/enums/role.enum';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  loading: boolean = false;

  user: User = new User();

  isEdit: boolean = false;

  groups: Array<Grupo> = [];
  selectedGroups: any = [];

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  userPermissions: any = []

  userPermissionsData: FunctionalityDTO[] = []

  confirmPassword: string = '';

  isPasswordGenerated: boolean = false;

  constructor(
    private functionalityService: FunctionalityService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private gruposService: GruposService,
    private messageService: MessageService,
    private breadCrumb: BreadcrumbService,
  ) {
    this.route.queryParams.subscribe(async params => {
      const state = this.router.getCurrentNavigation()?.extras.state;
      state ? this.user = state['user'] : '';
      state ? (state['user'] ? (this.isEdit = true) : (this.isEdit = false)) : (this.isEdit = false);
    });
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Administrador', icon: 'pi pi-users'},
      {label: `${this.isEdit ? 'Editar Usuário' : 'Registrar Usuário'}`, icon: 'pi pi-user-plus'}
    ]);
    if (!this.isEdit) {
      this.user = new User();
    }else{
      if(this.user.groups){
        this.user.groups.forEach((group: any) => {
          this.selectedGroups.push(group.id);
        });
        this.getUserPermissions();
      }
    }
    this.getGroups();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getGroups(): void {
    // const partGroup = [
    //   {
    //     contractId: 0,
    //     description: 'Todos',
    //     id: -1,
    //     showonmap: true,
    //     sinc_with_central_web: true,
    //   }
    // ];

    this.gruposService.getGrupos()
      .pipe(takeUntil(this._destroy$))
      .subscribe((partGroupAPI) => this.groups = partGroupAPI);
      // .subscribe((partGroupAPI) => this.groups = partGroup.concat(partGroupAPI));
  }

  contractChange(e: any) {
    if(!this.loading){
      this.selectedGroups = [];
      if (e.value.length<1) {
        this.selectedGroups = [];
        this.userPermissions = [];
        this.userPermissionsData = [];
      } else {
        if (e.value.includes(-1)) { //entrará aqui caso marque 'todos'. Por hora essa opção não está liberada
          // this.user.groups = [-1];
          // this.getUserPermissions()
        } else {
          this.selectedGroups = e.value;
          this.getUserPermissions();
        }
      }
    }
  }
  getUserPermissions() {
    this.loading = true;
    this.userPermissions = [];
    this.userPermissionsData = [];
    this.functionalityService.getRoles()
      .pipe(takeUntil(this._destroy$))
      .subscribe(roles => {
        this.userPermissionsData = roles;
        for(const element of this.selectedGroups) {
          let mapRoles: any = [];
          // @ts-ignore
          const userGroup = this.user.userGroups.find((group: any) => group.groupId === element);
          this.userPermissionsData.forEach((role) => {

            if (userGroup !== undefined) {
              // @ts-ignore
              const foundRole = userGroup.roles.find((r: any) => r.id === role.id);
              if (foundRole) {
                role.active = foundRole.active;
              } else {
                role.active = false;
              }
            } else {
              role.active = false;
            }
            role = this.functionalityService.mapFunctionalityDTOToFunctionality(role)
            mapRoles.push(role);
          });
          const data = {
            groupId: element,
            roles: mapRoles
          }
          this.userPermissions.push(data)
        }
        this.loading = false;
      })
  }

  registerUser() {
    this.user.phone = this.user.phone.replace(/\D/g, '');
    this.user.userGroups = [];
    for(let i = 0; i < this.userPermissions.length; i++){
      let data: any = {
        groupId: this.userPermissions[i].groupId,
        roles: []
      }
      this.userPermissions[i].roles.forEach((role: any) => {
        if (role.status) {
          role = this.functionalityService.mapFunctionalityToFunctionalityDTO(role)
          data.roles.push(role)
        }
      });
      this.user.userGroups?.push(data);
    }
    if (this.isEdit) {
      delete this.user.password;
      delete this.user.accessToken;
      delete this.user.refreshToken;
      delete this.user.groups;
      this.userService.update(this.user)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () =>{
            setTimeout(() => {
              this.messageService.add({
                key: 'toastSuccess',
                severity: 'success',
                detail: 'Usuário editado com sucesso!',
              });
            }, 2000);
            this.router.navigate([RoutesEnum.USER_AUTH_USERS])
          },
          error: (e) => {
            this.messageService.add({
              key: 'toastError',
              severity: 'error',
              summary: 'Error',
              detail: 'Erro ao tentar editar usuário',
            });
          },
          complete: () => this.user = new User()
        });
    } else {
      this.userService.register(this.user)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () =>{
            setTimeout(() => {
              this.messageService.add({
                key: 'toastSuccess',
                severity: 'success',
                detail: 'Usuário criado com sucesso!',
              });
            }, 2000);
            this.router.navigate([RoutesEnum.USER_AUTH_USERS])
          },
          error: (e) => {
            this.messageService.add({
              key: 'toastError',
              severity: 'error',
              summary: 'Error',
              detail: 'Erro ao tentar salvar usuário',
            });
          },
          complete: () => {
            this.user = new User();
            this.groups = [];
            this.selectedGroups = [];
          }
        });
    }
  }

  getGroupDesc(id: number){
    const find = this.groups.find((e: any) => e.id === id);
    return find?.description;
  }

  verifyName(name: any): boolean {
    const regex = /^[a-zA-Z\s]+$/;

    if(!name){
      return true;
    }

    if (!regex.test(name)) {
      return false;
    }

    return true;
  }

  verifyEmail(email: string) {
    const regex = /^[a-zA-Z0-9.+%-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

    if(!email){
      return true;
    }

    return regex.test(email);
  }

  verifyPhone(numero: any): boolean {
    const regex = /^\d{13}$/;
    if(!numero){
      return true;
    }

    return regex.test(numero.replace(/\D/g, ''));
  }

  verifyPassword(password: any): boolean {
    const tamanhoMinimo = 8;
    const regexMaiuscula = /[A-Z]/;
    const regexMinuscula = /[a-z]/;
    const regexNumero = /\d/;
    const regexEspecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if(!password){
      return true;
    }

    if (password.length < tamanhoMinimo) {
      return false;
    }

    if (!regexMaiuscula.test(password)) {
      return false;
    }

    if (!regexMinuscula.test(password)) {
      return false;
    }

    if (!regexEspecial.test(password)) {
      return false;
    }

    if (!regexNumero.test(password)) {
      return false;
    }

    return true;
  }

  generateRandomPassword(event: any) {
    if(event.checked){
      const caracteresEspeciais = "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?";
      const letrasMaiusculas = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const numeros = "1234567890";
      const letrasMinusculas = "abcdefghijklmnopqrstuvwxyz";
      const todosCaracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas;

      let senha = "";

      senha += letrasMaiusculas.charAt(Math.floor(Math.random() * letrasMaiusculas.length));
      senha += letrasMinusculas.charAt(Math.floor(Math.random() * letrasMinusculas.length));
      senha += caracteresEspeciais.charAt(Math.floor(Math.random() * caracteresEspeciais.length));
      senha += numeros.charAt(Math.floor(Math.random() * numeros.length));

      for (let i = 0; i < 4; i++) {
        senha += todosCaracteres.charAt(Math.floor(Math.random() * todosCaracteres.length));
      }

      senha = senha.split("").sort(function () {
        return 0.5 - Math.random();
      }).join("");

      this.user.password = senha;
      this.confirmPassword = senha;
      this.isPasswordGenerated = true;
    }else{
      this.user.password = undefined;
      this.confirmPassword = '';
      this.isPasswordGenerated = false;
    }
  }

  verifyUserEdit(user: User): boolean {
    for(let i = 0; i < this.userPermissions.length; i++){
      const find = this.userPermissions[i].roles.find((e: any) => e.status === true);
      if (find === undefined) {
        return false;
      }else{
        continue;
      }
    }
    const variablesToCheck = {
      userName: user.userName,
      email: user.email,
      phone: user.phone,

    }
    for (const chave in variablesToCheck) {
      if (!variablesToCheck.hasOwnProperty(chave)) {
        continue;
      }
      const valor = variablesToCheck[chave];

      if (valor === undefined || valor === null || valor === '' || valor.length<1) {
        return false;
      }
    }

    return true;
  }

}

