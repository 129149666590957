import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./core/guards/auth-guard";
import { FromCentralComponent } from './public/from-central/from-central.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [() => AuthGuard()],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  { path: 'fromcentral/:service/:token/:email/validacao', component: FromCentralComponent },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
