<p-dialog
class="dialog"
*ngIf="data.length > 0"
[(visible)]="show"
(visibleChange)="closeInfoDialog()"
[showHeader]="false"
[modal]="true"
[dismissableMask]="true"
[style]="{
  'max-width': '400px',
  'max-height': '100%',
  'border-radius': '10px'
}"
>
  <i class="pi pi-times" (click)="closeInfoDialog()">
  </i>
  <div [class]="i!==data.length-1 && data.length>1 ? 'border-bottom-1' : ''" *ngFor="let item of data let i = index">
    <div [class]="data.length>1 ? 'py-2' : ''">
      <div class="slider">
        <real-time-slider [data]="data"></real-time-slider>
      </div>
        <div [style]="{'cursor':'default'}" class="w-full flex justify-content-between mt-3">
          <h1 class="font-bold text-lg mb-4">{{item.deviceName}}</h1>
          <div class="icons">
            <real-time-data-icons
              (dialog)="showDialog(item.deviceName)"
              (videoDisplay)="openVideo(i)"
            ></real-time-data-icons>
          </div>
        </div>
        <real-time-info-window-text [data]="[item]"></real-time-info-window-text>
    </div>
  </div>
  </p-dialog>
  <div class="dialogDetails">
    <real-time-events-window
    [devices]="selectedDevicesByName"
    [(dialogShow)]="dialog"
    ></real-time-events-window>
  </div>
  <shared-video-dialog
    *ngIf="data[indexVideo]"
    [port]="data[indexVideo].port"
    [urlCam]="data[indexVideo].urlVideo"
    [(videoDisplay)]="videoDisplay"
  ></shared-video-dialog>
