import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {Router} from "@angular/router";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class InactivityService {
  timeoutId: any;
  timeout: number = 1800000; // 1800000ms = 30 minutos
  userActivity: Subject<void> = new Subject<void>();
  eventListeners: any[] = [];

  constructor(public router: Router,
              public userService: UserService) {
    this.setTimeout();
    this.userActivity.subscribe(() => this.setTimeout());
  }

  setTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => this.logout(), this.timeout);
  }

  setup() {
    const events = ['click', 'mousemove', 'keypress'];
    events.forEach(event => {
      const listener = () => this.userActivity.next();
      document.body.addEventListener(event, listener);
      this.eventListeners.push({event, listener});
    });
  }

  teardown() {
    this.eventListeners.forEach(({event, listener}) => {
      document.body.removeEventListener(event, listener);
    });
    this.eventListeners = [];
    clearTimeout(this.timeoutId);
  }

  logout() {
    this.teardown();
    this.userService.efetuarLogout();
  }
}
