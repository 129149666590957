export class MotiveOccurrence {
  id!: number;
  groupId!: number;
  description!: string;
  type!: MotiveOccurrenceType;
  serviceTime!: number;
}

export enum MotiveOccurrenceType {
  technical = 'technical',
  nonTechnical = 'nonTechnical'
}
