export class OccurrenceFilterModel {

  groupId!: string;
  type!: string;
  operation!: string;
  rejected!: string;
  motive!: string;

  endDate!: string;
  startDate!: string;


  constructor() {
    // @ts-ignore
    this.endDate = new Date();
    // @ts-ignore
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 30));
  }
}
