import {NavigationExtras, Router} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from "../../../service/user.service";
import {finalize, Subject, takeUntil} from "rxjs";
import {User} from "../../../models/user.model";
import {RoutesEnum} from "../../../shared/enums/routes.enum";
import {MessageService} from 'primeng/api';
import {BreadcrumbService} from "../../../service/breadcrumb.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  users = []

  loading = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private breadCrumb: BreadcrumbService,
  ) {
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Administrador', icon: 'pi pi-users'},
      {label: 'Usuários', icon: 'pi pi-user'}
    ]);
    this.getUsers()
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getUsers() {
    this.loading = true;
    this.userService.getUsers()
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: (user) => this.users = user,
      });
  }

  openNewUser() {
    this.router.navigate([RoutesEnum.USER_AUTH_EDIT_USERS]).then(() => {
    })
  }

  openEdit(user: User) {
    const navigationExtras: NavigationExtras = {
      state: {
        user: user
      }
    };
    this.router.navigate([RoutesEnum.USER_AUTH_EDIT_USERS], navigationExtras).then(() => {
    })
  }

  blockedChange(event: any, user: any){
    user.blocked = !event.checked;
    delete user.password;
    delete user.accessToken;
    delete user.refreshToken;
    delete user.groups;
    this.userService.update(user)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () =>{
          setTimeout(() => {
            this.messageService.add({
              key: 'toastSuccess',
              severity: 'success',
              detail: 'Usuário' + (event.checked?' liberado ': ' bloqueado ') + 'com sucesso!',
            });
          }, 2000);
        },
        error: (e) => {
          user.blocked = event.checked;
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar' + (event.checked?' liberar ': ' bloquear ') + 'usuário',
          });
        }
      });
  }

  deleteUser(userId: number) {
    this.userService.delete(userId)
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.getUsers()),
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Usuário excluído com sucesso!',
          });
        },
        error: (e) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar excluir usuário',
          });
        },
      })
  }

}
