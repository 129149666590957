export enum Role {
  ADMIN_FULL = '/all',
  FORM = '/form',
  GESTOR = '/gestor',
  EVENTOS = '/events',
  DASHBOARD = '/dashboard',
  INVID = '/invid',
  TEMPO_REAL = '/real-time',
  RELATORIOS = '/reports',
  OCORRENCIA = '/occurrence',
  STATUS_EQUIPAMENTOS = '/status-monitoring',
  STATUS_EQUIPAMENTOS_LIST = '/status-monitoring/list',
  PBT_CONFIG_ALERT_REPORT = '/alert-report',
  PBT_CONFIG = '/pbt-configuration',
  PBT_CONFIG_ALERT_CONFIG = '/alert-configuration',
  PROCESSAMENTO_TRIAGEM = '/triagem', // obrigatorio para todos que forem ter as etapas abaixo
  PROCESSAMENTO_PRE_TRIAGEM = '/triagem/preTriagem',
  PROCESSAMENTO_TRIAGEM_PRIMEIRA = '/triagem/primeiraTriagem',
  PROCESSAMENTO_TRIAGEM_SEGUNDA = '/triagem/segundaTriagem',
  PROCESSAMENTO_TRIAGEM_VALIDACAO = '/validacao',
  PROCESSAMENTO_TRIAGEM_HISTORICO = '/historico',
  PROCESSAMENTO_TRIAGEM_BATCH = '/batch',
  EXPORTACAO = '/exportacao',

  TRIAGEM_VALIDACAO_FUNCIONALIDADE_APROVE_ALL = '/triagem-funcionalidade-aproveAll' // funcionalidade no contrato do DNIT para liberar BTN APROVAR_TODOS na validacao do processamento
}
