import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DeviceEvent} from 'src/app/models/device-event.model';
import {DeviceEventType} from '../../../../models/deviceEventType';
import {GaleriaCommandsService} from "../../../../service/galeria-commands.service";
import {DomSanitizer} from '@angular/platform-browser';
import {AlertService} from 'src/app/service/alert.service';

@Component({
  selector: 'real-time-events-details-window',
  templateUrl: './events-details-window.component.html',
  styleUrls: ['./events-details-window.component.scss']
})
export class EventsDetailsWindowComponent implements OnInit {
  @Input() showDetails: boolean = false;
  @Output() showDetailsChange = new EventEmitter<boolean>();

  @Input() detailsData!: TmpDeviceEventMap;//TODO voltar com o tipo 'DeviceEventMap' quando a API estiver pronta
  @Output() detailsDataChange = new EventEmitter<any>();

  fullscreenImg: boolean = false;
  imgUrl: string = '';
  selectedIndex: number = 0;
  loaded: boolean = false;

  constructor(public galeriaService: GaleriaCommandsService,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit(): void {
    this.detailsData.documents.forEach(v => {
        // @ts-ignore
        this.alertService.consultaImgBucketS3(v.img, (imageObjectUrl: string) => v.img = this.sanitizer.bypassSecurityTrustUrl(imageObjectUrl));
    })
    this.loaded = true;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.fullscreenImg) {
      if (event.key === 'ArrowRight') {
        this.selectedIndex = this.selectedIndex === this.detailsData.documents.length - 1 ? 0 : this.selectedIndex + 1;
      } else if (event.key === 'ArrowLeft') {
        this.selectedIndex = this.selectedIndex === 0 ? this.detailsData.documents.length - 1 : this.selectedIndex - 1;
      } else if (event.key === 'Escape') {
        this.fullscreenImg = false;
      }
    }
  }
  cleanDialog() {
    this.showDetailsChange.emit(false);
  }

  fullscrennImage(url: any, index: number) {
    this.selectedIndex = index;
    this.fullscreenImg = true;
    this.imgUrl = url;
  }

  getAxlesPbt(axles: any[]): string {
    let ret = '';
    let i = 1;
    axles.forEach((val) => {
      ret += ('Eixo ' + i + ': ' + val.weight_axle + ' kg, ');
      i++;
    })
    ret = ret.substring(0, ret.length - 2);
    return ret;
  }

  isWheighing(t: DeviceEventType): boolean {
    return t === DeviceEventType.WEIGHING;
  }

}

class TmpDeviceEventMap extends DeviceEvent {
  pbt!: string;
  classifications_pbt_limit!: number;
  vehicle_classification!: any;
  wheighing_classification!: any;
  axis_wheighing!: number;
  pbt_excess!: number;
  vehicle_lenght!: number;
  distance_from_previous_vehicle!: number;
  axleJson!: Array<any>;
}
