import {Injectable} from '@angular/core';
import {RoleModel, User} from '../models/user.model';

import * as aes from 'crypto-js/aes';
import * as Utf8 from 'crypto-js/enc-utf8';
import {StorageKeys} from '../shared/enums/storage-keys.enum';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, finalize, map, Observable, tap} from "rxjs";
import {jwtDecode} from 'jwt-decode';
import {Router} from "@angular/router";
import {RoutesEnum} from "../shared/enums/routes.enum";
import {Role} from "../shared/enums/role.enum";
import {LoginService} from './login.service';
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})
export class UserService extends BaseService {

  private static readonly KEY: string = "hyhheyh5ytdFJYFG==";

  loginToastMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public http: HttpClient,
    private router: Router,
    private loginService: LoginService
  ) {
    super(http);
  }

  getUsers(): Observable<any> {
    const url: string = this.getEndpoint('USERS');
    return this.getData(url);
  }

  // consultaUserLegacyIdPorUserId(userId: number): Observable<any> {
  //   return this.http.get<any>(`${environment.baseApi}users/getLegacyId?userId=${userId}`)
  // }

  get user(): User {
    return this.decrypt(StorageKeys.USER, true) ?? new User();
  }

  saveUser(user: any) {
    this.encrypt(StorageKeys.USER, user, true);
  }

  efetuarLogout(): void {
    localStorage.removeItem(StorageKeys.USER);
    localStorage.removeItem(StorageKeys.TOKEN);
    localStorage.removeItem(StorageKeys.USER_EMAIL);

    this.router.navigate([RoutesEnum.LOGIN]).then(() => {
    });
  }

  redirectToHome() {
    this.router.navigate([RoutesEnum.HOME]).then(() => {
    });
  }

  redirectToChangePassword() {
    this.router.navigate([RoutesEnum.CHANGE_PASSWORD]).then(() => {
    });
  }

  setAuthToken(token: string): void {
    localStorage.setItem(StorageKeys.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(StorageKeys.TOKEN);
  }

  getRolesFromUser(): RoleModel[] {
    return (this.decrypt(StorageKeys.USER, true) as User)?.roles ?? [];
  }

  updateAuditor(auditorId: string): Observable<any> {
    const url: string = this.getEndpoint('UPDATE_AUDITOR_RECORD');
    return this.putData(url, {
      id: this.user.id,
      auditorRecord: auditorId
    }).pipe(finalize(() => {
      const userDecripted = structuredClone(this.user);
      userDecripted.auditorRecord = Number(auditorId);
      this.saveUser(userDecripted);
    }));
  }

  hasRoles(roles: Role | Role[]): boolean {
    if (!roles)
      return false;

    if (!Array.isArray(roles))
      roles = [roles];
    const userRoles = this.getRolesFromUser();
    let haveRole = false;


    roles.forEach(role => {
      const foundRole = userRoles.filter(userRole => userRole.roleRout === role);
      if (foundRole.length > 0 && foundRole[0].active) {
        haveRole = true;
      }
    })
    return haveRole
  }

  checkForcePassword() {
    const user = this.decrypt(StorageKeys.USER, true) as User ?? []
    return !!user.forceChangePassword;
  }

  sendToken(auth: string) {
    // this.loginService.updateTransporter({
    //   email,
    //   password
    // })
    const url = this.getEndpoint('SEND_TOKEN_LOGIN');
    return this.postData(url, {
      ...this.loginService.getValues(),
      passwordAuth: auth
    }).pipe(
      tap((response) => {
        if (response) {
          response = this.setRoles(response);
          this.setTenantCookie();
          this.saveUser(response);
          this.setAuthToken(response.accessToken);
          return response;
        }
      }))
  }


  login(email: string, password: string): Observable<any> {
    // const loginMock = this.loginMock(email, password);
    // if (loginMock)
    //   return of(loginMock);

    this.loginService.updateTransporter({
      email,
      password
    })
    const url: string = this.getEndpoint('AUTH_LOGIN');
    return this.postData(url, {
      ...this.loginService.getValues(),
      email,
      password
    })
      .pipe(map((response) => {
        if (response) {
          if (!response.enableMFA) {
            response = this.setRoles(response);
            this.setTenantCookie();
            this.saveUser(response);
            this.setAuthToken(response.accessToken);

            return response;
          }
          return response;
        }
      }))
  }

  loginFromToken(token: string, email: string) {
    const url: string = this.buildUrlQueryParams({
      email: email,
    }, this.getEndpoint('AUTH_LOGIN_TOKEN'))
    return this.http.get<any>(url, {
      headers: {
        Authorization: token
      }
    }).pipe(map((response) => {
        if (response) {
          response = this.setRoles(response);
          this.setTenantCookie();
          this.saveUser(response);
          return response;
        }
      }))
  }

  setRoles(user: User): User {
    const rolesSet = new Set<RoleModel>();
    if (!!user && !!user.userGroups && user.userGroups.length > 0) {
      user.userGroups.forEach(group => {
        group.roles?.forEach(role => rolesSet.add(role));
      })
    }
    ///SOLUÇÃO ABAIXO TEMPORÁRIA SOMENTE ATÉ USUÁRIOS ANTIGOS GANHAREM NOVAS PERMISSÕES
    else {
      rolesSet.add({
        id: 1,
        roleName: "ADMIN_FULL",
        roleRout: "/all",
        active: true
      });
    }
    ////////////////
    user.roles = Array.from(rolesSet);
    return user;
  }

  forgotPassword(email: string) {
    let url: string = this.getEndpoint('FORGOT_PASS_USER');
    url += `?email=${email}`;
    return this.getData(url);
  }

  register(user: User): Observable<any> {
    const url: string = this.getEndpoint('AUTH_REGISTER');
    return this.postData(url, user);
  }

  update(user: User): Observable<any> {
    const url: string = this.getEndpoint('AUTH_REGISTER');
    return this.putData(url, user);
  }

  delete(userId: number): Observable<any> {
    let url: string = this.getEndpoint('USERS');
    url += `?id=${userId}`
    return this.deleteData(url);
  }

  changePassword(data: any) {
    const url: string = this.getEndpoint('CHANGE_PASSWORD');
    return this.postData(url, data);
  }

  private setTenantCookie(): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `Tenant=f6842ee7-8e0b-4b2e-b93d-d7e542d5c34a; ${expires}`;
  }

  // ENCRYPT
  // -------------------------------

  encrypt(key: string, value: any, shouldParse?: boolean) {
    if (shouldParse) {
      value = JSON.stringify(value);
    }
    const encrypted: any = aes.encrypt(value, UserService.KEY).toString();
    localStorage.setItem(key, encrypted || value);
  }

  decrypt(key: string, shouldParse?: boolean) {
    try {
      const encrypted: any = localStorage.getItem(key);
      if (encrypted) {
        const bytes: any = aes.decrypt(encrypted, UserService.KEY);
        if (bytes) {
          if (shouldParse) {
            return JSON.parse(bytes.toString(Utf8));
          }
          return bytes.toString(Utf8);
        }
      }
    } catch (erro) {
      console.error(erro);
    }
    return null;
  }

  isLogged(): boolean {
    const jwtToken = this.getToken();
    if (jwtToken) {
      const decodedToken = this.decodeToken(jwtToken);
      return decodedToken?.exp && new Date(decodedToken.exp * 1000).getTime() > new Date().getTime();
    }
    return false;
  }

  private decodeToken(jwtToken: string): any {
    try {
      return jwtDecode(jwtToken);
    } catch (e) {
      console.error(`erro ao decodificar o token ${jwtToken}`)
      return null;
    }
  }
}
