import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from "../../service/breadcrumb.service";
import {MenuItem} from "primeng/api";
import {Subject, takeUntil} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  items: MenuItem[] = [];

  constructor(
    public breadcrumbService: BreadcrumbService,
    public route: Router) {
  }

  ngOnInit() {
    this.breadcrumbService.value()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => this.items = response,
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * @description Método responsável por tratar o click do breadcrumb ANTES de realizar a rota
   * @param item
   */
  onRouterClick(item: MenuItem) {
    if (item?.command)
      item.command({item});

    if (item.routerLink)
      this.route.navigate(item.routerLink, {queryParams: item.queryParams})
  }

}
