export class AlertConfiguration {
  id?: number;
  description?: string;
  message?: string;
  idDevice?: number;
  nameDevice?:string;
  createdAt?:Date;
  local?: string;
  active?: boolean;
  condition?: string;
  plate?: string;
  referenceValue?: number;
  color?: string;
  severity?: string;
  alertType?: AlertType;
  devices?: Array<number>;

  constructor(){
    this.description = '';
    this.message = '';
    this.plate = '';
    this.active = true;
    this.condition = '';
    this.referenceValue = undefined;
    this.severity = '';
    this.devices = [];
    this.alertType = AlertType.speed;
  }
}

export enum AlertType {
  weight = "ALERTA_EXCESSO_PESO",
  speed = "ALERTA_EXCESSO_VELOCIDADE",
  temperature = "ALERTA_TEMPERATURA",
  energyReturn = "ALERTA_RETORNO_ENERGIA",
  energyFail = "ALERTA_QUEDA_ENERGIA",
  energyOk = "ALERTA_ENERGIA_OK",
  openDoor = "ALERTA_PORTA_ABERTA",
  temperature2 = "msgEquipmentTemperatureHigh",
  energyReturn2 = "msgAlertPoweReturned",
  energyFail2 = "msgAlertPoweFailure",
  energyOk2 = "msgEnergyOK",
  openDoor2 = "msgEquipmentPortOpen",
}
