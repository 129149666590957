<div class="col-12">
  <p-card [style]="editCard">
    <div class="grid">
      <p
        class="col-12 mt-6 h-3rem"
        [style.color]="type === 'date' ? 'white' : 'black'"
      >
        {{ title }}
      </p>
      <h2 *ngIf="type === 'date'" class="col-12 h-4rem" [style.color]="'white'">
        {{ getValue() | date : "dd/MM/YYYY" }}
      </h2>
      <h2
        *ngIf="type === 'temperature'"
        class="col-12 h-4rem"
        [style.color]="'black'"
      >
        {{ getValue() }}
      </h2>
    </div>
  </p-card>
</div>
