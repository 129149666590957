import { ViolationEvent } from './../../../../models/violation-event.model';
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {GaleriaCommandsService} from "../../../../service/galeria-commands.service";
import { AlertService } from 'src/app/service/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'violation-events-details-window',
  templateUrl: './violation-events-details-window.component.html',
  styleUrls: ['./violation-events-details-window.component.scss']
})
export class ViolationEventsDetailsWindowComponent implements OnInit {
  @Input() showDetails: boolean = false;
  @Output() showDetailsChange = new EventEmitter<boolean>();

  @Input() isViolationEvent: boolean = false;

  @Input() detailsData!: ViolationEvent;
  @Output() detailsDataChange = new EventEmitter<any>();

  fullscreenImg: boolean = false;
  imgUrl: string = '';
  openDetails = false;
  indexSelecionado: number = 0;
  loaded: boolean = false;

  constructor(
    public galeriaService: GaleriaCommandsService,
    public alertService: AlertService,
    private sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit(): void {
    const safeUrls: any[] = [];

    // Função para carregar as imagens de forma assíncrona e garantir a segurança da URL
    const loadImage = (url: string, index: number) => {
        this.alertService.consultaImgBucketS3(url, (imageObjectUrl: string) => {
            safeUrls[index] = this.sanitizer.bypassSecurityTrustUrl(imageObjectUrl);

            // Verificar se todas as imagens foram carregadas
            if (safeUrls.length === this.detailsData.urlImage.length) {
                // Todas as imagens foram carregadas, definir loaded como true e atualizar o detailsData
                this.detailsData.urlImage = safeUrls;
                this.loaded = true;
            }
        });
    };

    // Percorrer o array de URLs das imagens
    for (let i = 0; i < this.detailsData.urlImage.length; i++) {
        loadImage(this.detailsData.urlImage[i], i);
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.fullscreenImg) {
      if (event.key === 'ArrowRight') {
        this.indexSelecionado = this.indexSelecionado === this.detailsData.urlImage.length - 1 ? 0 : this.indexSelecionado + 1;
      } else if (event.key === 'ArrowLeft') {
        this.indexSelecionado = this.indexSelecionado === 0 ? this.detailsData.urlImage.length - 1 : this.indexSelecionado - 1;
      } else if (event.key === 'Escape') {
        this.fullscreenImg = false;
      }
    }
  }

  testeConsole() {
  }

  cleanDialog() {
    this.showDetailsChange.emit(false);
  }

  fullscrennImage(url: any, index: number) {
    this.indexSelecionado = index;
    this.fullscreenImg = true;
    this.imgUrl = url;
  }

  getAxlesPbt(axles: any[]): string {
    let ret = '';
    let i = 1;
    axles.forEach((val) => {
      ret += ('Eixo ' + i + ': ' + val.weight_axle + ' kg, ');
      i++;
    })
    ret = ret.substring(0, ret.length - 2);
    return ret;
  }

}
