import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {AlertEvent} from '../models/device-event.model';
import {environment} from 'src/environments/environment';
import {delay, map, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RealTimeService extends BaseService {

  private lastAlertId: number = 0;

  constructor(public http: HttpClient) {
    super(http);
  }

  obterPontosRetencao(): Observable<any> {
    return of({
      "total": 52,
      "dispositivos": [{
        "id": 1,
        "nome": "Radar MO01 - Rua Benjamin 48",
        "velocidadeMediaAtual": 10,
        "velocidadeMediaAnterior": 8
      },
        {
          "id": 2,
          "nome": "Radar MO1 - Av Vicente Machado",
          "velocidadeMediaAtual": 20,
          "velocidadeMediaAnterior": 37
        }, {
          "id": 3,
          "nome": "Radar MO3 - Av Vicente Machado",
          "velocidadeMediaAtual": 24,
          "velocidadeMediaAnterior": 17
        }]
    }).pipe(delay(1000));
  }

  getNewAlerts(): Observable<Array<AlertEvent>> {
    let url: string = this.buildUrlQueryParams({
      idafter: this.lastAlertId
    }, this.getEndpoint('ALERT_AFTER_LAST'));
    return this.getData(url).pipe(
      map((response) => {
        const events: Array<AlertEvent> = response.items;
        if (events.length > 0)
          this.lastAlertId = this.getMaxId(events);
        //page.totalElements = response.totalCount;
        //page.totalPages = page.totalElements / page.size;
        return events;
      })
    );
  }

  getMaxId(events: Array<AlertEvent>): number {
    const ids = events.map(object => {
      return object.id;
    });
    return Math.max(...ids);
  }

  initLastAlertId(): Observable<number> {
    if (this.lastAlertId > 0) return of(this.lastAlertId);//Apenas reinicia se ainda não estiver iniciado. (ex: mudança de página)
    const url: string = this.getEndpoint('ALERT_LAST_ID');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (environment.production)
            this.lastAlertId = response;//Se produção, funciona normal
          else
            this.lastAlertId = 1;//Se não produção, pula 2 alerts
          return response;
        })
      );

  }
}
