import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner-img',
  templateUrl: './spinner-img.component.html',
  styleUrls: ['./spinner-img.component.scss']
})
export class SpinnerImgComponent {

}
