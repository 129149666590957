import { Directive, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

export enum MaskType {
	CPF_CNPJ,
	CELLPHONE,
	INT_CELLPHONE
}
export interface IMask {
	mask?: string;
	type?: MaskType;
}
/**
 * Adaptação de uma directiva genérica para uso de CPF/CNPJ no mesmo campo e outros tipos comum como telefone (8/9 digitos).
 * diretiva original: https://github.com/tiaguinho/ionic2-mask-directive/blob/master/index.ts
 */
@Directive({
	selector: '[mask]',
	host: {
		'(change)': 'ngOnChanges()',
		'(keyup)': 'onKeyUp($event)',
		'(keydown)': 'onKeyUp($event)'
	}
})
export class MaskDirective implements OnChanges {

	/**
	 * mask precisar ser um objeto do tipo
	 */
	@Input() mask!: IMask;
	// @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

	private cpfMask: string = "999.999.999-99";
	private cnpjMask: string = "99.999.999/9999-99";
	private cellphone8Mask: string = "(99) 9999-9999";
	private cellphone9Mask: string = "(99) 99999-9999";

	constructor(private control: NgControl) { }

	/**
	 * when loading dynamically data to the input, without this
	 * the mask will only work on keyup event changes
	 */
	ngOnChanges(): void {
		const value: string = this.control.control?.value;
		if (value) {
			this.control.control?.setValue(this.format(value));
		}
	}

	onKeyUp(event: any): void {
		try {
			if (event.keyCode !== 13 && event.keyCode !== 9) {
				const value: string = this.control.control?.value;
				this.control.control?.setValue(this.format(value));
			}
		} catch (erro) { }
	}

	// unmask(event) {
	//    if(event.target.value) {
	// 		let unmasked = event.target.value.replace(/[^a-z0-9]/gi, "");
	//        this.ngModelChange.emit(unmasked);
	//    }
	// }

	private format(v: string) {
		let s: string = '';

		const matches: RegExpMatchArray = String(v).match(/[a-zA-Z0-9]+/g)!;
		if (matches !== null) {
			const mask: string = this.getMaskByType(this.mask, v)!;
			if (mask == null) return;

			let value: Array<string> = matches.join('').split('');
			const chars: Array<string> = mask.split('');
			for (const c of chars) {
				if (value.length === 0) {
					break;
				}
				switch (c) {
					case '#': //qualquer
						s += value[0];
						value = value.slice(1);
						break;

					case '9':
						if (value[0].match(/\d/) !== null) {
							s += value[0];
							value = value.slice(1);
						}
						break;

					case 'A':
						if (value[0].match(/[a-zA-Z]/) !== null) {
							s += value[0];
							value = value.slice(1);
						}
						break;

					default:
						s += c;
				}
			}
		}
		return s;
	}

	// @ts-ignore
  private getMaskByType(mask: IMask, value: string)  {
		if (mask == null) return null;
		if (mask.type == null) return mask.mask!;

		// tslint:disable-next-line:typedef
		const valueCopied = new String(value);
		const valueUnmasked: string = valueCopied.replace(/[^a-z0-9]/gi, "");

		switch (mask.type) {
			case MaskType.CPF_CNPJ:
				return valueUnmasked.length > 11 ? this.cnpjMask : this.cpfMask;

			case MaskType.CELLPHONE:
				return valueUnmasked.length > 10 ? this.cellphone9Mask : this.cellphone8Mask;

			case MaskType.INT_CELLPHONE:
				return "999999999999999";

			default:
				break;
		}
	}
}
