import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType} from "angular-gridster2";
import {BreadcrumbService} from "../../service/breadcrumb.service";
import {HeaderService} from "../../service/header.service";
import {Router} from "@angular/router";
import {RoutesEnum} from "../../shared/enums/routes.enum";
import {LoaderService} from "../../service/loader.service";

@Component({
  selector: 'app-dashboard-servicos',
  templateUrl: './dashboard-servicos.component.html',
  styleUrls: ['./dashboard-servicos.component.scss']
})
export class DashboardServicosComponent implements OnInit, OnDestroy {

  options!: GridsterConfig;
  dashboard!: Array<GridsterItem>;

  constructor(private breadCrumb: BreadcrumbService,
              private route: Router,
              private loader: LoaderService,
              private headerService: HeaderService) {
  }

  ngOnInit() {
    this.headerService.showHeader = false;
    this.breadCrumb.next([]);
    this.generateOptions();
  }

  ngOnDestroy() {
    this.headerService.showHeader = true;
  }

  permiteEditar() {
    this.options.draggable!.enabled = !this.options.draggable!.enabled;
  }

  generateOptions(): void {
    this.options = {
      gridType: GridType.Fixed,
      setGridSize: true,
      compactType: CompactType.CompactUp,
      margin: 15,
      outerMargin: true,
      useTransformPositioning: true,
      minCols: 9,
      maxCols: 9,
      minRows: 4,
      maxRows: 4,
      maxItemCols: 8,
      minItemCols: 1,
      maxItemRows: 1000,
      minItemRows: 1,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 135,
      fixedRowHeight: 135,
      mobileBreakpoint: 769,
      keepFixedWidthInMobile: false,
      keepFixedHeightInMobile: true,
      pushItems: true,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
        stop: function (item, gridsterItem, event) {
          console.info('drag-end');
          console.info(item);
          console.info(gridsterItem);
          console.info(event);
          //do position update
        }
      },
      resizable: {
        enabled: false,
        stop: function (item, gridsterItem, event) {
          console.info('resize-end');
          console.info(item);
          console.info(gridsterItem);
          console.info(event);
          //do position update
        }
      },
      swap: false,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {
        north: true,
        east: true,
        south: true,
        west: true
      },
      pushResizeItems: false,
      displayGrid: DisplayGrid.None,
      disableWindowResize: false,
      disableWarnings: true,
      scrollToNewItems: true
    };

    this.dashboard = [
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
      {cols: 3, rows: 2, y: 0, x: 0, widget: "bar"},
    ];
  }

  goToModulo() {
    this.loader.showLoader();
    setTimeout(() => {
      this.route.navigate([RoutesEnum.REAL_TIME])
        .then(() => {
          this.loader.hideLoader();
        });
    }, 750);
  }
}
