import {Component, OnDestroy, OnInit} from '@angular/core';

import {DeviceEventType} from '../../models/deviceEventType';

import {Device} from '../../models/device.model';
import {Filters} from '../../models/filters.model';
import {DataTablePage} from '../../models/data-table-page.model';
import {DeviceEvent} from '../../models/device-event.model';

import {DeviceService} from '../../service/devices.service';
import {GruposService} from '../../service/grupos.service';
import {finalize, Subject, takeUntil} from "rxjs";
import {BreadcrumbService} from "../../service/breadcrumb.service";
import {UserService} from 'src/app/service/user.service';
import {Group} from "../../models/user.model";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  stylePattern = {'min-width': '100%', 'max-width': '100px', 'height': '40px'};

  devices!: Array<Device>;
  grupos!: Array<Group>;
  qfv: any;
  firstLoad: boolean = true;
  list_dispositivos: any[] = [];
  mapDispositivosPerGroup: Map<any, any[]> = new Map<any, any[]>();
  todosDispositivosSize: number = 9000;
  filters!: Filters;
  currentFilter!: Filters;
  rangeDates: Date[] = [];
  page!: DataTablePage;
  events!: Array<DeviceEvent>;
  violationEvents!: Array<any>;
  loading: boolean = false;
  totalRecords: number = 0;
  lazyEvent: any;
  selected!: Array<DeviceEvent>;
  display: boolean = false;
  displayVDetails: boolean = false;
  valorObjeto: any = undefined;
  valorViolationObjeto: any = undefined;
  first: number = 0;
  selectedViolationType: boolean = false;
  lastSearchAsViolationType: boolean = false;
  disableQFV: boolean = true
  deviceType = [
    {type: 'FLOW', description: 'Passagem'},
    {type: 'WEIGHING', description: 'Pesagem'},
  ];
  laneOptions = [1,2,3,4,5];

  selectedType: any;

  notFound: boolean = false;
  tituloNotFound: string = 'Nenhum evento encontrado';
  descricaoNotFound: string = 'Desculpe, não encontramos nenhum evento para os filtros informados';

  constructor(
    private deviceService: DeviceService,
    private gruposService: GruposService,
    private breadCrumb: BreadcrumbService,
    private userService: UserService
  ) {
    this.page = new DataTablePage();
    this.page.size = 10;
    this.page.pageNumber = 0;
  }


  public get DeviceEventType(): typeof DeviceEventType {
    return DeviceEventType;
  }

  showDialog(results: any) {
    this.display = true;
    this.valorObjeto = JSON.parse(JSON.stringify(results));
  }

  showViolationDialog(results: any) {
    this.displayVDetails = true;
    this.valorViolationObjeto = JSON.parse(JSON.stringify(results));
  }

  ngOnInit() {
    this.breadCrumb.next([
      {label: 'Monitoramento', icon: 'pi pi-fw pi-desktop'},
      {label: 'Eventos', icon: 'pi pi-fw pi-car'}
    ]);
    this.getQFV();
    this.getGroups();
    this.filters = new Filters();
    this.rangeDates[0] = this.filters.startDate;
    this.rangeDates[1] = this.filters.endDate;
    this.currentFilter = {...this.filters};
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getGroups(): void {
    const user = this.userService.user;
    if(user.groups){
      this.grupos = user.groups;
    }
  }

  getQFV() {
    this.gruposService.getQFV()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (qfv) => this.qfv = qfv,
        error: (e) => {}
      });
  }

  typeChange(event: any) {
    this.filters.type = [];
    this.filters.images = undefined;
    this.filters.qfvClass = '';
    this.filters.device = undefined;
    if (event.value.type === DeviceEventType.VIOLATION) {
      this.selectedViolationType = true;
      this.disableQFV = true;
     return;
      }else{
        this.selectedViolationType = false;
    }
    this.filters.type = event.value.type;
    if (event.value.type === DeviceEventType.WEIGHING) {
      this.disableQFV = false;
    } else {
      this.disableQFV = true;
    }
  }

  gatilhoItemFiltro(e: any) {
    this.filters.type = [];
    this.selectedType = {type: DeviceEventType.FLOW, description: 'Passagem'}
    this.typeChange({value: {type: DeviceEventType.FLOW}})
    this.filters.device = undefined;
    this.deviceType = [
      {type: DeviceEventType.FLOW, description: 'Passagem'},
      {type: DeviceEventType.WEIGHING, description: 'Pesagem'},
    ];

    if(e.value.id === 418){
      this.filters.qfvClass = '';
      this.disableQFV = true;
      this.deviceType = [
        {type: DeviceEventType.FLOW, description: 'Passagem'},
        {type: DeviceEventType.VIOLATION, description: 'Infração'}
      ];
    }else{
      this.deviceType = [
        {type: DeviceEventType.FLOW, description: 'Passagem'},
        {type: DeviceEventType.WEIGHING, description: 'Pesagem'},
      ];
    }
    const groupId = e.value.id;
    if (this.mapDispositivosPerGroup.has(groupId)) {
      this.list_dispositivos = this.mapDispositivosPerGroup.get(groupId) ?? [];
      return;
    }
    this.deviceService.getEquipmentGroupId(groupId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(list_dispositivos => {
        this.mapDispositivosPerGroup.set(groupId, list_dispositivos);
        this.list_dispositivos = this.mapDispositivosPerGroup.get(groupId) ?? [];
      });
  }

  search(pageInfo?: any, restart = false, isViolationEvent: boolean = false): void {
    this.notFound = false;
    if (restart) {
      this.page.pageNumber = 0;
      this.first = 0;
      if (this.rangeDates !== undefined) {
        this.filters.startDate = this.rangeDates[0];
        this.filters.endDate = this.rangeDates[1];
      }
      if (
        this.rangeDates === undefined &&
        this.filters.startHour !== undefined &&
        this.filters.endHour !== undefined
      ) {
        this.filters.startDate = new Date(this.filters.startHour);
        this.filters.endDate = new Date(this.filters.endHour);
      }
      if(this.filters.plate){
        this.filters.plate = this.filters.plate?.toUpperCase();
      }
      this.currentFilter = {...this.filters};
    }
    this.loading = true;
    let currentType: any = '';
    if (this.currentFilter.type) {
      currentType = this.currentFilter.type;
      currentType = this.getTypeName(currentType).toLowerCase();
    }

    if(!isViolationEvent){
      this.deviceService
        .getDeviceEvents(this.page, this.currentFilter)
        .pipe(
          takeUntil(this._destroy$),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: (data) => {
            if(this.lastSearchAsViolationType!==false){
              this.lastSearchAsViolationType = false;
            }
            this.events = data;
            this.totalRecords = this.page.totalElements;
            if (!data || data.length === 0) {
              this.notFound = true;
              this.tituloNotFound = (currentType ? ('Nenhuma '+currentType+' encontrada') : 'Nenhum evento encontrado');
              this.descricaoNotFound = 'Desculpe, não encontramos' + (currentType ? ('nenhuma '+currentType) : 'nenhum evento') +' para os filtros informados';
            }
          },
          error: (error) => {
            console.error(error)
          },
        });
    }else{
      this.deviceService
        .getDeviceViolationEvents(this.page, this.currentFilter)
        .pipe(
          takeUntil(this._destroy$),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: (data) => {
            if(this.lastSearchAsViolationType!==true){
              this.lastSearchAsViolationType = true;
            }
            this.lastSearchAsViolationType = true;
            this.violationEvents = data;
            this.totalRecords = this.page.totalElements;
            if (!data || data.length === 0) {
              this.notFound = true;
              this.tituloNotFound = 'Nenhuma infração encontrada';
              this.descricaoNotFound = 'Desculpe, não encontramos nenhuma infração para os filtros informados';
            }
          },
          error: (error) => {
            console.error(error)
          },
        });
    }
  }

  getTypeName(type: DeviceEventType) {
    if (type) {
      switch (type) {
        case DeviceEventType.FLOW:
          return 'Passagem';

        case DeviceEventType.WEIGHING:
          return 'Pesagem';

        case DeviceEventType.VIOLATION:
          return 'Infração';

        default:
          return '-';
      }
    }
    return '-';
  }

  loadRel(event: any, isUnderViolationSearch: boolean) {
    if (this.firstLoad) {
      this.filters.type = [];
      this.typeChange({value: {type: DeviceEventType.FLOW}})
      this.currentFilter = {...this.filters};
      this.firstLoad = false;
      return;
    }
    this.lazyEvent = event;
    this.page.pageNumber = event.first / this.page.size;
    this.search(null, false, isUnderViolationSearch);
  }

  verifyRangeDate(){
    if (this.rangeDates !== undefined && this.rangeDates !== null) {
      if(this.rangeDates.length===0){
        return true;
      }
      if(this.rangeDates[1]===null){
        return false;
      }
    }
    return true;
  }

  verifyVariables(): boolean {
    const regex = /[A-Za-z]{3}[0-9]{1}[A-Za-z]{1}[0-9]{2}|[A-Za-z]{3}[0-9]{4}/;
    if(this.verifyRangeDate()){
      if(this.filters.plate){
        return regex.test(this.filters.plate);
      }
      if(!this.filters.grupos){
        return false;
      }
      if(!this.filters.type){
        return false;
      }
    }else{
      return false;
    }
    return true;
  }
}
