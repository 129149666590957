<div class="px-6">
  <div class="grid">
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Referência</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="contract.reference"
      pInputText/>
    </div>
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Identificação do contrato</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="contract.identification"
      pInputText/>
    </div>
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Empresa</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="contract.enterprise"
      pInputText/>
    </div>
    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Data início vigência</h5>
      <p-calendar
      [style]="stylePattern"
      inputId="filterRange"
      [(ngModel)]="contract.startDate"
      ></p-calendar>
    </div>

    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Data fim vigência</h5>
      <p-calendar
      [style]="stylePattern"
      inputId="filterRange"
      [(ngModel)]="contract.endDate"
      ></p-calendar>
    </div>


    <div class="col-4">
      <button
      [disabled]="!verifyContract(contract)"
      pButton
      (click)="registerContract()"
      class="block relative top-100 -translate-y-100"
      type="button"
      label="Salvar" ></button>
    </div>

  </div>
</div>
