<div class="px-6">
  <p-toast position="top-right" key="toastError"></p-toast>
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-card styleClass="my-3">
    <p-fieldset legend="Filtros" [toggleable]="true">
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="grupos"
              inputId="filtroGrupos"
              optionLabel="description"
              optionValue="id"
              [(ngModel)]="motiveOccurrenceFilter.groupId"
              [filter]="true"
              [showClear]="true"
              placeholder="Selecionar"
            >
              <ng-template let-grupos pTemplate="item">
                <div class="font-semibold text-center">
                  {{ grupos.description }}
                </div>
              </ng-template>
            </p-dropdown>
            <label htmlFor="filtroGrupos">Grupos</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input
            class="min-w-full"
            [style]="stylePattern"
            type="text"
            pInputText
            [(ngModel)]="motiveOccurrenceFilter.description"
          />
            <label htmlFor="filterTipo">Descrição</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [options]="motiveOccurrenceType"
              [(ngModel)]="motiveOccurrenceFilter.type"
              optionLabel="name"
              optionValue="value"
              [style]="stylePattern"
              [showClear]="true"
              inputId="filterAgrupador"
              placeholder="Selecionar"
            ></p-dropdown>
            <label htmlFor="filterAgrupador">Agrupador</label>
          </span>
        </div>
        <div class="col-4 flex text-align: right;">
          <p-button
            class="block relative mr-2 top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            label="Filtrar"
            (onClick)="getMotiveOccurrence(false, motiveOccurrenceFilter)"
            size="small"
            icon="pi pi-search"
            ></p-button>
            <p-button
              [disabled]="!this.motiveOccurrenceFilter.description && !this.motiveOccurrenceFilter.groupId && !this.motiveOccurrenceFilter.type"
              class="block relative top-100 -translate-y-100"
              [style]="{ height: '40px' }"
              label="Limpar Filtro"
              size="small"
              icon="pi pi-times"
              (onClick)="clearFilter()"
            ></p-button>
        </div>
      </div>
    </p-fieldset>
  </p-card>
  <p-card [styleClass]="!notFound ? '' : 'mb-3'">
    <p-toolbar [styleClass]="!notFound ? 'mb-4 gap-2' : ''">
      <ng-template pTemplate="left">
          <button pButton pRipple label="Novo Motivo Ocorrência" icon="pi pi-plus" class="p-button-primary mr-2" (click)="showDialogCreate()"></button>
      </ng-template>
    </p-toolbar>
    <p-table
    *ngIf="!notFound"
    [(first)]="first"
    [value]="motiveOccurrenceData"
    [totalRecords]="totalRecords"
    [loading]="loading"
    [lazy]="true"
    [tableStyle]="{ 'min-width': '100%' }"
    [paginator]="true"
    [rows]="10"
    (onLazyLoad)="getMotiveOccurrence($event, currentFilter)"
    >
      <ng-template pTemplate="header">
        <tr [style]="{ cursor: 'default' }">
          <th class="text-center">Descrição</th>
          <th class="text-center">Agrupador</th>
          <th class="text-center">Tempo de atendimento</th>
          <th class="text-center">Ação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mOccurrence>
        <tr [style]="{ cursor: 'default' }">
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.description }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ formatTechnical(mOccurrence.type) }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.serviceTime }}
          </td>
          <td class="actions">
            <button class="p-button-warning" (click)="showEditDialog(mOccurrence)" pButton type="button" label="Editar" ></button>
            <button class="p-button-danger" (click)="openDeleteConfirm(mOccurrence.id, mOccurrence.description)" pButton type="button" label="Excluir" ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <app-not-found-component
  *ngIf="notFound"
  [titulo]="tituloNotFound"
  [descricao]="descricaoNotFound"
  ></app-not-found-component>


  <p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>



  <p-dialog
  [(visible)]="showCreateDialog"
  [modal]="true"
  [dismissableMask]="true"
  styleClass="w-9 h-60"
  >
    <p-fieldset legend="{{ editMode ? 'Editar Motivo Ocorrência' : 'Criar Motivo Ocorrência' }}">
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="grupos"
              inputId="filtroGrupos"
              [class]="!newMotiveOccurrence.groupId ? 'ng-invalid ng-dirty' : ''"
              [required]="true"
              optionLabel="description"
              [(ngModel)]="newMotiveOccurrence.groupId"
              optionValue="id"
              [filter]="true"
              [showClear]="true"
              placeholder="Selecionar"
              [required]="true"

            >
              <ng-template let-grupos pTemplate="item">
                <div class="font-semibold text-center">
                  {{ grupos.description }}
                </div>
              </ng-template>
            </p-dropdown>
            <label htmlFor="filtroGrupos">Grupos</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input
            [class]="!newMotiveOccurrence.description ? 'min-w-full ng-invalid ng-dirty' : 'min-w-full'"
            [style]="stylePattern"
            type="text"
            pInputText
            [(ngModel)]="newMotiveOccurrence.description"
            [required]="true"
          />
            <label htmlFor="filterTipo">Descrição</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [options]="motiveOccurrenceType"
              [(ngModel)]="newMotiveOccurrence.type"
              [required]="true"
              optionLabel="name"
              optionValue="value"
              [style]="stylePattern"
              inputId="filterAgrupador"
            ></p-dropdown>
            <label htmlFor="filterAgrupador">Agrupador</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <input
            [class]="!newMotiveOccurrence.serviceTime ? 'ng-invalid ng-dirty' : ''"
            placeholder="Digite o valor"
            [style]="stylePattern"
            type="number"
            min="1"
            pInputText
            [(ngModel)]="newMotiveOccurrence.serviceTime"
            [required]="true"

          />
            <label htmlFor="filterTipo">Tempo de Atendimento (horas)</label>
          </span>
        </div>
        <div class="col-2 text-align: right;">
          <p-button
            class="block relative top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            label="Salvar"
            [loading]="buttonLoading"
            (click)="editMode ? updateMotiveOccurrence() : createMotiveOccurrence()"
            size="small"
            [disabled]="!this.newMotiveOccurrence.description || !this.newMotiveOccurrence.serviceTime || !this.newMotiveOccurrence.groupId || !this.newMotiveOccurrence.type || buttonLoading"
            icon="pi pi-save"
          ></p-button>
        </div>
      </div>
    </p-fieldset>
  </p-dialog>
</div>
