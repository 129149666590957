import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private _showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  get showHeader() {
    return this._showHeader.value;
  }

  set showHeader(value: boolean) {
    this._showHeader.next(value);
  }
}
