import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaskDirective} from "./directives/mask-directive";
import {DialogService} from "primeng/dynamicdialog";
import {MessageService} from "primeng/api";
import {NotFoundComponentComponent} from './not-found-component/not-found-component.component';
import {ValidaFuncionalidadePorPerfilPipe} from "./pipes/valida-funcionalidade-por-perfil.pipe";
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    MaskDirective,
    NotFoundComponentComponent,
    ValidaFuncionalidadePorPerfilPipe,
    VideoDialogComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    ProgressSpinnerModule
  ],
  providers: [DialogService, MessageService],
  exports: [
    NotFoundComponentComponent,
    ValidaFuncionalidadePorPerfilPipe,
    VideoDialogComponent
  ]
})
export class SharedModule {
}
