<div class="xl:px-5 lg:px-4">
  <div class="flex flex-column md:flex-row md:justify-content-between">
    <h1 class="grister__title--page">
      Titulo principal
    </h1>
    <p-button icon="pi pi-cog"
              pRipple
              iconPos="right"
              pTooltip="Editar ordenação dos serviços"
              tooltipPosition="left"
              [style]="{'margin-right': '1rem'}"
              (click)="permiteEditar()"
              size="small"
              [outlined]="!options.draggable!.enabled"
              [rounded]="true"></p-button>
  </div>
  <div class="gridster_panel">
    <gridster [options]="options">
      <gridster-item [item]="item"
                     *ngFor="let item of dashboard;let i=index">
        <div class="p-3">
          <div class="mb-3 h-full flex-column justify-content-between flex">
            <div class="p-2">
              <div>
                <div class="bg-teal-100 inline-flex align-items-center justify-content-center mr-3"
                     style="width: 48px; height: 48px; border-radius: 10px;">
                  <i class="pi pi-shield text-teal-600 text-3xl"></i>
                </div>
                <span class="text-900 font-medium text-2xl">Framework</span>
              </div>
              <div class="text-900 my-3 text-xl font-medium">
                At obcaecati quos ipsam laboriosam.
              </div>
              <p class="mt-0 mb-3 text-700 line-height-3">
                Rhoncus urna neque viverra justo nec ultrices dui sapien
                eget. Id neque aliquam vestibulum morbi blandit cursus risus at ipsum.
              </p>
            </div>
            <div class="text-right">
              <p-button pRipple icon="pi pi-sign-in"
                        iconPos="right" label="Acessar"
                        (click)="goToModulo()"
                        size="small"
                        [rounded]="true" severity="info"
              ></p-button>
            </div>
          </div>
        </div>
      </gridster-item>
    </gridster>
  </div>
</div>

