import {DatePipe} from "@angular/common";

export class TriagemFilter {
  violationId?: number[];
  dateBegin?: string;
  dateEnd?: string;
  deviceId?: number[];
  timeBegin?: string;
  timeEnd?: string;
  reasonId?: number;
  infractionTypeId?: number[];
  contractId?: number;
  userId?: number;
  stepTypeId?: number;
  ehValidacao?: boolean;
  ehHistorico?: boolean;
  ehPreTriagem?: boolean;
  emLote?: boolean;
  processReevaluate?: number;
  filterByUserId?: number;

  descarte?: boolean; // utilizado apenas na pre triagem

  consistent?: boolean;
  reavaliar?: boolean;
  aprovar?: boolean;

  get ehSegundaTriagem(): boolean {
    return this.stepTypeId == 50;
  }

  static validFilter(filter: TriagemFilter): TriagemFilter {
    const clone = structuredClone(filter);

    if (!clone.ehHistorico)
      clone.filterByUserId = undefined;

    clone.emLote = undefined;
    clone.ehValidacao = undefined;
    clone.ehHistorico = undefined;
    clone.ehPreTriagem = undefined;
    // @ts-ignore
    clone.ehSegundaTriagem = undefined;

    if (clone.descarte) {
      clone.stepTypeId = undefined;
    }

    if (!clone.violationId || clone.violationId.length === 0) {
      clone.violationId = undefined;
    }

    if (!!filter.violationId && filter.violationId.length > 0)
      clone.violationId = String(filter.violationId).split(' ').map((i: string) => Number(i));

    return clone;
  }

  static handleFields(filtroConvertido: any, datePipe: DatePipe, originalFilter?: TriagemFilter) {
    if (filtroConvertido?.imageIds?.includes(' ')) {
      filtroConvertido.imageIds = filtroConvertido.imageIds.split(' ').map((i: string) => Number(i));
    } else if (filtroConvertido.imageIds) {
      filtroConvertido.imageIds = [Number(filtroConvertido.imageIds)]
    }
    if (filtroConvertido.dateBegin) {
      filtroConvertido.dateBegin = datePipe.transform(filtroConvertido.dateBegin, 'yyyy-MM-dd');
    }
    if (filtroConvertido.dateEnd) {
      filtroConvertido.dateEnd = datePipe.transform(filtroConvertido.dateEnd, 'yyyy-MM-dd');
    }
    if (filtroConvertido.timeBegin && filtroConvertido.timeBegin instanceof Date) {
      filtroConvertido.timeBegin = `${datePipe.transform(filtroConvertido.timeBegin, 'HH:mm:ss')}`;
    }
    if (filtroConvertido.timeEnd && filtroConvertido.timeEnd instanceof Date) {
      filtroConvertido.timeEnd = `${datePipe.transform(filtroConvertido.timeEnd, 'HH:mm:ss')}`;
    }

    if (originalFilter) {
      if (datePipe.transform(originalFilter.dateBegin, 'yyyy-MM-dd') !== filtroConvertido.dateBegin) {
        filtroConvertido.timeBegin = '00:00:00';
      }
      if (datePipe.transform(originalFilter.dateEnd, 'yyyy-MM-dd') !== filtroConvertido.dateEnd) {
        filtroConvertido.timeEnd = '23:59:59';
      }
    }
  }

  constructor(stepTypeId?: number) {
    this.stepTypeId = stepTypeId;
    // @ts-ignore
    this.dateBegin = new Date(new Date().setDate(new Date().getDate() - 90));
    // @ts-ignore
    this.dateEnd = new Date();

    this.timeBegin = '00:00:00';
    this.timeEnd = '23:59:59';
  }

}
