import {FunctionalityService} from '../../../service/functionality.service';
import {Functionality, FunctionalityDTO} from '../../../models/functionality.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, Subject, takeUntil} from "rxjs";
import { MessageService } from 'primeng/api';
import {BreadcrumbService} from "../../../service/breadcrumb.service";

@Component({
  selector: 'app-functionalities',
  templateUrl: './functionalities.component.html',
  styleUrls: ['./functionalities.component.scss']
})
export class FunctionalitiesComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  functionalities: Functionality[] = [];

  newFunctionality: Functionality = new Functionality()

  functionalityOnEdit: Functionality = {}

  loading = false;

  showEditDialog = false;

  constructor(
    private functionalityService: FunctionalityService,
    private messageService: MessageService,
    private breadCrumb: BreadcrumbService,
  ) {
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Administrador', icon: 'pi pi-users'},
      {label: 'Funcionalidades', icon: 'pi pi-clone'}
    ]);
    this.getFunctionalities()
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getFunctionalities() {
    this.functionalities = [];
    this.loading = true;
    this.functionalityService.getRoles()
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.loading = false),
      )
      .subscribe({
        next: roles => {
          roles.forEach((role: any) => {
            role = this.functionalityService.mapFunctionalityDTOToFunctionality(role)
            this.functionalities.push(role);
          });
        },
      })
  }

  openDialogEdition(route: Functionality) {
    this.showEditDialog = true;
    this.functionalityOnEdit = JSON.parse(JSON.stringify(route))
  }

  saveRole(isEdit: boolean) {
    let functionalityToSave: FunctionalityDTO;
    if (isEdit) {
      functionalityToSave = this.functionalityService.mapFunctionalityToFunctionalityDTO(this.functionalityOnEdit)
    } else {
      functionalityToSave = this.functionalityService.mapFunctionalityToFunctionalityDTO(this.newFunctionality)
    }
    this.functionalityService.registerRole(functionalityToSave)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.showEditDialog = false;
          this.getFunctionalities();
          this.newFunctionality = new Functionality();
          this.functionalityOnEdit = {};
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Funcionalidade'+(isEdit ? ' editada ':' criada ')+'com sucesso!',
          });
        },
        error: (e) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar' +isEdit?' editar ':' criar '+ 'funcionalidade',
          });
        }
      })
  }

  editRoleStatus(role: Functionality, event: any) {
    role.status = event.checked;
    this.functionalityOnEdit = JSON.parse(JSON.stringify(role))
    this.saveRole(true);
  }

  deleteRole(roleId: number) {
    this.functionalityService.delete(roleId)
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.getFunctionalities()),
      )
      .subscribe({
        next: () =>{
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Funcionalidade excluída com sucesso!',
          });
        },
        error: (e) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar excluir funcionalidade',
          });
        },
      })
  }

  verifyRole(role: Functionality): boolean {
    for (const chave in role) {
      if (!role.hasOwnProperty(chave)) {
        continue;
      }
      const valor = role[chave];

      if (valor === undefined || valor === null || valor === '') {
        return false;
      }
    }
    return true;
  }

}
