import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MotiveOccurrenceComponent } from './motive-occurrence/motive-occurrence.component';
import { OccurrenceComponent } from './occurrence/occurrence.component';
import { NewOccurrenceComponent } from './new-occurrence/new-occurrence.component';

const routes: Routes = [
  {
    path: 'motive-occurrence',
    component: MotiveOccurrenceComponent,
  },
  {
    path: 'occurrence',
    component: OccurrenceComponent,
  },
  {
    path: 'new-occurrence',
    component: NewOccurrenceComponent,
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonitoringRoutingModule {
}
