export class Grupo {
  description!: string;
  showonmap!: boolean;
  contractId!: number;
  sinc_with_central_web!: boolean;
  id!: number;
  contract?: any;

  constructor(){
    this.description = '';
    this.contract = {};
  }
}
