<div class="px-6">
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-toast position="top-right" key="toastError"></p-toast>
  <div style="margin: 7% auto; width: 60%">
    <p-card>
      <ng-template pTemplate="body">
        <div class="grid">
          <h5 class="w-full text-xl text-center font-medium my-2">Alterar Senha</h5>
          <div class="col-6 col-offset-3">
            <h5 class="w-full text-lg font-medium my-2">Senha atual</h5>
            <input
              [style]="stylePattern"
              type="password"
              pInputText
              [disabled]="loading"
              [(ngModel)]="currentPassword"
            />
          </div>
          <div class="col-6 col-offset-3">
            <h5 class="w-full text-lg font-medium my-2">Nova senha</h5>
            <input
              [style]="stylePattern"
              type="password"
              [disabled]="loading"
              pInputText
              [(ngModel)]="newPassword"
            />
            <small
              *ngIf="!verifyPassword(newPassword)"
              [style]="stylePattern"
              class="p-invalid text-red-500">
              A senha deve ter pelo menos 8 caracteres e conter ao menos um letra maiúscula e minúscula, um número e um
              caractere especial
            </small>
          </div>
          <div class="col-6 col-offset-3">
            <h5 class="w-full text-lg font-medium my-2">Confirmar nova senha</h5>
            <input
              [style]="stylePattern"
              type="password"
              pInputText
              [disabled]="!verifyPassword(newPassword) || loading"
              [(ngModel)]="confirmPassword"
            />
            <small *ngIf="confirmPassword!=='' && confirmPassword !== newPassword" class="p-invalid text-red-500">Senhas
              não conferem</small>
          </div>
          <div class="col-6 col-offset-3">
            <p-button
              [style]="stylePattern"
              [loading]="loading"
              [disabled]="confirmPassword === ''
              || confirmPassword !== newPassword
              || !verifyPassword(newPassword)"
              (click)="changePassword()"
              class="block relative top-100 my-1 -translate-y-100"
              label="Salvar"
            ></p-button>
          </div>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>
