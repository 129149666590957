<div class="px-6">
  <p-toast position="top-right" key="toastError"></p-toast>
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-card styleClass="my-3">
    <p-fieldset legend="Filtros" [toggleable]="true">
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="grupos"
              inputId="filtroGrupos"
              [required]="true"
              optionLabel="description"
              optionValue="id"
              [(ngModel)]="occurrenceFilter.groupId"
              [filter]="true"
              [showClear]="true"
              placeholder="Selecionar"
              (onChange)="getMotives();getOperations()"
            >
              <ng-template let-grupos pTemplate="item">
                <div class="font-semibold text-center">
                  {{ grupos.description }}
                </div>
              </ng-template>
            </p-dropdown>
            <label htmlFor="filtroGrupos">Grupos</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [options]="occurrenceType"
              [(ngModel)]="occurrenceFilter.type"
              optionLabel="name"
              optionValue="value"
              [style]="stylePattern"
              [showClear]="true"
              inputId="filterAgrupador"
              placeholder="Selecionar"
            ></p-dropdown>
            <label htmlFor="filterAgrupador">Agrupador</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-12">
          <span class="p-float-label">
            <p-dropdown
            [(ngModel)]="occurrenceFilter.operation"
            [filter]="true"
            [showClear]="true"
            [options]="operations"
            optionLabel="operation"
            optionValue="id"
            [style]="stylePattern"
            inputId="filterOperacao"
            placeholder="Selecionar"
            [disabled]="!occurrenceFilter.groupId"
            ></p-dropdown>
            <label htmlFor="filterOperacao">Operação</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              selectionMode="range"
              [readonlyInput]="true"
              inputId="filterRange"
              [class]="!verifyVariables() ? 'ng-invalid ng-dirty' : ''"
              [(ngModel)]="rangeDates"
            ></p-calendar>
            <label htmlFor="filterRange">Intervalo</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [options]="motives"
              [(ngModel)]="occurrenceFilter.motiveOccurrence"
              optionLabel="description"
              optionValue="id"
              [style]="stylePattern"
              inputId="filterAMotivo"
              [disabled]="!occurrenceFilter.groupId"
            ></p-dropdown>
            <label htmlFor="filterMotivo">Motivo</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-3">
          <p-checkbox label="Rejeitado Pelo Orgão" [(ngModel)]="occurrenceFilter.rejected" [binary]="true"></p-checkbox>
        </div>
        <div class="col-4 flex text-align: right;">
          <p-button
            class="block relative mr-2 top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            [disabled]="!verifyVariables()"
            label="Filtrar"
            (onClick)="getOccurrence(false, occurrenceFilter)"
            size="small"
            icon="pi pi-search"
          ></p-button>
          <p-button
            class="block relative top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            label="Limpar Filtro"
            size="small"
            icon="pi pi-times"
            (click)="clearFilter()"
          ></p-button>
        </div>
      </div>
    </p-fieldset>
  </p-card>
  <p-card [styleClass]="!notFound ? '' : 'mb-3'">
    <p-toolbar [styleClass]="!notFound ? 'mb-4 gap-2' : ''">
      <ng-template pTemplate="left">
          <button pButton pRipple label="Nova Ocorrência" icon="pi pi-plus" class="p-button-primary mr-2" (click)="goToNewOccurrence()"></button>
      </ng-template>
    </p-toolbar>
    <p-table
    *ngIf="!notFound"
    [(first)]="first"
    [value]="occurrenceData"
    [totalRecords]="totalRecords"
    [loading]="loading"
    [lazy]="true"
    [tableStyle]="{ 'min-width': '100%' }"
    [paginator]="true"
    [rows]="10"
    (onLazyLoad)="getOccurrence($event, currentFilter)"
    >
      <ng-template pTemplate="header">
        <tr [style]="{ cursor: 'default' }">
          <th class="text-center">Data Início</th>
          <th class="text-center">Data Fim</th>
          <th class="text-center">Operação</th>
          <th class="text-center">Descrição</th>
          <th class="text-center">Motivo</th>
          <th class="text-center">Rejeitado</th>
          <th class="text-center">Ação</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mOccurrence>
        <tr [style]="{ cursor: 'default' }">
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.startDate | date : "dd/MM/yyyy HH:mm:ss"}}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.endDate  | date : "dd/MM/yyyy HH:mm:ss" }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.operationDescription }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.description }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ mOccurrence.motiveOccurrence.description }}
          </td>
          <td class="text-center" [style]="{ 'vertical-align': 'middle' }">
            {{ returnRejectedLabel(mOccurrence.rejected) }}
          </td>
          <td class="actions">
            <button class="p-button-warning" (click)="openEdit(mOccurrence)"  pButton type="button" label="Editar" ></button>
            <button class="p-button-danger" (click)="openDeleteConfirm(mOccurrence.id, mOccurrence.description)" pButton type="button" label="Excluir"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
  <app-not-found-component
  *ngIf="notFound"
  [titulo]="tituloNotFound"
  [descricao]="descricaoNotFound"
  ></app-not-found-component>

  <p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>
</div>
