import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { Contract } from 'src/app/models/contract.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { ContractService } from 'src/app/service/contract.service';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@Component({
  selector: 'app-register-contract',
  templateUrl: './register-contract.component.html',
  styleUrls: ['./register-contract.component.scss']
})
export class RegisterContractComponent {

  private _destroy$: Subject<void> = new Subject<void>();

  contract: Contract = new Contract();

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  constructor(
    private breadCrumb: BreadcrumbService,
    private contractService: ContractService,
    private messageService: MessageService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Administrador', icon: 'pi pi-users'},
      {label: 'Cadastrar contrato', icon: 'pi pi-id-card'}
    ]);
  }

  verifyContract(contract: any): boolean {
    const variablesToCheck = {
      reference: contract.reference,
      identification: contract.identification,
      enterprise: contract.enterprise,
      startDate: contract.startDate,
      endDate: contract.endDate,

    }
    for (const chave in variablesToCheck) {
      if (!variablesToCheck.hasOwnProperty(chave)) {
        continue;
      }
      const valor = variablesToCheck[chave];

      if (valor === undefined || valor === null || valor === '' || valor.length<1) {
        return false;
      }
    }

    return true;
  }

  registerContract(){
    this.contractService.registerContract(this.contract)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: () => {
        setTimeout(() => {
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Contrato cadastrado com sucesso!',
          });
        }, 2000);
        this.router.navigate([RoutesEnum.USER_AUTH_REGISTER_GROUP])
      },
      error: (e) => {
        this.messageService.add({
          key: 'toastError',
          severity: 'error',
          summary: 'Error',
          detail: 'Erro ao tentar cadastrar contrato',
        });
      }
    })
  }

}
