export class Contract {
  reference: string;
  identification: string;
  enterprise: string;
  startDate: any;
  endDate: any;

  constructor(){
    this.reference = '';
    this.identification = '';
    this.enterprise = '';
    this.startDate = '';
    this.endDate = '';

  }
}
