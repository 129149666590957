<div class="boxes">
  <div class="box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
  </div>
  <div class="box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
  </div>
  <div class="box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
  </div>
  <div class="box">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
  </div>
</div>