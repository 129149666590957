import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {Grupo} from '../models/grupo.model';
import {QFV} from '../models/qfv.model';
import {ReportType} from "../models/report-type.model";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import { UserService } from './user.service';

@Injectable({providedIn: 'root'})
export class GruposService extends BaseService {
  reportTypes: Array<ReportType> = [];

  constructor(
    public http: HttpClient,
    private userService: UserService) {
    super(http);
  }

  getGrupos(): Observable<Array<Grupo>> {
    const user = this.userService.user;
    let url: string = this.getEndpoint('GRUPOS');
    url+=`&userId=${user.id}`
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }

  getGrupoId(id: number) {
    const url: string = this.buildUrlQueryParams({id}, this.getEndpoint('GRUPOS_ID'));
    return this.getData(url).pipe(
      map((response) => {
        if (response) {
          this.reportTypes = response;
          return response;
        }
        return [];
      })
    );
  }

  registerGroup(group: Grupo): Observable<any> {
    const url: string = this.getEndpoint('USERS_GROUPS');
    return this.postData(url, group);
  }


  getQFV() {
    const url: string = this.getEndpoint('QFV');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }
}
