import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { Observable, map } from "rxjs";

@Injectable({providedIn: 'root'})
export class ContractService extends BaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  registerContract(contract: any): Observable<any> {
    const url: string = this.getEndpoint('USERS_CONTRACT');
    return this.postData(url, contract);
  }

  getContracts(): Observable<Array<any>> {
    const url: string = this.getEndpoint('USERS_CONTRACT');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }

}
