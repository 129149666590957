import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DeviceStatus} from '../../../../../models/device-status.model';
import {Subject, takeUntil, tap} from "rxjs";
import {DeviceStatusService} from "../../../../../service/device-status.service";

@Component({
  selector: 'device-info',
  templateUrl: './device-info.component.html',
  styleUrls: ['./device-info.component.scss']
})
export class DeviceInfoComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() device: any = new DeviceStatus();
  @Input() dialogShow: boolean = false;
  @Output() dialogShowChange = new EventEmitter<boolean>();

  dataGraphHistory: any = []
  dataGraphDataSet: any = []
  labels: string[] = [];
  isSensor: boolean = false;
  deviceId: number = 0;

  showGraphWindow = false;
  data: any;
  options: any;

  dataSeries: any;

  constructor(private seviceStatusService: DeviceStatusService) {
    this.data = {
      labels:
        ["", "Purple", ""],
      datasets: [
        {
          data: [88.5, 1, 10.5],
          backgroundColor: [
            "rgba(0,0,0,0)",
            "rgba(255,255,255,1)",
            "rgba(0,0,0,0)",
          ],
          borderColor: [
            'rgba(0, 0, 0 ,0)',
            'rgba(46, 204, 113, 1)',
            'rgba(0, 0, 0 ,0)'
          ],
          borderWidth: 3
        }]
    };
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  openGraph(labels: string[], isSensor: boolean) {
    this.labels = labels;
    this.isSensor = isSensor;
    this.deviceId = this.device.deviceId;
    if (isSensor) {
      this.seviceStatusService.getDeviceDataSet(this.deviceId)
        .pipe(
          takeUntil(this._destroy$),
          tap((r: any) => {
            this.dataGraphDataSet = r
            this.showGraphWindow = true;
          })
        ).subscribe()
    } else {
      this.seviceStatusService.getDeviceDataHistory(this.deviceId)
        .pipe(
          takeUntil(this._destroy$),
          tap((r: any) => {
            this.dataGraphHistory = r
            this.showGraphWindow = true;
          })
        ).subscribe()
    }
  }


  closeEventsWindow() {

    this.dialogShow = false;
    this.cleanDialog();
  }

  cleanDialog() {
    //this.isInfoWindowOpen = false;
    this.dialogShowChange.emit(false);
  }

  openCam(url: any) {
    if(url == null) return;
    window.open(url, '_blank');
  }

  formatDataNumber(value: any) {
    if(value == null || isNaN(value)) return 0
    return value
  }

}
