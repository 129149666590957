import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {PBTConfiguration} from '../models/pbt-configuration.model';
import {AlertConfiguration, AlertType} from '../models/alert-configuration.model';
import {DataTablePage} from '../models/data-table-page.model';
import {AlertReport} from '../models/alert-report.model';
import moment from 'moment';
import {Filters} from '../models/filters.model';
import {DeviceEventType} from '../shared/enums/device-event-type.enum';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, of} from "rxjs";
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService extends BaseService {

  constructor(public override httpClient: HttpClient,
    public userService: UserService) {
    super(httpClient);
  }

  private deviceToNavigate: BehaviorSubject<any> = new BehaviorSubject(null);
  deviceToNavigate$: Observable<any> = this.deviceToNavigate.asObservable();

  setDeviceToNavigate(device: any) {
    this.deviceToNavigate.next(device);
  }

  getDeviceToNavigate(): Observable<any> {
    return this.deviceToNavigate$;
  }

  //AlertPBT
  getAlertPBT(): Observable<Array<PBTConfiguration>> {
    const url: string = this.getEndpoint('PBT_CONFIGURATION');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }

  updateAlertPBT(alertPBT: PBTConfiguration): Observable<PBTConfiguration> {
    let url: string = this.getEndpoint('PBT_CONFIGURATION');
    url += '/?id=' + alertPBT.id;
    return this.putData(url, alertPBT);
  }

  //AlertSpeed
  getAlertConfiguration(page: DataTablePage, filters: any): Observable<Array<AlertConfiguration>> {
    let url: string = this.buildUrlQueryParams({
      page: page.pageNumber,
      size: page.size
    }, this.getEndpoint('ALERT_CONFIGURATION_PAGINATION'));
    if (filters.grupos) {
      url += '&groupId=' + filters.grupos;
    }
    if (filters.device) {
      if(filters.device.length>0){
        url += '&devicesId=' + filters.device.join(',');
      }
    }
    if (filters.plate) {
      url += '&plate=' + filters.plate.toUpperCase();
    }
    if (filters.alertType) {
      url += '&alertType=' + filters.alertType;
    }
    if (filters.condition) {
      url += '&condition=' + filters.condition;
    }
    if (filters.referenceValue) {
      url += '&referenceValue=' + filters.referenceValue;
    }
    if (filters.severity) {
      url += '&severity=' + filters.severity;
    }
    if (filters.description) {
      url += '&description=' + filters.description;
    }
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            page.totalElements = response.totalElements;
            page.totalPages = page.totalElements / page.size;
            return response;
          }
          return [];
        })
      );
  }

  saveAlertConfiguration(newAlertConfig: AlertConfiguration): Observable<AlertConfiguration> {
    const url: string = this.getEndpoint('ALERT_CONFIGURATION');
    return this.postData(url, newAlertConfig);
  }

  updateAlertConfiguration(alertConfig: AlertConfiguration): Observable<AlertConfiguration> {
    let url: string = this.getEndpoint('ALERT_CONFIGURATION');
    return this.putData(url, alertConfig);
  }

  deleteAlertConfiguration(idAlertConfig: AlertConfiguration[]): Observable<any> {
    const references: any = idAlertConfig.map(r => {
      return r.id;
    })
    let url: string = this.getEndpoint('ALERT_CONFIGURATION');
    url += '?ids=' + references;
    return this.deleteData(url);
  }

  //AlertReport

  getCountAlertReports(){
    const url: string = this.getEndpoint('ALERT_REPORT_COUNT');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }

  getAlertReport(page: DataTablePage, filters: any): Observable<any> {
    let url: string = this.buildUrlQueryParams({
      skipcount: page.pageNumber,
      maxcount: page.size
    }, this.getEndpoint('ALERT_REPORT'));
    if (filters.grupos) {
      url += '&groupId=' + filters.grupos;
    }
    if (filters.device) {
      if(filters.device.length>0){
        url += '&equipmentsId=' + filters.device.join(',');
      }
    }
    if (filters.qfvClass) {
      url += '&qfvClass=' + filters.qfvClass;
    }
    if (filters.alertType) {
      url += '&alertType=' + filters.alertType;
    }
    if (filters.plate) {
      url += '&plate=' + filters.plate;
    }
    if (filters.lane) {
      url += '&lane=' + filters.lane;
    }
    if (filters.images) {
      url += '&images=' + filters.images;
    }
    if (filters.startDate && filters.endDate) {
      filters.startDate.setHours(0, 0, 0, 0);
      filters.endDate.setHours(23, 59, 0, 0);

      if (filters.startHour) {
        const startHourSplitted = String(moment(filters.startHour).format('HH:mm')).split(':');
        filters.startDate.setHours(
          (parseInt(startHourSplitted[0])),
          parseInt(startHourSplitted[1])
        );
      }
      if (filters.endHour) {
        const endHourSplitted = String(moment(filters.endHour).format('HH:mm')).split(':');
        filters.endDate.setHours(
          (parseInt(endHourSplitted[0])),
          parseInt(endHourSplitted[1])
        );
      }
      const startDate: string = moment(filters.startDate).format('YYYY-MM-DD HH:mm');
      const endDate: string = moment(filters.endDate).format('YYYY-MM-DD HH:mm');
      url += `&dataInicio=${startDate}&dataFim=${endDate}`;
    }
    return this.getData(url)
      .pipe(
        map((response) => {
          page.totalElements = response.totalElements;
          page.totalPages = page.totalElements / page.size;
          return response;
        })
      );
  }


  getAlertByDeviceId(deviceId: number): Observable<AlertReport> {
    const url: string = this.buildUrlQueryParams({
      deviceId: deviceId
    }, this.getEndpoint('ALERT_REPORT_BY_DEVICE'));
    return this.getData(url);
  }

  //AlertDetails
  getAlertEventDetails(eventId: number, eventType: string): Observable<any> {
    let url: string = this.getEndpoint('EVENTS_ALERT');
    if (eventType === DeviceEventType.FLOW) {
      url += '/flow'
      url += `?flowId=${eventId}`
    } else if (eventType === DeviceEventType.WEIGHING) {
      url += '/weighing'
      url += `?weighingId=${eventId}`
    } else {
      return of([]);
    }
    return this.getData(url);
  }

  consultaImgBucketS3(urlImagem?: string, onLoadImagem?: (imageObjectUrl: string) => void | any) {
    if (!urlImagem)
      return;

    fetch(`${environment.baseApi}${urlImagem}`, {
      headers: new Headers({
        'Authorization': this.userService.getToken() ?? '',
      })
    }).then(response => response.blob()) // Converter a resposta em Blob
      .then(blob => {
        const imageObjectUrl = URL.createObjectURL(blob);
        if (onLoadImagem)
          onLoadImagem(imageObjectUrl);
      }).catch(error => console.error('Erro ao carregar a imagem:', error));
  }
}
