<div class="px-6">
  <p-card styleClass="my-3">
    <p-fieldset legend="Filtros" [toggleable]="true">
      <div class="grid mt-3">
        <div class="col-3">
          <span class="p-float-label">
            <p-dropdown
              (onChange)="gatilhoItemFiltro($event)"
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="grupos"
              inputId="filtroGrupos"
              [(ngModel)]="filters.grupos"
              [class]="!filters.grupos ? 'ng-invalid ng-dirty' : ''"
              [required]="true"
              optionLabel="description"
              [filter]="true"
              [showClear]="true"
              placeholder="Selecionar"
            >
              <ng-template let-grupos pTemplate="item">
                <div class="font-semibold text-center">
                  {{ grupos.description }}
                </div>
              </ng-template>
            </p-dropdown>
            <label htmlFor="filtroGrupos">Grupos</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-dropdown
              [options]="deviceType"
              (onChange)="typeChange($event)"
              [(ngModel)]="selectedType"
              optionLabel="description"
              [style]="stylePattern"
              inputId="filterTipo"
            ></p-dropdown>
            <label htmlFor="filterTipo">Tipo</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-multiSelect
              [maxSelectedLabels]="1"
              selectedItemsLabel="{0} QFVs selecionados"
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="qfv"
              optionLabel="description"
              optionValue="vehicle_class"
              [disabled]="disableQFV"
              [filter]="true"
              [showClear]="true"
              [tooltipDisabled]="!disableQFV"
              [pTooltip]="'Filtro disponível apenas para Pesagem'"
              tooltipPosition="left"
              placeholder="Selecionar"
              inputId="filterClasseQFV"
              [(ngModel)]="filters.qfvClass"
            >
              <ng-template let-qfvs pTemplate="item">
                <div class="font-semibold">
                  {{ qfvs.vehicle_class }} - {{ qfvs.description }}
                </div>
              </ng-template>
            </p-multiSelect>
            <label htmlFor="filterClasseQFV">Classe QFV</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <input
              type="text"
              maxlength="7"
              pInputText
              id="filterPlaca"
              class="text-transform: uppercase"
              [style]="stylePattern"
              [(ngModel)]="filters.plate"
              pattern="[A-Za-z]{3}[0-9]{1}[A-Za-z]{1}[0-9]{2}|[A-Za-z]{3}[0-9]{4}"
            />
            <label htmlFor="filterPlaca">Placa</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              selectionMode="range"
              [readonlyInput]="true"
              inputId="filterRange"
              [class]="!verifyRangeDate() ? 'ng-invalid ng-dirty' : ''"
              [(ngModel)]="rangeDates"
            ></p-calendar>
            <label htmlFor="filterRange">Intervalo</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-multiSelect
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="list_dispositivos"
              [virtualScroll]="list_dispositivos.length === todosDispositivosSize"
              [virtualScrollItemSize]="38"
              [class]="!filters.device ? 'ng-invalid ng-dirty' : ''"
              [required]="true"
              optionLabel="equipmentName"
              [optionValue]="selectedViolationType ? 'equipmentIdLegacy' : 'equipmentId'"
              inputId="filterDispositivo"
              [filter]="true"
              [showClear]="true"
              [lazy]="true"
              placeholder="Selecione o grupo"
              [(ngModel)]="filters.device"
              [disabled]="!filters.grupos"
            >
            </p-multiSelect>
            <label htmlFor="filterDispositivo">Equipamentos</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              [readonlyInput]="true"
              [timeOnly]="true"
              [showTime]="true"
              hourFormat="24"
              inputId="filterHoraInit"
              placeholder="hh:mm"
              [(ngModel)]="filters.startHour"
            ></p-calendar>
            <label htmlFor="filterHoraInit">Hora Início</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              [readonlyInput]="true"
              inputId="filterHoraFim"
              [timeOnly]="true"
              [showTime]="true"
              hourFormat="24"
              placeholder="hh:mm"
              [(ngModel)]="filters.endHour"
            ></p-calendar>
            <label htmlFor="filterHoraFim">Hora Fim</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-3">
          <span class="p-float-label">
            <p-dropdown
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="laneOptions"
              [(ngModel)]="filters.lane"
              [showClear]="true"
              inputId="filterLane"
              placeholder="Selecionar"
            >
            </p-dropdown>
            <label htmlFor="filterLane">Pista</label>
          </span>
        </div>
        <div *ngIf="!disableQFV"  class="col-6 mt-2">
          <p-checkbox
          label="Considerar apenas com imagens"
          [binary]="true"
          [(ngModel)]="filters.images"
          ></p-checkbox>
        </div>
        <div class="col-3 text-align: right;">
          <p-button
            class="block relative top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            [disabled]="!verifyVariables()"
            [loading]="loading"
            label="Pesquisar"
            size="small"
            icon="pi pi-search"
            (onClick)="verifyVariables() ? search(null, true, selectedViolationType) : ''"
          ></p-button>
        </div>
      </div>
    </p-fieldset>
  </p-card>
  <p-table
    [attr.hidden]="lastSearchAsViolationType || notFound ? true : null"
    [(first)]="first"
    [paginator]="true"
    [lazy]="true"
    (onLazyLoad)="!lastSearchAsViolationType ? loadRel($event, false): ''"
    [totalRecords]="totalRecords"
    [loading]="loading"
    [rows]="10"
    selectionMode="multiple"
    [(selection)]="selected"
    [value]="events"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Data</th>
        <th>Dispositivo</th>
        <th>Tipo</th>
        <th>Placa</th>
        <th>Velocidade</th>
        <th>Peso</th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-results
      let-rowIndex="rowIndex"
    >
      <tr (click)="showDialog(results)">
        <td>
          <label>{{ results.date | date : "dd/MM/yyyy HH:mm:ss"}}</label>
        </td>
        <td>
          <label>{{ results.device.name }}</label>
        </td>
        <td>
          <label>{{ getTypeName(results.type) }}</label>
        </td>
        <td>
          <label> {{ results.plate || "-" }}</label>
        </td>
        <td>
          <label>{{ results.speed ? results.speed + " km/h" : "-" }}</label>
        </td>
        <td>
          <label>{{ results.pbt ? results.pbt + " Kg" : "-" }}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-table
  [attr.hidden]="!lastSearchAsViolationType || notFound ? true : null"
  [(first)]="first"
  [paginator]="true"
  [lazy]="true"
  (onLazyLoad)="lastSearchAsViolationType ? loadRel($event, true): ''"
  [totalRecords]="totalRecords"
  [loading]="loading"
  [rows]="10"
  selectionMode="multiple"
  [(selection)]="selected"
  [value]="violationEvents"
  dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Data</th>
        <th>Dispositivo</th>
        <th>Placa</th>
        <th>Tipo de veículo</th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-results
      let-rowIndex="rowIndex"
    >
      <tr (click)="showViolationDialog(results)">
        <td>
          <label>{{ results.violationDate | date : "dd/MM/yyyy HH:mm:ss"}}</label>
        </td>
        <td>
          <label>{{ results.deviceName }}</label>
        </td>
        <td>
          <label> {{ results.plate || "-" }}</label>
        </td>
        <td>
          <label>{{ results.vehicleClassification}}</label>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <app-not-found-component
  *ngIf="notFound"
  [titulo]="tituloNotFound"
  [descricao]="descricaoNotFound"
  ></app-not-found-component>
</div>
<real-time-events-details-window
  *ngIf="display"
  [(showDetails)]="display"
  [(detailsData)]="valorObjeto"
></real-time-events-details-window>

<violation-events-details-window
  *ngIf="displayVDetails"
  [(showDetails)]="displayVDetails"
  [(detailsData)]="valorViolationObjeto"
>
</violation-events-details-window>
