import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'real-time-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {


  @Input() data: any[] = [];
  images: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.images = [];
      if(this.data[0].images !== undefined){
        for (let i = 0; i < this.data[0].images.length; i++) {
          this.images.push(this.data[0].images[i]);
        }
      }
    }
  }

  nextImage() {
    document.getElementById('slide')!.scrollLeft += 200;
  }
  previousImage() {
    document.getElementById('slide')!.scrollLeft -= 200;
  }

}
