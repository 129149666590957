import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'real-time-info-window',
  templateUrl: './info-window.component.html',
  styleUrls: ['./info-window.component.scss']
})
export class InfoWindowComponent {

	@Input() data: any[] = [];
	@Input() dataMetrics: any[] = [];
	@Input() show: boolean = false;
	@Output() showChange = new EventEmitter<boolean>();

	selectedDevicesByName: Array<any> = []
	dialog = false;
	videoDisplay = false;
	isInfoWindowOpen = false;
	teste: any;

	selectedDeviceName: string = '';

	indexVideo!: number;

	constructor() {

	}


	ngOnInit() {
	}

	openVideo(index: number) {
		this.videoDisplay = true;
		this.indexVideo = index;
	}

	closeInfoDialog() {
		this.dialog = false;
		this.showChange.emit(false);
	}

	async showDialog(deviceName: string) {
		this.selectedDevicesByName = this.dataMetrics.filter((d) => d.deviceName  === deviceName);
		setTimeout(() => {
			this.dialog = true;
		},10);
	}
}
