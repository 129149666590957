<p-dialog
  [(visible)]="showDetails"
  (onHide)="cleanDialog()"
  (onShow)="loadEvent()"
  [modal]="true"
  [style]="{ width: '50%', height: '70%' }"
>
  <div class="loading">
    <p-progressSpinner *ngIf="showSpinner"></p-progressSpinner>
  </div>
  <div class="full">
    <div *ngIf="detailsData?.documents"  class="imageCard">
      <div
        class="image"
        *ngFor="let image of detailsData?.documents"
        (click)="fullscrennImage(image.img)"
      >
        <img src="{{ image.img }}" width="250" />
        <!-- <img
          *ngIf="detailsData.documents?.length === 0"
          src="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png"
        /> -->
      </div>
    </div>
    <p class="name" (click)="testeConsole()">{{ detailsData?.device?.name }}</p>
    <label>{{ detailsData?.device?.addresses[0]?.streetName }}, {{ detailsData?.device?.addresses[0]?.city }} -
      {{ detailsData?.device?.addresses[0]?.state }}</label>
    <div class="detail">
      <div class="item">
        <label>Data e Hora</label>
        <p>{{ detailsData?.date | date : "dd/MM/yyyy," }}</p>
        <p>{{ detailsData?.date | date : "HH:mm:ss" }}</p>
      </div>
      <div class="item">
        <label>Direção</label>
        <p>{{ detailsData?.device?.addresses[0]?.flowDirection || "-" }}</p>
      </div>
      <div class="item">
        <label>Pista</label>
        <p>{{ detailsData?.device?.addresses[0]?.laneIdentification || "-" }}</p>
      </div>
      <div class="item">
        <label>Velocidade</label>
        <p *ngIf="detailsData?.speed">{{ detailsData.speed ? (detailsData.speed + ' km/h') : "-" }}</p>
      </div>
      <div class="item">
        <label>Placa</label>
        <p>{{ detailsData.plate || "-" }}</p>
      </div>
      <div class="item">
        <label>Comprimento</label>
        <p>{{ detailsData.vehicle_lenght ? (detailsData.vehicle_lenght + ' cm') : "-" }}</p>
      </div>
      <div class="item">
        <label [ngClass]="detailsData.distance_from_previous_vehicle == 0 ? 'esconder' : ''">Distância veículo anterior</label>
        <p>
          {{
          detailsData.distance_from_previous_vehicle == 0
          ? ""
          : detailsData.distance_from_previous_vehicle
          }}
        </p>
      </div>
      <!-- <div *ngIf="detailsData?.type == 'WEIGHING'"> -->
        <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>PBT</label>
        <p>{{ !!detailsData.pbt ? detailsData.pbt+' kg' : "-" }}</p>
      </div>
      <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>Limite PBT da Classificação</label>
        <p>{{ !!detailsData.classifications_pbt_limit ? detailsData.classifications_pbt_limit +' kg' : "-" }}</p>
      </div>
      <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>Classificação do Veículo</label>
        <p>{{ detailsData.vehicle_classification || "-" }}</p>
      </div>
      <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>Classificação da Pesagem</label>
        <p>{{ detailsData.wheighing_classification || "-" }}</p>
      </div>
      <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>Peso de cada eixo</label>
        <p>{{ detailsData.axleJson.length > 0 ? getAxlesPbt(detailsData.axleJson) : "-" }}</p>
      </div>
      <div class="item" *ngIf="isWheighing(detailsData.type)">
        <label>Excesso PBT</label>
        <p>{{ !!detailsData.pbt_excess ? detailsData.pbt_excess > 0 ? detailsData.pbt_excess+' kg' : "0 kg" : '-' }}</p>
      </div>
    </div>
  </div>
</p-dialog>

<div class="fullscreen">
  <p-dialog
    [(visible)]="fullscreenImg"
    [modal]="true"
    [style]="{ width: '100%', height: '100%' }"
  >
    <img src="{{ imgUrl }}" />
  </p-dialog>
</div>
