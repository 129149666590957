import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'card-device-status',
  templateUrl: './card-device-status.component.html',
  styleUrls: ['./card-device-status.component.scss']
})
export class CardDeviceStatusComponent implements OnInit {
  @Input() redColor: boolean = false;
  @Input() title: string = '';
  @Input() status: any;
  @Input() type: 'temperature' | 'date' = 'temperature';

  editCard =
    {'background':'#22C55E'};

  constructor() {
  }

  ngOnInit(): void {
    if(this.status>50) {
      this.editCard = {'background':'#EF4444'}
    }
    if(this.type==='temperature'){
      this.editCard = {'background':'#fff'}

    }
  }

  getValue(){
    if(this.type === 'temperature')
      return this.status + 'ºC';
    return this.status;
  }
}
