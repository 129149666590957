import {Injectable} from "@angular/core";
import {Functionality, FunctionalityDTO} from "../models/functionality.model";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class FunctionalityService extends BaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  getRoles() {
    const url: string = this.getEndpoint('USERS_ROLE');
    return this.getData(url);
  }


  registerRole(role: FunctionalityDTO) {
    const url: string = this.getEndpoint('USERS_ROLE');
    return this.postData(url, role);
  }

  delete(roleId: number) {
    let url: string = this.getEndpoint('USERS_ROLE');
    url += `?id=${roleId}`
    return this.deleteData(url);
  }


  mapFunctionalityDTOToFunctionality = (functionalityDTO: FunctionalityDTO): Functionality => {
    const {id, roleName: name, roleRout: route, active: status} = functionalityDTO;
    return {id, name, route, status};
  }

  mapFunctionalityToFunctionalityDTO = (functionality: Functionality): FunctionalityDTO => {
    const {id: id, name: roleName, route: roleRout, status: active} = functionality;
    return {id, roleName, roleRout, active};
  }
}
