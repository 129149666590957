<div id="mainDiv">
  <p-toast position="top-right" key="toastError"></p-toast>
  <real-time-data-layer
    (layerHeatChange)="changeLayerHeat($event)"
    (layerTrafficChange)="changeLayerTraffic($event)"
    (layerTaxaChange)="changeLayerTaxa($event)"
    (layerCaminhaoChange)="changeLayerCaminhao($event)"
    (onlyWimChange)="filterOnlyWim($event)"
  ></real-time-data-layer>
  <real-time-devices-layer
  [dataMetrics]="dataMetrics"
  (goToLocation)="toLocation($event)"
  ></real-time-devices-layer>
  <real-time-heat-map3d
    *ngIf="this.isOpenLayers"
    (infoWindowData)="setInfoWindowData($event)"
    [(isInfoWindow)]="showInfoWindow"
  ></real-time-heat-map3d>
  <real-time-info-window
    [data]="infoWindowData"
    [dataMetrics]="devicesMarker"
    [(show)]="showInfoWindow"
  ></real-time-info-window>
  <p-dialog
    class="loading"
    [showHeader]="false"
    [modal]="false"
    [(visible)]="loading"
    [style]="{ width: '100px', height: '100px' }"
    [baseZIndex]="10000"
  >
    <div>
      <p-progressSpinner id="loading"></p-progressSpinner>
    </div>
  </p-dialog>
</div>
