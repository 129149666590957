<p-toast position="top-right" key="toastError"></p-toast>

<div class="search">
  <div class="filter">
    <div>
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class="dropdown" type="text" pInputText placeholder="Pesquisar" (input)="search($event)"/>
      </span>
    </div>
    <div class="group">
      <span class="p-float-label">
        <p-dropdown
          [class]="!groupselected ? 'ng-invalid ng-dirty' : ''"
          [style]="stylePattern"
          [scrollHeight]="'30rem'"
          optionLabel="description"
          optionValue="id"
          [filter]="true"
          [options]="groups"
          [showClear]="true"
          (onChange)="onChangeGroup($event)"
          placeholder="Selecionar"
          inputId="filterGroup"
          [(ngModel)]="groupselected"
          [required]="true"
        >
        <ng-template let-group pTemplate="item">
          <div class="font-semibold dropdown-options-limits text-center">
            {{ group.description }}
          </div>
          </ng-template>
        </p-dropdown>
        <label htmlFor="filterEquipamento1">Grupo</label>
      </span>
    </div>
    <button pButton type="button" class="p-button-outlined" (click)="sendListEquipment()" icon="pi pi-table" label="Ver tabela" ></button>
  </div>
</div>

<div class="container"
  infiniteScroll
  [infiniteScrollDistance]="0.5"
  [infiniteScrollThrottle]="50"
  (scrolled)="loadDevices()">
  <card-device *ngFor="let equipment of devicesFiltered" (showVideoDialog)="showDevice($event)" [equipmentData]="equipment"></card-device>
</div>

<shared-video-dialog
  *ngIf="dataDevice"
  [(videoDisplay)]="videoDisplay"
  [port]="dataDevice.urlAccessPort"
  [urlCam]="dataDevice.urlCamAccess"
></shared-video-dialog>
