export class DeviceStatus {
  deviceId!: number;
  deviceName!: string;
  address!: string;
  online!: boolean;
  flowings!: number;
  infractions!: number;
  weighings!: number;
  captureOCR!: number;
  captureUse!: number;
  captureUseDay!: number;
  captureUseNight!: number;
  device_temperature!: number;
  trackTemperature!: number;
  last_energy_break!: string;
  last_opened_door!: string;
  urlCamAccess!: string;
  urlAccessPort!: number;
  urlAdminAccess!: string;
  link1!: string;
  link2!: string;
  dataSeries1!: number[];
  dataSeries2!: number[];
  dataSeries3!: number;
  id!: number;
  port!: number;
}
