import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MenuComponent} from "./menu/menu.component";
import {TieredMenuModule} from "primeng/tieredmenu";
import {SidebarModule} from "primeng/sidebar";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {ToastModule} from "primeng/toast";
import {PanelMenuModule} from "primeng/panelmenu";
import {MenubarModule} from "primeng/menubar";
import {DialogModule} from "primeng/dialog";
import {BadgeModule} from "primeng/badge";
import {ListboxModule} from "primeng/listbox";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MenuModule} from "primeng/menu";
import {ConfirmationService, MessageService} from "primeng/api";
import {AvatarModule} from "primeng/avatar";
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerImgComponent } from './spinner-img/spinner-img.component';
import {RippleModule} from "primeng/ripple";
import {StyleClassModule} from "primeng/styleclass";
import {TotalReservadasPipe} from "./menu/pipes/total-reservadas.pipe";

@NgModule({
  declarations: [
    MenuComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    SpinnerImgComponent,
  ],
  imports: [
    CommonModule,

    TieredMenuModule,
    SidebarModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    PanelMenuModule,
    MenubarModule,
    DialogModule,
    BadgeModule,
    ListboxModule,
    OverlayPanelModule,

    FormsModule,
    HttpClientModule,
    MenuModule,
    NgOptimizedImage,
    AvatarModule,
    BreadcrumbModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    RippleModule,
    StyleClassModule,
    TotalReservadasPipe,
  ],
  exports: [
    MenuComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    SpinnerImgComponent
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class ThemeModule { }
