import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceStatus } from '../../../../../models/device-status.model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'card-device',
  templateUrl: './card-device.component.html',
  styleUrls: ['./card-device.component.scss']
})
export class CardDeviceComponent {

  @Input() equipmentData!: DeviceStatus;

  showInfoWindow = false;

  star: boolean = false;

  @Output() showVideoDialog: EventEmitter<any> = new EventEmitter<any>();

  starStyle = { 'color': '#F5F949', 'text-stroke': '1px #CAD400', '-webkit-text-stroke': '1px #CAD400' };

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  openInfo() {
    this.showInfoWindow = true;
  }

  getTemperature() {
    if (this.equipmentData.trackTemperature <= 0) {
      return '-';
    } else {
      return this.equipmentData.trackTemperature + 'ºC';
    }
  }

  openVideo(event: Event) {
    event.stopPropagation();
    if (this.equipmentData.urlAccessPort && this.equipmentData.urlAccessPort > 0 && this.equipmentData.urlCamAccess !== null) {
      this.setCookie();
      // window.open(this.equipmentData.urlCamAccess, "_blank");
      this.showVideoDialog.emit(this.equipmentData);
    } else {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipamento sem acesso',
      });
    }
  }

  setCookie() {
    document.cookie = 'port=' + this.equipmentData.port + ';Path=/;domain=appvelsis.com;SameSite=None;Secure';
  }

  openLink(event: any, url: string, port: number) {
    event.stopPropagation();
    if(port == null || url == null) {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipamento sem acesso',
      });
      return;
    }
    window.open(`${url}:${port}`,'_blank');
  }

}
