import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbService} from "../service/breadcrumb.service";
import {InactivityService} from "../service/inactivity.service";
import {TriagemService} from "../service/triagem.service";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent implements OnInit, OnDestroy {

  constructor(public breadCrumb: BreadcrumbService,
              public triagemService: TriagemService,
              public inactivityService: InactivityService) {
  }

  ngOnInit() {
    this.breadCrumb.next([]);
    this.inactivityService.setup();
    this.triagemService.loadContratosAndDomains();
  }

  ngOnDestroy() {
    this.triagemService.cleanCache$.next();
    this.triagemService.cleanCache$.complete();
    this.inactivityService.teardown();
  }
}
