import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, finalize, forkJoin, map, Observable, of, Subject, takeUntil} from "rxjs";
import {MotivoDescarte} from "../models/motivo-descarte.model";
import {InfractionTypes} from "../models/infraction-types.model";
import {TriagemFilter} from "../models/triagem-filter.model";
import {TriagemResponse} from "../models/triagem-response.model";
import {sortTriagens, TriagemDetail, UniqueReservadasEmTelaSet} from "../models/triagem-detail.model";
import {TriagemBatch} from "../models/triagem-batch.model";
import {RoutesEnum} from "../shared/enums/routes.enum";
import {Router} from "@angular/router";
import {EquipamentoTriagem} from "../models/equipamento-triagem.model";
import {ClassificacaoVeiculoTriagem} from "../models/classificacao-veiculo-triagem.model";
import {TriagemInfracaoSave} from "../models/triagem-infracao-save.model";
import {UpdateImagemTriagem} from "../models/update-imagem-triagem.model";
import {UpdateViolationProcess} from "../models/update-violation-process.model";
import {environment} from "../../environments/environment";
import {TriagemReevaluate} from "../models/triagem-reevaluate.model";
import {TriagemStepHistory} from "../models/triagem-step-history.model";
import {DescarteOrAceiteType, PreTriagem} from "../models/pre-triagem.model";
import {TriagemCountReservadas} from "../models/triagem-count-reservadas.model";
import {UserService} from "./user.service";
import {DuplicidadeInfracao} from "../models/duplicidade-infracao.model";
import {CacheListPorContrato, ContratoTriagem} from "../models/contrato-triagem.model";
import {UserFilter} from "../models/user-filter.model";

@Injectable({
  providedIn: 'root'
})
export class TriagemService extends BaseService {
  seguraConsultaReservadas: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cleanCache$: Subject<void> = new Subject<void>();
  discartActiveObject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  efetuouReservaDeTriagem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  usuario$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  msgSucesso$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  triagemSelecionada: BehaviorSubject<null | TriagemDetail | TriagemDetail[]> = new BehaviorSubject<any>(null);
  triagemFilterSelecionada: BehaviorSubject<TriagemFilter | null> = new BehaviorSubject<TriagemFilter | null>(null);
  triagemFilterGroupSelecionada: BehaviorSubject<TriagemFilter | null> = new BehaviorSubject<TriagemFilter | null>(null);
  colarRecortePlaca$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  env = environment;
  urlLegacyAPI: string = `${environment.baseApi}legacy`;
  urlScreening: string = `/api/screening`;
  urlPreInfraction: string = `/api/preinfraction`;
  urlValidation: string = `/api/validation`;
  urlHistorico: string = `/api/history`;

  contractsAlreadyLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  cacheContratos$: BehaviorSubject<ContratoTriagem[]> = new BehaviorSubject<ContratoTriagem[]>([]);
  cacheMotivoDescarte$: BehaviorSubject<CacheListPorContrato<MotivoDescarte>[]> = new BehaviorSubject<CacheListPorContrato<MotivoDescarte>[]>([]);
  cacheTiposDeInfracao$: BehaviorSubject<CacheListPorContrato<InfractionTypes>[]> = new BehaviorSubject<CacheListPorContrato<InfractionTypes>[]>([]);
  cacheEquipamentos$: BehaviorSubject<CacheListPorContrato<EquipamentoTriagem>[]> = new BehaviorSubject<CacheListPorContrato<EquipamentoTriagem>[]>([]);
  cacheClassificacaoVeiculo$: BehaviorSubject<CacheListPorContrato<ClassificacaoVeiculoTriagem>[]> = new BehaviorSubject<CacheListPorContrato<ClassificacaoVeiculoTriagem>[]>([]);
  cacheUsuarios$: BehaviorSubject<CacheListPorContrato<UserFilter>[]> = new BehaviorSubject<CacheListPorContrato<UserFilter>[]>([]);

  infracoesAvaliarDepois$: BehaviorSubject<UniqueReservadasEmTelaSet> = new BehaviorSubject<UniqueReservadasEmTelaSet>(new UniqueReservadasEmTelaSet());

  private processedInfractions: BehaviorSubject<any> = new BehaviorSubject([]);
  processedInfractions$: Observable<any> = this.processedInfractions.asObservable();

  setProcessedInfractions(infractions: any) {
    this.processedInfractions.next(infractions);
  }

  getProcessedInfractions(): Observable<any> {
    return this.processedInfractions$;
  }

  constructor(public http: HttpClient,
              public userService: UserService) {
    super(http);
  }

  loadContratosAndDomains() {
    const user = this.userService.user;
    const possiveisContratos: Observable<any>[] = [];
    user.groups?.forEach(grupo => {
      const url: string = (`${this.urlLegacyAPI}/${grupo.id}/api/contract/getById?id=${grupo.contractId}`);
      possiveisContratos.push(
        this.getData(url)
          .pipe(
            catchError(() => of(null)),
            map(res => {
              if (res)
                res.groupId = grupo.id;

              return res;
            }))
      );
    })
    forkJoin(possiveisContratos)
      .pipe(takeUntil(this.cleanCache$))
      .subscribe({
        next: (arrayContratosList) => {
          if (arrayContratosList) {
            const contratos: ContratoTriagem[] = [];
            arrayContratosList.filter(contrato => !!contrato).forEach((contrato: ContratoTriagem) => {
              contrato.userId = user.legacyUserId[contrato.id ?? ''] ?? -1;
              contratos.push(contrato);
            });
            this.cacheContratos$.next(contratos);
            this.loadDomains();
            this.contractsAlreadyLoaded$.next(true);
          }
        },
        complete: () => {
        }
      })
  }

  setAvaliarDepois(value: TriagemDetail, userId: number, contractId: number, holdEfetuouReservaDeTriagem?: boolean) {
    const itens = this.getAvaliarDepois(userId, contractId);
    itens.push(value.id ?? -1);
    const reservadasEmTela = this.infracoesAvaliarDepois$.value;
    reservadasEmTela.addReservadasEmTela({id: `iADPs_${userId}_${contractId}`, numbers: Array.from(new Set(itens))});
    this.nextReservadasEmTela(reservadasEmTela);
    localStorage.setItem(`iADPs_${userId}_${contractId}`, JSON.stringify(itens));
    if (!holdEfetuouReservaDeTriagem)
      this.efetuouReservaDeTriagem.next(true);
  }

  getAvaliarDepois(userId: number, contractId: number): number[] {
    const itens = JSON.parse(localStorage.getItem(`iADPs_${userId}_${contractId}`) ?? '[]') as number[];
    const reservadasEmTela = this.infracoesAvaliarDepois$.value;
    reservadasEmTela.addReservadasEmTela({id: `iADPs_${userId}_${contractId}`, numbers: itens});
    this.nextReservadasEmTela(reservadasEmTela);
    return itens;
  }

  updateAvaliarDepois(reservados: number[], userId: number, contractId: number) {
    const itens = this.getAvaliarDepois(userId, contractId);
    const itensAindaExistentes = reservados.filter(i => itens.includes(i));
    const reservadasEmTela = this.infracoesAvaliarDepois$.value;
    reservadasEmTela.addReservadasEmTela({id: `iADPs_${userId}_${contractId}`, numbers: itensAindaExistentes});
    this.nextReservadasEmTela(reservadasEmTela);
    localStorage.setItem(`iADPs_${userId}_${contractId}`, JSON.stringify(itensAindaExistentes));
  }

  clearAvaliarDepois(userId: number, contractId: number) {
    const reservadasEmTela = this.infracoesAvaliarDepois$.value;
    reservadasEmTela.addReservadasEmTela({id: `iADPs_${userId}_${contractId}`, numbers: []});
    this.nextReservadasEmTela(reservadasEmTela);
    localStorage.setItem(`iADPs_${userId}_${contractId}`, JSON.stringify([]));
  }

  clearAllAvaliarDepois() {
    this.cacheContratos$.value.forEach(contrato => {
      this.clearAvaliarDepois(contrato.userId ?? -1, contrato.id ?? -1);
    });
  }

  private nextReservadasEmTela(reservadas: UniqueReservadasEmTelaSet) {
    this.infracoesAvaliarDepois$.next(new UniqueReservadasEmTelaSet());
    this.infracoesAvaliarDepois$.next(reservadas);
  }

  loadDomains() {
    this.loadTiposDeInfracao();
    this.loadMotivoDescarte();
    this.loadEquipamentos();
    this.loadClassificacaoVeiculos();
    this.loadUsuariosDoContrato();
  }

  goToProcessamentoTriagem(router: Router, routeEnum: RoutesEnum) {
    this.triagemFilterSelecionada.next(null);
    router.navigate([routeEnum]).then();
  }

  //SCREENING
  listInfractionsById(filter: TriagemFilter): Observable<TriagemDetail[]> {
    filter.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filter.contractId)}${this.urlScreening}/listInfractionsByIds`, TriagemFilter.validFilter(filter))
      // .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)))
      .pipe(
        map((value: TriagemDetail[]) => {
          return sortTriagens(value, 0, this.env.features.triagemPermiteRecortePlaca);
        }),
        map((value: TriagemDetail[]) => {
          value.forEach(v => {
            v.imageList?.forEach(i => {
              this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
              this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
            });
            v.listImage?.forEach(i => {
              this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
              this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
            });
          })
          return value;
        }),
      );
  }

  listGroupedInfractions(filter: TriagemFilter, avoidFinalize?: boolean): Observable<TriagemResponse[]> {
    filter.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filter.contractId)}${this.getUrl(filter)}/listGroupedInfractions`, TriagemFilter.validFilter(filter))
      .pipe(finalize(() => {
        if (!avoidFinalize)
          this.efetuouReservaDeTriagem.next(true)
      }));
  }

  private getUrl(filter: TriagemFilter): string {
    return `${filter.ehValidacao ? this.urlValidation : filter.ehPreTriagem ? this.urlPreInfraction : filter.ehHistorico ? this.urlHistorico : this.urlScreening}`;
  }

  listInfractionsDetails(filtro: TriagemFilter, lastId?: number): Observable<TriagemDetail[]> {
    if (filtro.ehHistorico && !filtro.ehPreTriagem)
      return this.listInfractionsDetailsHistorico(filtro, lastId);

    filtro.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filtro.contractId)}${filtro.ehValidacao ? this.urlValidation : filtro.ehPreTriagem ? this.urlPreInfraction : this.urlScreening}/listInfractionsDetails`, TriagemFilter.validFilter(filtro))
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)))
      .pipe(
        map((value: TriagemDetail[]) => {
          return sortTriagens(value, lastId, this.env.features.triagemPermiteRecortePlaca);
        }),
        map((value: TriagemDetail[]) => {
          value.forEach(v => {
            v.imageList?.forEach(i => {
              if (i.imageType === 27) {
                this.teste(i.imageUrl, (imageObjectUrl: string) => {
                  i.imageUrl = imageObjectUrl;
                  i.canLoad = true;
                });
              } else {
                this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
                this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
              }
            });
            v.listImage?.forEach(i => {
              if (i.imageType === 27) {
                this.teste(i.imageUrl, (imageObjectUrl: string) => {
                  i.imageUrl = imageObjectUrl;
                  i.canLoad = true;
                });
              } else {
                this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
                this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
              }
            });
          })
          return value;
        }),
      );
  }

  teste(url?: string, onFinalize?: (url: any) => any) {
    return this.http.get(`${environment.baseApi}${url}`, {responseType: 'blob'}).subscribe((item) => {
      const videoBlob = new Blob([item], {type: 'video/mp4'}); // Define o tipo MIME

      if (onFinalize)
        onFinalize(URL.createObjectURL(videoBlob))
    }, error => {
      console.error('Erro ao carregar a imagem:' + JSON.stringify(error));
    });
  }

  listInfractionsDetailsHistorico(filtro: TriagemFilter, lastId?: number): Observable<TriagemDetail[]> {
    filtro.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filtro.contractId)}${this.urlHistorico}/${filtro.consistent ? 'consistent' : 'inconsistent'}`, TriagemFilter.validFilter(filtro))
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)))
      .pipe(
        map((value: TriagemDetail[]) => {
          return sortTriagens(value, lastId, this.env.features.triagemPermiteRecortePlaca);
        }),
        map((value: TriagemDetail[]) => {
          value.forEach(v => {
            v.imageList?.forEach(i => {
              this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
              this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
            });
            v.listImage?.forEach(i => {
              this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
              this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
            });
          })
          return value;
        }),
      );
  }

  saveAllInfractions(filtro: TriagemFilter, descarteOrAceite?: DescarteOrAceiteType): Observable<any> {
    if (filtro.ehPreTriagem && descarteOrAceite)
      return this.preTriagemSaveAll(descarteOrAceite, filtro);

    filtro.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filtro.contractId)}/api/processing/saveAllInfration`, TriagemFilter.validFilter(filtro))
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  getStepHistory(infracaoId: number, contractId: number): Observable<TriagemStepHistory[]> {
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/step/findHistoryStep?contractId=${contractId}`, {
      violationId: infracaoId,
    })
  }

  listInfractionBatch(filtro: TriagemFilter): Observable<TriagemDetail[]> {
    filtro.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filtro.contractId)}/api/batch/listInfrationsBatch`, TriagemFilter.validFilter(filtro))
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  saveInfractionsBatch(triagemBatch: TriagemBatch): Observable<TriagemBatch> {
    triagemBatch.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(triagemBatch.contractId)}/api/batch/saveInfrationsBatch`, triagemBatch)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  releaseReservations(contractId: number, userId: number): Observable<any> {
    if (this.triagemFilterSelecionada.value?.contractId === contractId) {
      if (this.triagemFilterSelecionada.value?.violationId)
        this.triagemFilterSelecionada.value.violationId = undefined;
      if (this.triagemFilterGroupSelecionada.value?.violationId)
        this.triagemFilterGroupSelecionada.value.violationId = undefined;
    }
    return forkJoin([
      this.http.get(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/reservations/releaseReservations?userId=${userId}&contractId=${contractId}`),
      this.http.get(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/reservations/releaseReservationsPreInfractions?userId=${userId}&contractId=${contractId}`)
    ]).pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  listReservations(contractId: number, userId: number): Observable<TriagemCountReservadas[]> {
    return forkJoin([
      this.http.get(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/reservations/listReservations?userId=${userId}&contractId=${contractId}`)
        .pipe(catchError(() => of({}))),
      this.http.get(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/reservations/listReservationsPreInfractions?userId=${userId}&contractId=${contractId}`)
        .pipe(catchError(() => of({})))
    ])
  }

  reevaluateInfraction(infracao: TriagemReevaluate, contractId: number) {
    infracao.userId = this.usuario$.value;
    infracao.contractId = contractId;
    return this.putData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/${this.urlScreening}/reevaluateInfraction`, infracao)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  saveTriagem(infracao: TriagemInfracaoSave) {
    infracao.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(infracao.contrato)}/api/processing/saveInfration`, infracao)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  updateImage(imagem: UpdateImagemTriagem, contractId: number) {
    imagem.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/image/updateImage?contractId=${contractId}`, imagem)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  updateViolationProcessHistory(imagem: UpdateViolationProcess, contractId: number) {
    imagem.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/${this.urlHistorico}/updateViolationProcess?contractId=${contractId}`, imagem)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  preTriagemSaveById(descarteOrAceite: DescarteOrAceiteType, preTriagem: PreTriagem) {
    preTriagem.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(preTriagem.contractId)}${this.urlPreInfraction}/${descarteOrAceite}ById`, preTriagem)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  preTriagemCancelarDescarte(preTriagem: PreTriagem) {
    preTriagem.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(preTriagem.contractId)}${this.urlPreInfraction}/cancelaDescarte`, preTriagem)
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  preTriagemSaveAll(descarteOrAceite: DescarteOrAceiteType, filtro: TriagemFilter) {
    filtro.userId = this.usuario$.value;
    return this.postData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(filtro.contractId)}${this.urlPreInfraction}/${descarteOrAceite}All`, TriagemFilter.validFilter(filtro))
      .pipe(finalize(() => this.efetuouReservaDeTriagem.next(true)));
  }

  getDadosDuplicados(idTriagem: number, placa: string, contractId: number): Observable<DuplicidadeInfracao[]> {
    return this.getData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/checkViolation/findDuplicityByViolation?violation=${idTriagem}${placa.length > 0 ? '&plate=' + placa.toUpperCase() : ''}&contractId=${contractId}`)
      .pipe(
        map((res: DuplicidadeInfracao[]) => {
          const retorno: DuplicidadeInfracao[] = [];
          (res ?? []).forEach(v => {
            if (retorno.filter(r => r.id === v.id).length === 0)
              retorno.push(v);
          });
          return retorno;
        }),
        map((value: DuplicidadeInfracao[]) => {
          value.forEach(v => {
            v.listImage?.forEach(i => {
              this.consultaImgBucketS3(i.imageUrl, (imageObjectUrl: string) => i.imageUrl = imageObjectUrl);
              this.consultaImgBucketS3(i.imageUrlObliterada, (imageObjectUrl: string) => i.imageUrlObliterada = imageObjectUrl);
            });
          })
          return value;
        }),
      )
  }

  consultaImgBucketS3(urlImagem?: string, onLoadImagem?: (imageObjectUrl: string) => void | any) {
    if (!urlImagem)
      return;

    if (!urlImagem.includes('blob'))
      urlImagem = `${environment.baseApi}${urlImagem}`

    fetch(`${urlImagem}`, {
      headers: new Headers({
        'Authorization': this.userService.getToken() ?? '',
      })
    }).then(response => response.blob()) // Converter a resposta em Blob
      .then(blob => {
        const imageObjectUrl = URL.createObjectURL(blob);
        if (onLoadImagem)
          onLoadImagem(imageObjectUrl);
      }).catch(error => console.error('Erro ao carregar a imagem:', error));
  }

  setUserLegacyIdByFiltroContrato(filtro: TriagemFilter) {
    this.usuario$.next(this.userService.user?.legacyUserId[filtro.contractId ?? '']);
  }

  private loadMotivoDescarte(): void {
    const cacheReqs: Observable<any>[] = [];
    this.cacheContratos$.value.forEach((contrato: ContratoTriagem) => {
      cacheReqs.push(this.getData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contrato.id)}/api/reasons/listReasons?orgao=${contrato.orgao}&contractId=${contrato.id}`));
    });
    forkJoin(cacheReqs)
      .pipe(
        takeUntil(this.cleanCache$),
        map((res: any[]) => {
          res.forEach((motivoList: MotivoDescarte[]) => {
            motivoList.forEach(v => v.descricao = v.codigo + ' - ' + v.descricao);
            // @ts-ignore
            motivoList.sort((a, b) => a.codigo - b.codigo);
          })
          return res;
        })
      ).subscribe({
      next: (value: any[]) => {
        const cacheInfos: any[] = [];
        value.forEach((resp: MotivoDescarte[], index: number) => {
          cacheInfos.push({
            contractIdOrOrgao: this.cacheContratos$.value[index].orgao,
            cacheList: resp.filter(r => r.ativo)
          })
        });
        this.cacheMotivoDescarte$.next(cacheInfos);
      },
      error: () => {
        const retornos: CacheListPorContrato<any>[] = [];
        this.cacheContratos$.value.forEach(contrato => {
          retornos.push({
            contractIdOrOrgao: contrato.orgao,
            cacheList: this.createErrorItem(`Erro ao carregar Motivos`)
          });
        })
        this.cacheMotivoDescarte$.next(retornos);
      }
    });
  }

  private loadTiposDeInfracao(): void {
    const cacheReqs: Observable<any>[] = [];
    this.cacheContratos$.value.forEach((contrato: ContratoTriagem) => {
      cacheReqs.push(this.getData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contrato.id)}${this.urlScreening}/listInfractionTypes?contractId=${contrato.id}`));
    });
    forkJoin(cacheReqs)
      .pipe(takeUntil(this.cleanCache$))
      .subscribe({
        next: (value: any[]) => {
          const cacheInfos: any[] = [];
          value.forEach((resp: InfractionTypes[], index: number) => {
            cacheInfos.push({
              contractIdOrOrgao: this.cacheContratos$.value[index].id,
              cacheList: resp.filter(r => r.ativo)
            })
          });
          this.cacheTiposDeInfracao$.next(cacheInfos);
        },
        error: () => {
          const retornos: CacheListPorContrato<any>[] = [];
          this.cacheContratos$.value.forEach(contrato => {
            retornos.push({
              contractIdOrOrgao: contrato.id,
              cacheList: this.createErrorItem(`Erro ao carregar Tipos de infração`)
            });
          })
          this.cacheTiposDeInfracao$.next(retornos);
        }
      });
  }

  private loadEquipamentos(): void {
    const cacheReqs: Observable<any>[] = [];
    this.cacheContratos$.value.forEach((contrato: ContratoTriagem) => {
      cacheReqs.push(this.getData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contrato.id)}${this.urlScreening}/listEquipments?contractId=${contrato.id}`));
    });
    forkJoin(cacheReqs)
      .pipe(takeUntil(this.cleanCache$))
      .subscribe({
        next: (value: any[]) => {
          const cacheInfos: any[] = [];
          value.forEach((resp: EquipamentoTriagem[], index: number) => {
            cacheInfos.push({
              contractIdOrOrgao: this.cacheContratos$.value[index].id,
              cacheList: resp.filter(r => r.ativo)
            })
          });
          this.cacheEquipamentos$.next(cacheInfos);
        },
        error: () => {
          const retornos: CacheListPorContrato<any>[] = [];
          this.cacheContratos$.value.forEach(contrato => {
            retornos.push({
              contractIdOrOrgao: contrato.id,
              cacheList: this.createErrorItem(`Erro ao carregar lista de Equipamentos`)
            });
          })
          this.cacheEquipamentos$.next(retornos);
        }
      });
  }

  private loadClassificacaoVeiculos(): void {
    const cacheReqs: Observable<any>[] = [];
    this.cacheContratos$.value.forEach((contrato: ContratoTriagem) => {
      cacheReqs.push(this.getData(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contrato.id)}/api/vehicle/listVehicleClassification?contractId=${contrato.id}`));
    });
    forkJoin(cacheReqs)
      .pipe(takeUntil(this.cleanCache$))
      .subscribe({
        next: (value: any[]) => {
          const cacheInfos: any[] = [];
          value.forEach((resp: ClassificacaoVeiculoTriagem[], index: number) => {
            cacheInfos.push({
              contractIdOrOrgao: this.cacheContratos$.value[index].id,
              cacheList: resp.filter(r => r.ativo)
            })
          });
          this.cacheClassificacaoVeiculo$.next(cacheInfos);
        },
        error: () => {
          const retornos: CacheListPorContrato<any>[] = [];
          this.cacheContratos$.value.forEach(contrato => {
            retornos.push({
              contractIdOrOrgao: contrato.id,
              cacheList: this.createErrorItem(`Erro ao carregar Classificacao de veiculo`)
            });
          })
          this.cacheClassificacaoVeiculo$.next(retornos);
        }
      });
  }

  private createErrorItem(descricao: string): any[] {
    return [{id: -1, descricao: descricao}];
  }

  private loadUsuariosDoContrato(): void {
    const cacheReqs: Observable<any>[] = [];
    this.cacheContratos$.value.forEach((contrato: ContratoTriagem) => {
      cacheReqs.push(this.getData(`${environment.baseApi}users/findUserByContractId?contractId=${contrato.id}`));
    });
    forkJoin(cacheReqs)
      .pipe(takeUntil(this.cleanCache$))
      .subscribe({
        next: (value: any[]) => {
          const cacheInfos: any[] = [];
          value.forEach((resp: UserFilter[], index: number) => {
            cacheInfos.push({
              contractIdOrOrgao: this.cacheContratos$.value[index].id,
              cacheList: resp.map(r => {
                r.id = r.legacyUserId[this.cacheContratos$.value[index].id ?? '']
                return r;
              })
            })
          });
          this.cacheUsuarios$.next(cacheInfos);
        },
        error: () => {
          const retornos: CacheListPorContrato<any>[] = [];
          this.cacheContratos$.value.forEach(contrato => {
            retornos.push({
              contractIdOrOrgao: contrato.id,
              cacheList: this.createErrorItem(`Erro ao carregar Usuários`)
            });
          })
          this.cacheUsuarios$.next(retornos);
        }
      });
  }

  checkSpeedLimit(violationId: number, contractId: number): Observable<any> {
    return this.http.get(`${this.urlLegacyAPI}/${this.getGroupIdByContractId(contractId)}/api/checkViolation/checkSpeedAlert?violation=${violationId}&contractId=${contractId}`);
  }

  getGroupIdByContractId(contractId?: number): number {
    return this.cacheContratos$.value.find(c => c.id === contractId)?.groupId ?? -1;
  }
}
