import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _statusLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showLoader(): void {
    this._statusLoader.next(true);
  }

  hideLoader(): void {
    this._statusLoader.next(false);
  }

  get statusLoader(): boolean {
    return this._statusLoader.value;
  }

}
