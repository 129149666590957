<div class="px-6">
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-toast position="top-right" key="toastError"></p-toast>
  <div class="grid">
    <div class="col-6">
      <h5 class="w-full text-xl font-medium my-2">Nome</h5>
      <input
      placeholder="Digite o nome da rota"
      [style]="stylePattern"
      type="text"
      [(ngModel)]="newFunctionality.name"
      pInputText/>
    </div>
    <div class="col-6">
      <h5 class="w-full text-xl font-medium my-2">Rota</h5>
      <input
      placeholder="Digite a url da rota"
      [style]="stylePattern"
      type="text"
      [(ngModel)]="newFunctionality.route"
      pInputText/>
    </div>
    <div class="col-6">
      <button [disabled]="!verifyRole(newFunctionality)" (click)="saveRole(false)" pButton type="button" label="Salvar" ></button>
    </div>
  </div>
  <p-table
  [value]="functionalities"
  [tableStyle]="{'min-width': '100%'}"
  [loading]="loading"
  >
  <ng-template pTemplate="header">
      <tr [style]="{'cursor':'default'}">
        <th class="text-center">Nome</th>
        <th class="text-center">Ativo</th>
        <th class="text-center">Rota</th>
        <th class="text-center">Ação</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-route>
      <tr [style]="{'cursor':'default'}">
        <td class="text-center">{{route.name}}</td>
        <td class="text-center table-toggle">
          <p-inputSwitch (onChange)="editRoleStatus(route,$event)" [(ngModel)]="route.status"></p-inputSwitch>
        </td>
        <td class="text-center">{{route.route}}</td>
        <td class="flex justify-content-around">
          <button (click)="openDialogEdition(route)" class="p-button-warning" pButton type="button" label="Editar" ></button>
          <button (click)="deleteRole(route.id)" class="p-button-danger" pButton type="button" label="Excluir" ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-dialog
  [(visible)]="showEditDialog"
  [style]="{ width: '50%', height: '43%'}"
  >
    <h5 class="w-full text-xl font-medium my-2">Editar Rota</h5>
    <div class="grid">
      <div class="col-6">
        <h5 class="w-full text-xl font-medium my-2">Nome</h5>
        <input
        placeholder="Digite o nome da rota"
        [style]="stylePattern"
        [required]="true"
        type="text"
        [(ngModel)]="functionalityOnEdit.name"
        pInputText/>
      </div>
      <div class="col-6">
        <h5 class="w-full text-xl font-medium my-2">Rota</h5>
        <input
        placeholder="Digite a url da rota"
        [style]="stylePattern"
        [required]="true"
        type="text"
        [(ngModel)]="functionalityOnEdit.route"
        pInputText/>
      </div>
      <div class="col-6">
        <button [disabled]="!verifyRole(functionalityOnEdit)" (click)="saveRole(true)" pButton type="button" label="Salvar" ></button>
      </div>
    </div>
  </p-dialog>
</div>
