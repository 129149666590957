<div class="container-fluid">
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-toast position="top-right" key="toastError"></p-toast>

  <!-- <div class="grid">
    <div class="col-6 xl:col-3" *ngFor="let video of videos">
      <invid-camera-card [video]="video"></invid-camera-card>
    </div>
  </div> -->

  <div class="grid">
    <div class="col-6 xl:col-3" *ngFor="let device of devices">
      <status-camera-card
        [name]="device.DeviceName"
        [url]="device.url"
        [port]="device.port"
      ></status-camera-card>
    </div>
  </div>
</div>

<div class="loading">
  <p-progressSpinner *ngIf="showLoading"></p-progressSpinner>
</div>

<app-violation-dialog
  [showViolations]="showViolations"
  [(showViolations)]="showViolations"
  [violation]="violation"
  (clearViolationsStackEvent)="clearViolations()"
  (showViolationsChange)="showViolations = $event"
  (violationAction)="execAction($event)"
></app-violation-dialog>
