<div class="slider" *ngIf="images.length > 0">
  <p-button
    icon="pi pi-angle-left"
    (click)="previousImage()"
    [style.font-size]="'2rem;'"
    id="previousPage"
    class="previous"
  ></p-button>
  <div class="slide" id="slide">
    <div *ngFor="let img of images; let i = index">
      <img *ngIf="images !== undefined" src="{{ img.url }}" />
      <img
        *ngIf="images === undefined"
        src="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png"
      />
    </div>
  </div>
  <p-button
    icon="pi pi-angle-right"
    (click)="nextImage()"
    [style.font-size]="'2rem;'"
    id="nextPage"
    class="next"
  ></p-button>
</div>
