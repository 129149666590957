import { DeviceEventType } from "./deviceEventType";
import { Grupo } from "./grupo.model";

export class Filters {
	startDate!: Date;
	endDate!: Date;
	startHour!: string | Date;
	endHour!: string | Date;
	plate?: string;
	device?: any;
	type?: Array<DeviceEventType>;
	reportRef?: number;
	groupRunway?: boolean;
  contract?: number;
  qfvClass?: string | string[];
  grupos?: Grupo;
  user?: number;
  step?: number;
  lane?: number;
  images?: boolean;
  track?: number;
  filterDate?: number;
  cleanFile?: boolean;
  vehicleSize?: number;
  displaysOccurrence?: boolean;
  route?: number;
  fullShow?: boolean;
  groupType?: boolean;
  groupOperation?: boolean;
  groupStepDay?: boolean;
  groupViolationType?: boolean;

	constructor(obj: any = { }) {
		Object.assign(this, obj);
		if (!this.startDate) {
			this.startDate = new Date();
		}
		if (!this.endDate) {
      this.endDate = new Date();
		}

    if (!this.startHour) {
      this.startHour = new Date();
      this.startHour.setHours(0, 0, 0, 0);
		}
    if (!this.endHour) {
      this.endHour = new Date();
      this.endHour.setHours(23, 59, 59, 0);
		}
	}
}
