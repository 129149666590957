import { Component, Input } from '@angular/core';

@Component({
  selector: 'real-time-info-window-text',
  templateUrl: './info-window-text.component.html',
  styleUrls: ['./info-window-text.component.scss']
})
export class InfoWindowTextComponent {
  @Input() data: any[] = [];
  taxa = '';
  constructor() { }

  getTextColor(min: any, med: any, alt: any, max: any, value: any) {
    if (value <= min) {
      this.taxa = 'Baixo';
      return 'color: #54a005';
    } else if (value <= med && value > min) {
      this.taxa = 'Médio';
      return 'color: #aacc00';
    }else if(value <= alt && value > med){
      this.taxa = 'Alto';
      return 'color: #d3c203';
    }else{
      this.taxa = 'Congestionado';
      return 'color: #54a005';
    }
  }
}
