<div class="container">
  <p-toast position="top-right" key="toastError"></p-toast>
  <div class="search mb-5">
    <div class="filter">
      <div>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input class="dropdown" type="text" pInputText placeholder="Pesquisar" (input)="search($event)"/>
        </span>
      </div>
      <div class="group">
        <span class="p-float-label">
          <p-dropdown
            [class]="!groupselected ? 'ng-invalid ng-dirty' : ''"
            [style]="stylePattern"
            [scrollHeight]="'30rem'"
            optionLabel="description"
            optionValue="id"
            [filter]="true"
            [options]="groups"
            [showClear]="true"
            (onChange)="onChangeGroup($event)"
            placeholder="Selecionar"
            inputId="filterGroup"
            [(ngModel)]="groupselected"
            [required]="true"
          >
          <ng-template let-group pTemplate="item">
            <div class="font-semibold dropdown-options-limits text-center">
              {{ group.description }}
            </div>
            </ng-template>
          </p-dropdown>
          <label htmlFor="filterEquipamento1">Grupo</label>
        </span>
      </div>
      <!-- A TELA DOS CARDS EM STATUS EQUIPAMENTO ESTÁ DESATIVADA -->
      <!-- <button pButton type="button" class="p-button-outlined" (click)="back()" icon="pi pi-copy" label="Ver Cards" ></button> -->
    </div>
  </div>

  <p-table
    [lazy]="true"
    (onLazyLoad)="loadDevicesLazy($event)"
    [value]="devicesFiltered"
    [paginator]="true"
    dataKey="deviceId"
    [rows]="10"
    [totalRecords]="totalRecords"
    [(first)]="first"
    [rowsPerPageOptions]="[10, 25, 50, 100]"
    [tableStyle]="{ 'min-width': '50rem' }"
    currentPageReportTemplate="{first} - {last} de {totalRecords}"
    [showCurrentPageReport]="true"
    (onPage)="onPageChange($event)"
    [loading]="loading"
    >
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 5rem"></th>
            <th>Equipamento</th>
            <th>Endereço</th>
            <th>infrações</th>
            <th>Passagens</th>
            <th>Pesagens</th>
            <th>Câmera</th>
            <th>Temperatura</th>
            <th>Status</th>
            <th>Ações</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-device let-expanded="expanded">
      <tr [ngClass]="{'green-row': device.online, 'red-row': !device.online, 'expanded' : expanded}">
        <td>
          <div [pRowToggler]="device">
            <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </div>
        </td>
        <td>
          <i class="fas fa-video mr-2" [style.color]="device.online ? '#498204' : ''"></i>
          {{ device.deviceName }}
        </td>
        <td>{{ device.address }}</td>
        <td>{{ device.infractions }}</td>
        <td>{{ device.flowings }}</td>
        <td>{{ device.weighings  }}</td>
        <td>{{ device.captureUse + "%" }}</td>
        <td>{{ device.trackTemperature == 0 ? '-' : device.trackTemperature + " ºC" }}</td>
        <td>
          <ng-container *ngIf="device.online; else offlineTag">
              <p-tag styleClass="mr-2" icon="fas fa-check-circle" severity="success" value="Online"></p-tag>
          </ng-container>
          <ng-template #offlineTag>
              <p-tag icon="fas fa-times-circle" severity="danger" value="Offline"></p-tag>
          </ng-template>
        </td>
        <td>
          <div class="actions">
            <div>
              <button (click)="userFavorites.includes(device.deviceId) ? removeFavoriteDevice(device.deviceId) : addFavoriteDevice(device.deviceId)" pButton icon="pi pi-star-fill"
              [class]="userFavorites.includes(device.deviceId) ? 'p-button p-1 p-button-warning' : 'p-button p-1'"></button>
            </div>
            <button
              pButton icon="pi pi-camera" class="p-button p-1"
              (click)="openVideo($event, device)"></button>
            <button
              pButton icon="pi pi-link" class="p-button p-1"
              (click)="openLink($event, device.urlAdminAccess,device.urlAccessPort)"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" style="width: 100%;" let-device>
      <tr class="expanded-row">
        <td colspan="12">
          <row-expansion [device]="device"></row-expansion>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<shared-video-dialog
  *ngIf="dataDevice"
  [(videoDisplay)]="showDeviceDialog"
  [port]="dataDevice.urlAccessPort"
  [urlCam]="dataDevice.urlCamAccess"
></shared-video-dialog>
