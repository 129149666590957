import {Pipe, PipeTransform} from "@angular/core";
import {TriagemService} from "../../../service/triagem.service";

@Pipe({
  name: 'totalReservadas',
  standalone: true
})
export class TotalReservadasPipe implements PipeTransform {

  transform(triagemService: TriagemService): number {

    return triagemService.infracoesAvaliarDepois$.value
      .getReservadasEmTela()
      .map((reservadasEmTela) => reservadasEmTela.numbers.length)
      .reduce((acc, value) => acc + value, 0);
  }

}
