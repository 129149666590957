<p-dialog
  [(visible)]="showDetails"
  (onHide)="cleanDialog()"
  [modal]="true"
  [closeOnEscape]="false"
  [style]="isWheighing(detailsData.type) ? { width: '70%', height: '90%' } : { width: '50%', height: '50%' }"
  *ngIf="detailsData">
  <div class="full">
    <p-galleria
      *ngIf="loaded"
      [value]="detailsData.documents || []"
      [attr.id]="detailsData.id"
      thumbnailsPosition="bottom"
      [showIndicators]="false"
      [showThumbnails]="!!detailsData.documents && detailsData.documents.length > 1"
      [(activeIndex)]="selectedIndex"
      #carousel
      [circular]="false"
    >
      <ng-template pTemplate="item" let-item>
        <div>
          <div class="border-1 surface-border border-round m-2 text-center py-3 px-3">
            <img pTooltip="Clique na imagem para ver em tela cheia" class="w-5 cursor-pointer" (click)="fullscrennImage(item.img, selectedIndex)" [src]="item.img" alt="image"/>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
          <img [src]="item.img" width="80" height="60"
              tooltipPosition="left"
              alt="thumbnail"/>
        </div>
      </ng-template>
    </p-galleria>
    <div class="grid">
      <div class="col-4">
        <p class="font-semibold">Dispositivo</p>
        <label>{{ detailsData.device.name }}</label>
      </div>
      <div class="col-8 pl-7">
        <p class="font-semibold">Local</p>
        <label>{{ detailsData.device.addresses[0].streetName }}, {{ detailsData.device.addresses[0].city }} -
          {{ detailsData.device.addresses[0].state }}</label>
      </div>
    </div>
    <div class="grid mt-5">
      <div class="col-4">
        <p class="font-semibold">Data e Hora</p>
        <label>{{ detailsData.date | date : "dd/MM/yyyy, HH:mm:ss" }}</label>
      </div>
      <div class="col-4 pl-7">
        <p class="font-semibold">Direção</p>
        <label>{{ detailsData.device.addresses[0].flowDirection || "-" }}</label>
      </div>
      <div class="col-4 pl-5">
        <p class="font-semibold">Pista</p>
        <label>{{ detailsData.device.addresses[0].laneIdentification || "-" }}</label>
      </div>
      <div class="col-4">
        <p class="font-semibold">Velocidade</p>
        <label *ngIf="detailsData?.speed">{{ detailsData.speed ? (detailsData.speed + ' km/h') : "-" }}</label>
      </div>
      <div class="col-4 pl-7">
        <p class="font-semibold">Placa</p>
        <label>{{ detailsData.plate || "-" }}</label>
      </div>
      <div class="col-4 pl-5">
        <p class="font-semibold">Comprimento</p>
        <label>{{ detailsData.vehicle_lenght ? (detailsData.vehicle_lenght + ' cm') : "-" }}</label>
      </div>
      <div class="col-4">
        <p class="font-semibold">Distância veículo anterior</p>
        <label>
          {{detailsData.distance_from_previous_vehicle || "-"}}
        </label>
      </div>
      <!-- <div *ngIf="detailsData?.type == 'WEIGHING'"> -->
      <div class="col-4 pl-7" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">PBT</p>
        <label>{{ !!detailsData.pbt ? detailsData.pbt+' kg' : "-" }}</label>
      </div>
      <div class="col-4 pl-5" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">Limite PBT da Classificação</p>
        <label>{{ !!detailsData.classifications_pbt_limit ? detailsData.classifications_pbt_limit +' kg' : "-" }}</label>
      </div>
      <div class="col-4" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">Classificação do Veículo</p>
        <label>{{ detailsData.vehicle_classification || "-" }}</label>
      </div>
      <div class="col-4 pl-7" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">Classificação da Pesagem</p>
        <label>{{ detailsData.wheighing_classification || "-" }}</label>
      </div>
      <div class="col-4 pl-5" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">Peso de cada eixo</p>
        <label>{{detailsData.axleJson ? (detailsData.axleJson.length > 0 ? getAxlesPbt(detailsData.axleJson) : "-") : "-" }}</label>
      </div>
      <div class="col-4" *ngIf="isWheighing(detailsData.type)">
        <p class="font-semibold">Excesso PBT</p>
        <label>{{ !!detailsData.pbt_excess ? detailsData.pbt_excess > 0 ? detailsData.pbt_excess+' kg' : "0 kg" : '-' }}</label>
      </div>
    </div>
  </div>
</p-dialog>

<p-galleria
  [value]="detailsData.documents || []"
  [(visible)]="fullscreenImg"
  [(activeIndex)]="selectedIndex"
  [responsiveOptions]="galeriaService.responsiveOptions"
  [containerStyle]="galeriaService.containerStyle"
  [circular]="true"
  [fullScreen]="true"
  [showItemNavigators]="true"
  [showThumbnails]="false"
>
  <ng-template pTemplate="item" let-item>
    <img [src]="item.img" class="w-full" alt="image"/>
  </ng-template>
</p-galleria>
