import {Injectable} from '@angular/core';
import {AverageFlow} from '../models/averageflow.model';
import {ReportType} from "../models/report-type.model";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AvaregeFlowService extends BaseService {

  reportTypes: Array<ReportType> = [];

  constructor(public http: HttpClient) {
    super(http);
  }

  getAverageFlow(deviceId: any): Observable<Array<AverageFlow>> {
    let url: string = this.buildUrlQueryParams(
      {deviceId,},
      this.getEndpoint('AVERAGE_FLOW')
    );

    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            this.reportTypes = response;
            return response;
          }
          return []
        })
      );
  }
}
