import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusMonitoringRoutingModule } from './status-monitoring-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { DialogModule } from 'primeng/dialog';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { CheckboxModule } from 'primeng/checkbox';
import { ImageModule } from 'primeng/image';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MonitoringAccessComponent } from './monitoring-access/monitoring-access.component';
import { CardDeviceComponent } from './monitoring-access/components/card-device/card-device.component';
import { DeviceInfoComponent } from './monitoring-access/components/device-info/device-info.component';
import { CardDeviceStatusComponent } from './monitoring-access/components/device-info/components/card-device-status/card-device-status.component';
import { DeviceGraphComponent } from './monitoring-access/components/device-info/components/device-graph/device-graph.component';
import { CameraCardComponent } from './invid/camera-card/camera-card.component';
import { CamerasScreenComponent } from './invid/cameras-screen/cameras-screen.component';
import { ToastModule } from 'primeng/toast';
import { ViolationDialogComponent } from './invid/violation-dialog/violation-dialog.component';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from 'src/app/shared/shared.module';
import { MonitoringListComponent } from './list/list.component';
import { RowExpansionComponent } from './list/row-expansion/row-expansion.component';
import { TagModule } from 'primeng/tag';


@NgModule({
  declarations: [
    MonitoringAccessComponent,
    CardDeviceComponent,
    DeviceInfoComponent,
    CardDeviceStatusComponent,
    DeviceGraphComponent,
    CameraCardComponent,
    CamerasScreenComponent,
    ViolationDialogComponent,
    MonitoringListComponent,
    RowExpansionComponent
  ],
  imports: [
    CommonModule,
    StatusMonitoringRoutingModule,

    HttpClientModule,
    FormsModule,
    SharedModule,

    DialogModule,
    ChartModule,
    CardModule,
    BadgeModule,
    CheckboxModule,
    ImageModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DropdownModule,
    InputMaskModule,
    InputTextModule,
    ButtonModule,
    ProgressBarModule,
    InfiniteScrollModule,
    ToastModule,
    GalleriaModule,
    ProgressSpinnerModule,
    TagModule
  ]
})
export class StatusMonitoringModule { }
