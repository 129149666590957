export class TriagemCountReservadas {
  totalReserved?: number;
  reservedIds?: number[];

  public static merge(reservadas: TriagemCountReservadas[]): TriagemCountReservadas {
    const totalReserved = reservadas.reduce((acc: number, curr) => (acc ?? 0) + (curr.totalReserved ?? 0), 0);
    const reservedIds = reservadas.reduce((acc: number[], curr) => (acc ?? []).concat(curr?.reservedIds ?? []), []);

    return {
      totalReserved,
      reservedIds,
    };

  }
}
