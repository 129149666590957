import {Component, OnDestroy, OnInit } from '@angular/core';
import {ViolationService} from '../../../../service/violation.service';
import {DeviceService} from "../../../../service/devices.service";
import {Subject, catchError, takeUntil, tap, throwError} from "rxjs";
import { MessageService } from 'primeng/api';
import { VIOLATION_ACTION } from 'src/app/shared/enums/violation.enum';

@Component({
  selector: 'app-cameras-screen',
  templateUrl: './cameras-screen.component.html',
  styleUrls: ['./cameras-screen.component.scss']
})
export class CamerasScreenComponent implements OnInit, OnDestroy {


  private _destroy$: Subject<void> = new Subject<void>();

  showLoading = false;

  devices: any[] = [];
  violations: any[] = [];

  showViolations: boolean = false;
  showCards: boolean = false;

  violationIndex: any = -1;
  violationOldTotal: any = undefined;

  interval: any;

  firstLoad: boolean = true;
  awaitingResponse: boolean = false;


  constructor(private device: DeviceService,
              private violationService: ViolationService,
              private messageService: MessageService,
              ) {

  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
    this.getDevices();
    this.getViolations();
  }

  nextVioletion() {
    this.showViolations = false;
    this.violationIndex++;

    clearInterval(this.interval);
    if((this.violations.length) < (this.violationIndex + 1) && !this.firstLoad) {
      this.clearViolations();
      this.firstLoad = true;
      this.messageService.add({
        key: 'toastError',
        severity: 'warn',
        summary: 'Aviso',
        detail: 'recarregando as violações.',
      });
      this.showLoading = true;
      this.getViolations()
      return;
    }

    if(this.violations.length == 0) {
      this.clearViolations();
      this.showLoading = true;
      setTimeout(() => {
        this.getViolations()
      }, 10 * 1000)
    }
    this.firstLoad = false;
    this.showLoading = false;
    this.execInterval();
    setTimeout(() => {
      this.showViolations = true;
    }, 100);
  }

  execAction(violation: VIOLATION_ACTION) {
    if(violation == VIOLATION_ACTION.IGNORE) {
      setTimeout(() => this.nextVioletion(), 5 * 1000)
    } else if(violation == VIOLATION_ACTION.RESET) {
      clearInterval(this.interval);
      this.clearViolations();
      setTimeout(() => this.nextVioletion(), 5 * 1000)
    } else {
      this.changeViolationStatus(violation)
    }
  }

  execInterval(time: number = (10 * 1000) + 100) {
    this.interval = setInterval(() => {
      this.getViolations();
    }, time);
  }

  getDevices() {
    this.device.getDeviceCam(0)
      .pipe(
        catchError((err) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao carregar os dispositivos',
          });
          return throwError(err);
        }),
        tap((devices) => {
          this.devices = [];
          this.devices.push(devices[0]);
          this.showCards = true;
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();

  }

  clearViolations(){
    this.showViolations = false;
    this.violations = [];
  }

  changeViolationStatus(event: VIOLATION_ACTION){
    clearInterval(this.interval);
    this.showViolations = false;

    const violationType = event == VIOLATION_ACTION.VALID ? 'VIOLATION_ACCEPTED': 'VIOLATION_REPROVED';
    const v = this.violations[this.violationIndex].hashcode

    this.violationService.setViolation(v, violationType)
    .pipe(
      catchError((err) => {
        this.messageService.add({
          key: 'toastError',
          severity: 'error',
          summary: 'Error',
          detail: 'Erro ao tentar atualizar infração',
        });
        this.nextVioletion();
        return throwError(err);
      }),
      tap((r) => {
        this.messageService.add({
          key: 'toastSuccess',
          severity: 'success',
          detail: r.message,
        });
        this.nextVioletion();
      }),
      takeUntil(this._destroy$),
    ).subscribe()
  }

  getViolations() {
    this.showViolations = false;
    this.showLoading = true;

    if(this.violations.length == 0 || this.violations == null) {
      if(this.awaitingResponse) {
        return;
      }
      this.awaitingResponse = true
      this.violationService.getViolation(10)
      .pipe(
        catchError((e) => {
          this.showLoading = false;
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao carregar as violações',
          });
          this.violationIndex = -1;
          this.violations = [];
          this.awaitingResponse = false;
          this.nextVioletion();
          return throwError(e);
        }),
        tap((violations) => {
          this.violationIndex = -1;
          this.violations = [];
          this.awaitingResponse = false;
          if (violations.length) {
            this.violations = violations;
            this.nextVioletion();
            return;
          }
          this.nextVioletion();
          this.showLoading = true;
        }),
        takeUntil(this._destroy$)
      ).subscribe();
      return;
    } else {
      this.nextVioletion()
      return;
    }
  }

  public get violation(){
    if(this.violations.length > 0) {
      const model = this.violations[this.violationIndex].violationModel
      return model ? model : null
    }
    return null;
  }

}
