<div class="layout-breadcrumb"
     *ngIf="items.length > 1;">
  <div class="breadcrumb">
    <div class="route-bar-breadcrumb">
      <ng-template ngFor let-item let-last="last" [ngForOf]="items">
        <li (click)="breadcrumbService.clickEvent(item)">
          <i [class]="item.icon" *ngIf="item.icon" style="margin-right: 0.3rem;"></i>
          <a class="a-breadcrumb"
             (click)="onRouterClick(item)"
             *ngIf="item.routerLink">
            {{ item.label }}
          </a>
          <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
        </li>
        <li *ngIf="!last">
          <i class="pi pi-angle-right"></i>
        </li>
      </ng-template>
    </div>
  </div>
</div>
