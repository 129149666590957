import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from "../../shared/shared.module";
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule} from 'primeng/image';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { EventsRoutingModule } from './events-routing.module';
import { EventsComponent } from './events.component';
import { EventsDetailsWindowComponent } from './components/events-details-window/events-details-window.component';
import { ViolationEventsDetailsWindowComponent } from './components/violation-events-details-window/violation-events-details-window.component';
import {CardModule} from "primeng/card";
import {FieldsetModule} from "primeng/fieldset";
import {DividerModule} from "primeng/divider";
import {TooltipModule} from "primeng/tooltip";
import { ThemeModule } from 'src/app/theme/theme.module';

@NgModule({
  declarations: [
    EventsComponent,
    EventsDetailsWindowComponent,
    ViolationEventsDetailsWindowComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    TableModule,
    DropdownModule,
    DialogModule,
    GalleriaModule,
    ImageModule,
    ToastModule,
    DividerModule,
    ThemeModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgOptimizedImage,

    EventsRoutingModule,
    CardModule,
    FieldsetModule,
    TooltipModule
  ],
  providers: [MessageService]
})
export class EventsModule { }
