import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {UserService} from '../../service/user.service';
import {finalize, forkJoin, interval, map, Subject, takeUntil, tap} from "rxjs";
import {StorageKeys} from "../../shared/enums/storage-keys.enum";
import {RoutesEnum} from "../../shared/enums/routes.enum";
import {Role} from "../../shared/enums/role.enum";
import {TriagemService} from "../../service/triagem.service";
import {LoaderService} from "../../service/loader.service";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {TriagemCountReservadas} from "../../models/triagem-count-reservadas.model";
import {HeaderService} from "../../service/header.service";
import {OverlayPanel} from "primeng/overlaypanel";
import {ContratoTriagem} from "../../models/contrato-triagem.model";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('opMenu') overlayPanel!: OverlayPanel;
  private _destroy$: Subject<void> = new Subject<void>();
  notixAppId = '100595b66642e265aba1a22a1520933';
  routerEnum = RoutesEnum;
  showNotixPixel = true;
  visibleSidebar = false;
  role = Role;
  userName: any = ''
  menuItensSideMenu: MenuItem[] = [];
  version: string = environment.version;
  showReservadasIcon: boolean = true;
  email: string = '';
  showMenu: boolean = true;

  userMenuActions: MenuItem[] = [
    {label: 'Alterar senha', icon: 'pi pi-key', routerLink: 'change-password', command: () => this.overlayPanel.hide()},
    {
      label: 'Sobre',
      icon: 'pi pi-fw pi-info-circle',
      routerLink: '/pages/home',
      command: () => this.overlayPanel.hide()
    },
    {label: 'Sair', icon: 'pi pi-fw pi-sign-out', command: () => this.logout()},
  ];
  iconMenuActions: MenuItem[] = [];
  menuOptions: MenuItem[] = [];
  possueTriagemReservada: boolean = false;

  constructor(public userService: UserService,
              public loaderService: LoaderService,
              public messageService: MessageService,
              public route: Router,
              public confirmationService: ConfirmationService,
              public headerService: HeaderService,
              public triagemService: TriagemService) {
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit() {
    this.setUsuario();
    this.setEmail();
    this.buildModelMenu();
    this.triagemService.contractsAlreadyLoaded$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (loaded: boolean) => {
          if (loaded) {
            this.generateNotificationMenu();
          }
        }
      })
    this.subscribeReservaDeTriagem();
  }

  subscribeReservaDeTriagem() {
    this.triagemService.efetuouReservaDeTriagem
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.listAllReservations();
        }
      });
  }

  setUsuario() {
    this.userName = this.userService.user.userName;
  }

  setEmail() {
    this.email = localStorage.getItem(StorageKeys.USER_EMAIL) ?? '';
    if (this.email.length > 0) {
      this.showNotixPixel = true;
    }
  }

  closeSideMenu() {
    this.menuItensSideMenu.forEach(menu => {
      if (menu.items)
        menu.expanded = false;
    });
    this.visibleSidebar = false;
    this.showMenu = false;
    setTimeout(() => this.showMenu = true, 10);
  }

  callCommand(menuItem: MenuItem) {
    if (menuItem.command)
      menuItem.command({item: menuItem})
  }

  buildModelMenu() {

    this.menuItensSideMenu = [
      {
        label: `<img width="50%" src="/assets/images/logo.png" alt="Logo da empresa"/>`,
        command: () => this.closeSideMenu(),
        routerLink: [RoutesEnum.REAL_TIME],
        styleClass: 'mt-3',
        escape: false,
      },
      {
        label: 'Dashboard',
        icon: 'pi pi-chart-bar',
        routerLink: [RoutesEnum.DASHBOARD],
        visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.DASHBOARD]),
        command: () => this.closeSideMenu(),
      },
      {
        label: 'Monitoramento',
        icon: 'pi pi-fw pi-desktop',
        expanded: false,
        visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.TEMPO_REAL, Role.EVENTOS, Role.STATUS_EQUIPAMENTOS, Role.INVID, Role.RELATORIOS, Role.PBT_CONFIG_ALERT_REPORT]),
        items: [
          {
            label: 'Tempo real',
            icon: 'pi pi-fw pi-clock',
            routerLink: [RoutesEnum.REAL_TIME],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.TEMPO_REAL]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Eventos',
            icon: 'pi pi-fw pi-car',
            routerLink: [RoutesEnum.EVENTOS],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.EVENTOS]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Status Equipamento',
            icon: 'pi pi-fw pi-exclamation-circle',
            routerLink: [RoutesEnum.STATUS_EQUIPAMENTOS_LIST],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.STATUS_EQUIPAMENTOS]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'INVID',
            icon: 'pi pi-fw pi-camera',
            routerLink: [RoutesEnum.INVID],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.INVID]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Relatórios',
            icon: 'pi pi-fw pi-file',
            routerLink: [RoutesEnum.RELATORIOS],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.RELATORIOS]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Relatório de Alertas',
            icon: 'pi pi-book',
            routerLink: [RoutesEnum.PBT_CONFIG_ALERT_REPORT],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PBT_CONFIG_ALERT_REPORT]),
            command: () => this.closeSideMenu(),
          },
        ],
      },
      {
        label: 'Configurações',
        icon: 'pi pi-sliders-v',
        expanded: false,
        visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PBT_CONFIG, Role.PBT_CONFIG_ALERT_CONFIG, Role.PBT_CONFIG_ALERT_REPORT]),
        items: [
          {
            label: 'Configurações PBT',
            icon: 'pi pi-sliders-v',
            routerLink: [RoutesEnum.PBT_CONFIG],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PBT_CONFIG]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Configurações de Alerta',
            icon: 'pi pi-bell',
            routerLink: [RoutesEnum.PBT_CONFIG_ALERT_CONFIG],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PBT_CONFIG_ALERT_CONFIG]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Motivo Ocorrência',
            icon: 'pi pi-arrow-circle-right',
            routerLink: [RoutesEnum.MONITORING_MOTIVE_OCCURRENCE],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.OCORRENCIA]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Ocorrência',
            icon: 'pi pi-history',
            routerLink: [RoutesEnum.MONITORING_OCCURRENCE],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.OCORRENCIA]),
            command: () => this.closeSideMenu(),
          },
        ]
      },
      {
        label: 'Administrador',
        icon: 'pi pi-users',
        expanded: false,
        visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
        items: [
          {
            label: 'Usuários',
            icon: 'pi pi-user',
            routerLink: [RoutesEnum.USER_AUTH_USERS],
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Novo Usuário',
            icon: 'pi pi-user-plus',
            routerLink: [RoutesEnum.USER_AUTH_EDIT_USERS],
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Funcionalidades',
            icon: 'pi pi-clone',
            routerLink: [RoutesEnum.USER_AUTH_FUNCTIONALITIES],
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Cadastrar Contrato',
            icon: 'pi pi-id-card',
            routerLink: [RoutesEnum.USER_AUTH_REGISTER_CONTRACT],
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Cadastrar Grupo',
            icon: 'pi pi-th-large',
            routerLink: [RoutesEnum.USER_AUTH_REGISTER_GROUP],
            command: () => this.closeSideMenu(),
          },
        ]
      },
      {
        label: 'Processamento',
        icon: 'pi pi-database',
        expanded: false,
        visible: this.userService.hasRoles([
          Role.ADMIN_FULL,
          Role.GESTOR,
          Role.PROCESSAMENTO_PRE_TRIAGEM,
          Role.PROCESSAMENTO_TRIAGEM,
          Role.PROCESSAMENTO_TRIAGEM_SEGUNDA,
          Role.PROCESSAMENTO_TRIAGEM_VALIDACAO,
          Role.PROCESSAMENTO_TRIAGEM_HISTORICO,
          Role.PROCESSAMENTO_TRIAGEM_HISTORICO,
          Role.PROCESSAMENTO_TRIAGEM_BATCH
        ]),
        items: [
          {
            label: 'Pré Triagem',
            icon: 'pi pi-filter',
            routerLink: [RoutesEnum.PROCESSAMENTO_PRE_TRIAGEM],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_PRE_TRIAGEM]),
            command: () => {
              this.closeSideMenu();
              this.loaderService.showLoader();
              setTimeout(() => this.loaderService.hideLoader(), 300);
            },
          },
          {
            label: 'Triagem',
            icon: 'pi pi-filter',
            routerLink: [RoutesEnum.PROCESSAMENTO_PRIMEIRA_TRIAGEM],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM_PRIMEIRA, Role.PROCESSAMENTO_TRIAGEM_BATCH]),
            command: () => {
              this.closeSideMenu();
              this.loaderService.showLoader();
              setTimeout(() => this.loaderService.hideLoader(), 300);
            },
          },
          {
            label: 'Segunda Triagem',
            icon: 'pi pi-filter',
            routerLink: [RoutesEnum.PROCESSAMENTO_SEGUNDA_TRIAGEM],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM_SEGUNDA]),
            command: () => {
              this.closeSideMenu();
              this.loaderService.showLoader();
              setTimeout(() => this.loaderService.hideLoader(), 300);
            },
          },
          {
            label: 'Validação',
            icon: 'pi pi-filter',
            routerLink: [RoutesEnum.PROCESSAMENTO_VALIDACAO_TRIAGEM],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM_VALIDACAO]),
            command: () => {
              this.closeSideMenu();
              this.loaderService.showLoader();
              setTimeout(() => this.loaderService.hideLoader(), 300);
            },
          },
          {
            label: 'Histórico',
            icon: 'pi pi-filter',
            routerLink: [RoutesEnum.PROCESSAMENTO_HISTORICO_TRIAGEM],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.PROCESSAMENTO_TRIAGEM_HISTORICO]),
            command: () => {
              this.closeSideMenu();
              this.loaderService.showLoader();
              setTimeout(() => this.loaderService.hideLoader(), 300);
            },
          },
        ]
      },
      {
        label: 'Exportação',
        icon: 'pi pi-file-export',
        expanded: false,
        visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.EXPORTACAO]),
        items: [
          {
            label: 'Exportação AIT',
            icon: 'pi pi-file',
            routerLink: [RoutesEnum.EXPORTACAO_LISTAGEM_AIT],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.EXPORTACAO]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Lote exportado AIT',
            icon: 'pi pi-file',
            routerLink: [RoutesEnum.EXPORTACAO_LOTE_EXPORTADO_AIT],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR, Role.EXPORTACAO]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Exportação Placas',
            icon: 'pi pi-file',
            routerLink: [RoutesEnum.EXPORTACAO_LISTAGEM_PLACAS, Role.EXPORTACAO],
            visible: false,
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Lote exportado Placas',
            icon: 'pi pi-file',
            routerLink: [RoutesEnum.EXPORTACAO_LOTE_EXPORTADO_PLACAS, Role.EXPORTACAO],
            visible: false,
            command: () => this.closeSideMenu(),
          },
        ]
      },
      {
        label: 'Operacional',
        icon: 'fa-solid fa-gears',
        expanded: false,
        visible: environment.features.device_config && this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
        items: [
          {
            label: 'Configuração de Equipamento',
            icon: 'pi pi-video',
            routerLink: [RoutesEnum.CONFIGURACAO_EQUIPAMENTO_CONSULTAR],
            visible: environment.features.device_config && this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Local de Operação',
            icon: 'fa-solid fa-road',
            routerLink: [RoutesEnum.CONFIGURACAO_GERAL_LOCAL_OPERACAO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Operação',
            icon: 'fas fa-road-bridge',
            routerLink: [RoutesEnum.OPERACAO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Classificação de Veículo',
            icon: 'fas fa-car-side',
            routerLink: [RoutesEnum.CONFIGURACAO_GERAL_CLASSIFICACAO_VEICULO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Perfil de Funcionamento',
            icon: 'fas fa-calendar-day',
            routerLink: [RoutesEnum.PERFIL_OPERACAO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Tipo de Infração',
            icon: 'fas fa-car-on',
            routerLink: [RoutesEnum.CONFIGURACAO_GERAL_TIPO_INFRACAO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
          {
            label: 'Tipo Periférico',
            icon: 'fas fa-tachograph-digital',
            routerLink: [RoutesEnum.CONFIGURACAO_GERAL_TIPO_PERIFERICO],
            visible: this.userService.hasRoles([Role.ADMIN_FULL, Role.GESTOR]),
            command: () => this.closeSideMenu(),
          },
        ]
      },
    ];
  }

  logout() {
    this.overlayPanel.hide();
    this.closeSideMenu();
    this.userService.efetuarLogout();
  }

  confirmarLiberarReservadas(contrato: ContratoTriagem) {
    this.confirmationService.confirm({
      message: `Deseja realmente liberar todas as infrações reservadas do contrato de ${contrato.descricao}?`,
      header: 'Confirmar', key: 'headerMenuConfirmDialog',
      rejectLabel: 'Cancelar', acceptLabel: 'Confirmar',
      rejectButtonStyleClass: 'p-button-outlined mr-3',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.liberarReservadas(contrato);
        this.overlayPanel.hide();
      }
    });
  }

  liberarReservadas(contrato: ContratoTriagem) {
    this.loaderService.showLoader();
    this.triagemService.releaseReservations(
      contrato.id ?? -1,
      contrato.userId ?? -1
    ).pipe(takeUntil(this._destroy$))
      .pipe(finalize(() => this.loaderService.hideLoader()))
      .subscribe({
        next: () => {
          this.showReservadasIcon = false;
          this.triagemService.clearAvaliarDepois(contrato.userId ?? -1, contrato.id ?? -1);
          this.messageService.add({
            key: 'toastMenuHeader',
            severity: 'success',
            detail: 'Reservas liberadas com sucesso',
          });
          setTimeout(() => this.showReservadasIcon = true, 2);
          this.trataRotaTriagem(contrato);
        },
        error: () => {
          this.messageService.add({
            key: 'toastMenuHeader',
            severity: 'error',
            detail: 'Ocorreu um erro na liberação das infrações reservas',
          })
        },
      })
  }

  trataRotaTriagem(contrato?: ContratoTriagem) {
    if (contrato && this.triagemService.triagemFilterSelecionada.value?.userId !== contrato.userId)
      return;

    const estaEfetuandoPreTriagem = [
      RoutesEnum.PROCESSAMENTO_PRE_TRIAGEM_PROCESSAR.toString(),
    ].includes(this.route.url);

    const estaEfetuandoPrimeiraTriagem = [
      RoutesEnum.PROCESSAMENTO_PRIMEIRA_TRIAGEM_PROCESSAR.toString(),
      RoutesEnum.PROCESSAMENTO_PRIMEIRA_TRIAGEM_EM_LOTE.toString()
    ].includes(this.route.url);

    const estaEfetuandoSegundaTriagem = [
      RoutesEnum.PROCESSAMENTO_SEGUNDA_TRIAGEM_PROCESSAR.toString()
    ].includes(this.route.url);

    const estaEfetuandoValidacaoTriagem = [
      RoutesEnum.PROCESSAMENTO_VALIDACAO_TRIAGEM_PROCESSAR.toString()
    ].includes(this.route.url);

    if (estaEfetuandoPrimeiraTriagem) {
      this.route.navigate(['/pages/triagem/navigate-back/primeira']);
    } else if (estaEfetuandoPreTriagem) {
      this.route.navigate(['/pages/triagem/navigate-back/pretriagem']);
    } else if (estaEfetuandoSegundaTriagem) {
      this.route.navigate(['/pages/triagem/navigate-back/segunda']);
    } else if (estaEfetuandoValidacaoTriagem) {
      this.route.navigate(['/pages/triagem/navigate-back/validacao']);
    }
  }

  consultaInfracoesReservadas() {
    if (!this.userService.hasRoles([this.role.ADMIN_FULL, this.role.GESTOR, this.role.PROCESSAMENTO_TRIAGEM])) {
      return;
    }

    interval(60 * 1500)
      .pipe(
        tap(() => {
          this.listAllReservations();
        }),
        takeUntil(this._destroy$)
      )
      .subscribe({
        error: (err) => console.error('Error in reservation timer:', err),
        complete: () => {
        }
      });
  }

  listAllReservations() {
    if (this.triagemService.seguraConsultaReservadas.value)
      return;

    this.showReservadasIcon = false;
    this.possueTriagemReservada = false
    const reservationRequests = this.iconMenuActions.map(menu => {
      const contrato = menu?.['data'] as ContratoTriagem;
      return this.triagemService.listReservations(contrato.id ?? -1, contrato.userId ?? -1)
        .pipe(
          takeUntil(this._destroy$),
          map((countReservadas: TriagemCountReservadas[]) => {
            return {reservadas: countReservadas, menu: menu, contrato: contrato};
          })
        );
    });

    forkJoin(reservationRequests).subscribe({
      next: (e: any[]) => {
        e.forEach((item: any) => {
          item.possueTriagemReservada = this.handleTriagemCountReservadas(item.reservadas, item.menu, item.contrato);
        });
        if (e.filter((item: any) => item.possueTriagemReservada).length === 0) {
          this.triagemService.clearAllAvaliarDepois();
          this.trataRotaTriagem();
        }
        setTimeout(() => this.showReservadasIcon = true, 2);
      }
    });
  }

  handleTriagemCountReservadas(countReservadas: TriagemCountReservadas[],
                               menu: MenuItem,
                               contrato: ContratoTriagem): boolean {
    let possueTriagemReservada = false;
    if (!!countReservadas && countReservadas.length > 0) {
      const merge = TriagemCountReservadas.merge(countReservadas);
      this.triagemService.updateAvaliarDepois(merge.reservedIds ?? [], contrato.userId ?? -1, contrato.id ?? -1);
      const totalReservadas = this.triagemService.getAvaliarDepois(contrato.userId ?? -1, contrato.id ?? -1);
      if (totalReservadas.length > 0) {
        possueTriagemReservada = true;
      }
      menu.badge = `${totalReservadas.length}`;
      menu.disabled = (totalReservadas.length ?? 0) === 0;
    }
    return possueTriagemReservada;
  }

  generateNotificationMenu() {
    this.triagemService.cacheContratos$
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (contratos: ContratoTriagem[]) => {
          if (contratos.length > 0) {
            this.iconMenuActions = [];
            contratos.forEach(contrato => {
              this.iconMenuActions.push({
                id: `${contrato.id ?? -1}`,
                data: contrato,
                label: `Liberar triagens reservadas (${contrato.descricao})`,
                command: () => this.confirmarLiberarReservadas(contrato)
              });
            })

            this.listAllReservations();
            this.consultaInfracoesReservadas();
          }
        }
      })
  }

  checkForceChangePassword() {
    const user = this.userService.user;
    return !!user.forceChangePassword;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    this.overlayPanel.hide();
  }

  /**
   * NAO ALTERAR, NECESSARIO PARA O PRIMENG MENU
   * RENDERIZAR O MENU DE RESERVADAS ATUALIZADO.
   */
  setMenuOptionsTriagem() {
    // @ts-ignore
    this.menuOptions = undefined;
    this.iconMenuActions.push({title: 'mock'});
    this.iconMenuActions.pop();
    setTimeout(() => {
      this.menuOptions = this.iconMenuActions
        .sort((a, b) => parseInt((a.badge ?? '0'), 10) - parseInt((b.badge ?? '0'), 10))
        .reverse();
    }, 10)
  }

  copiarVersao() {
    navigator.clipboard.writeText(this.version).then(() => {
    }).catch(err => {
      console.error('Erro ao copiar a versao: ', err);
    });
  }

}
