import {Injectable} from '@angular/core';
import {DeviceEvent, DeviceEventGrouped, DeviceEventMap} from '../models/device-event.model';
import {DeviceEventType} from '../shared/enums/device-event-type.enum';
import {HttpClient} from '@angular/common/http';
import {BaseService} from "./base.service";
import {map, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class ApiService extends BaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  getAll(deviceId: number, maxcount: number, eventTypes: Array<DeviceEventType>): Observable<any> {
    return this.http.get(this.getRootEndpoint() + `monitoring/events?deviceId=${deviceId}`)
      .pipe(
        map((response: any) => {
          const events: DeviceEventGrouped = {
            flowEvents: [],
            weighingsEvents: [],
          };
          if (response) {
            const flowEvents: Array<DeviceEventMap> = (
              response as DeviceEventMap[]
            ).filter((ev) => ev.event_type == DeviceEventType.FLOW);
            (flowEvents || []).forEach((ev) => {
              events.flowEvents.push(this.prepareEvent(ev));
            });
            const weighingsEvents: Array<DeviceEvent> = response.filter(
              (ev: any) => ev.event_type == DeviceEventType.WEIGHING
            );
            (weighingsEvents || []).forEach((ev) => {
              events.weighingsEvents.push(this.prepareEvent(ev));
            });
          }
          return events
        })
      );
  }

  private prepareEvent(event: any): DeviceEvent {
    if (event.documents && event.documents.length > 0) {
      event.mainPhoto = event.documents.find((d: any) => d.mainDocument);
      event.photos = event.documents.filter(
        (d: any) => d.documentType == 'JPG'
      );
    }
    return event;
  }
}
