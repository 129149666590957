import {VehicleTriagem} from "./vehicle-triagem.model";
import {UpdateImagemTriagem} from "./update-imagem-triagem.model";
import {AlertIsencaoVeiculo} from "./isencao-veiculo.model";
import {DuplicidadeInfracao} from "./duplicidade-infracao.model";
import {Dados} from "./triagem-tarja-config.model";

export interface TriagemDetail {
  plate?: string;
  imageUrl?: string;
  id?: number;
  date?: string;
  operation?: string;
  lane?: number;
  speedMetered?: number;
  speedConsidered?: number;
  inmetro?: string;
  checkedDate?: string;
  severity?: string;
  points?: any;
  vehicle?: VehicleTriagem;
  infractionTypeId?: number;
  listImage?: ListImage[];
  classificationId?: number;
  weightClassificationId?: number;
  reasonId?: number;

  seloInmetroSanmft?: string;
  dataAfericaoNaoMetrologico?: string;
  dataVencimentoNaoMetrologico?: string;
  permanenceTime?: number;

  jsonTarja?: string;

  processReevaluate?: boolean;

  apresentaFuncionalidadeRecortePlaca?: boolean;
  placaRecortada?: any;
  possueRecortePlaca?: boolean;
  totalObliteradas?: number;
  imageList?: ListImage[]; // em lote

  alerts?: AlertIsencaoVeiculo[];
  duplicidadeInfracao?: DuplicidadeInfracao[];
  loadingVeiculo?: boolean;
  erroConsultaVeiculo?: string;

  obliterandoOriginal?: boolean;

  toleranceTime?: number;
  redTrafficTime?: any;

  isProcessed?: boolean;
}

export interface ListImage {
  imageUrl?: string;
  imageId?: number;
  imageType?: number;
  obliterada?: boolean;
  imageUrlObliterada?: string;
  imagemAlterada?: UpdateImagemTriagem;

  canLoad?: boolean;
}

export function sortTriagens(value: TriagemDetail[], idToRemove?: number, permiteRecortePlaca?: boolean): TriagemDetail[] {
  value.forEach((item: TriagemDetail) => {
    if (item.listImage && item.listImage.length > 0) {
      item.apresentaFuncionalidadeRecortePlaca = !permiteRecortePlaca ? false : item.listImage.filter((item: any) => item.imageType === 24).length > 0;
      item.listImage.sort((a: any, b: any) => a.imageType - b.imageType);
    }
  });
  if (idToRemove) {
    value = value.filter((item: TriagemDetail) => item.id !== idToRemove);
  }
  return value;
}

export interface ReservadasEmTela {
  id: string;
  numbers: number[];
}

export class UniqueReservadasEmTelaSet {
  private reservadasEmTela: Map<string, ReservadasEmTela>;

  constructor() {
    this.reservadasEmTela = new Map();
  }

  addReservadasEmTela(newObject: ReservadasEmTela): void {
    this.reservadasEmTela.set(newObject.id, newObject);
  }

  removeReservadasEmTela(id: string): boolean {
    return this.reservadasEmTela.delete(id);
  }

  getReservadasEmTela(): ReservadasEmTela[] {
    return Array.from(this.reservadasEmTela.values());
  }

  getUniqueReservadasEmTela(id: string) {
    return this.reservadasEmTela.get(id);
  }
}
