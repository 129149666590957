<div class="px-6">
  <p-toast position="top-right" key="toastError"></p-toast>
  <div class="grid">

    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Nome</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="user.userName"
      pInputText/>
      <small *ngIf="!verifyName(user.userName)" class="p-invalid text-red-500">O nome do usuário não pode conter número ou caractere especial</small>
    </div>

    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">E-mail</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      type="text"
      [(ngModel)]="user.email"
      pInputText/>
      <small *ngIf="!verifyEmail(user.email)" class="p-invalid text-red-500">O e-mail não está no padrão</small>
    </div>

    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Telefone</h5>
      <p-inputMask
      [style]="stylePattern"
      mask="?99 (99)99999-9999"
      [required]="true"
      type="text"
      [(ngModel)]="user.phone"
      />
      <small *ngIf="!verifyPhone(user.phone)" class="p-invalid text-red-500">O número de telefone não está no padrão (55 (00)99999-9999)</small>
    </div>

    <div class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Grupos</h5>
      <p-multiSelect
        [scrollHeight]="'30rem'"
        [style]="stylePattern"
        [options]="groups"
        [(ngModel)]="selectedGroups"
        (onChange)="contractChange($event)"
        (onClear)="contractChange({value:[]})"
        optionValue="id"
        optionLabel="description"
        [filter]="true"
        [showClear]="true"
        placeholder="Selecionar"

      >
        <ng-template let-groups pTemplate="item">
          <div class="font-semibold text-center">
            {{ groups.description }}
          </div>
        </ng-template>
      </p-multiSelect>
    </div>

    <div *ngIf="!isEdit" class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Senha</h5>
      <input
      [style]="stylePattern"
      [disabled]="isPasswordGenerated"
      [required]="true"
      type="password"
      [(ngModel)]="user.password"
      pInputText/>
      <small *ngIf="!verifyPassword(user.password)" class="p-invalid text-red-500">A senha deve ter pelo menos 8 caracteres e conter ao menos um letra maiúscula e minúscula, um número e um caractere especial</small>
    </div>

    <div *ngIf="!isEdit" class="col-4">
      <h5 class="w-full text-xl font-medium my-2">Cofirmar senha</h5>
      <input
      [style]="stylePattern"
      [required]="true"
      [disabled]="!verifyPassword(user.password) || isPasswordGenerated"
      type="password"
      [(ngModel)]="confirmPassword"
      pInputText/>
      <small *ngIf="confirmPassword!=='' && confirmPassword !== user.password"  class="p-invalid text-red-500">Senhas não conferem</small>
    </div>

    <div *ngIf="!isEdit" class="col-4">
      <p-checkbox [binary]="true"  [(ngModel)]="user.enableMFA" label="Autenticação de dois fatores"></p-checkbox>
    </div>

    <div *ngIf="!isEdit" class="col-4">
      <p-checkbox (onChange)="generateRandomPassword($event)" [binary]="true" label="Gerar senha automática"></p-checkbox>
    </div>

    <div *ngIf="!isEdit" class="col-4">
      <p-checkbox [binary]="true"  [(ngModel)]="user.forceChangePassword" label="Troca de senha no primeiro acesso"></p-checkbox>
    </div>

    <div class="col-2">
      <button
      [disabled]="isEdit ?
      !verifyUserEdit(user)
      || !verifyName(user.userName)
      || !verifyPassword(user.password)
      || !verifyEmail(user.email)
      || !verifyPhone(user.phone) :
      !verifyUserEdit(user)
      || confirmPassword === ''
      || confirmPassword !== user.password
      || !verifyName(user.userName)
      || !verifyPassword(user.password)
      || !verifyEmail(user.email)
      || !verifyPhone(user.phone)"
      (click)="registerUser()"
      pButton
      class="block relative top-100 -translate-y-100"
      type="button"
      label="Salvar" ></button>
    </div>

  </div>
  <div *ngFor="let groupRole of userPermissions ; let i = index" >
    <div>
      <h5 class="w-full text-xl font-medium my-2">Permissões - {{getGroupDesc(groupRole.groupId)}}</h5>
      <h3 class="w-full font-normal my-2"> (Selecione ao menos uma funcionalidade)</h3>
      <p-table
      [value]="groupRole.roles"
      [tableStyle]="{'min-width': '100%'}"
      [lazy]="true"
      [loading]="loading"
      >
      <ng-template pTemplate="header">
          <tr [style]="{'cursor':'default'}">
              <th class="text-center">Funcionalidade</th>
              <th style="width:10%" class="text-center"></th>
              <th style="width:10%" class="text-center">Pode acessar</th>
              <th style="width:10%"  class="text-center"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-access>
          <tr [style]="{'cursor':'default'}">
            <td class="text-center">{{access.name}}</td>
            <td class="text-right">Não</td>
            <td class="text-center table-toggle">
              <p-inputSwitch [(ngModel)]="access.status"></p-inputSwitch>
            </td>
            <td class="text-left">Sim</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>
