export class Functionality {
  id?: number;
  name?: string;
  route?:string;
  status?: boolean;

  constructor(){
    this.name = '';
    this.route = '';
    this.status = true;

  }
}

export class FunctionalityDTO {
  id?: number;
  roleName?: string;
  roleAction?: string;
  roleRout?:string;
  active?: boolean;
}


