import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/service/alert.service';
import { AvaregeFlowService } from 'src/app/service/average-flow.service';
import { AverageFlow } from 'src/app/models/averageflow.model';
import { AvaregeSpeedService } from 'src/app/service/average-speed.service';
import { Average } from 'src/app/models/average.model';
import { DeviceService } from 'src/app/service/devices.service';
import { Occupancy } from 'src/app/models/occupancy.model';
import { OccupancyRateService } from 'src/app/service/occupancy-rate.service';
import { ApiService } from '../../../../../service/api.service';
import { DeviceEventType } from 'src/app/shared/enums/device-event-type.enum';
import { MessageService } from 'primeng/api';
import { AlertType } from 'src/app/models/alert-configuration.model';
import { DatePipe } from "@angular/common";
import { finalize, from, map, Subject, takeUntil } from "rxjs";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'real-time-events-window',
  templateUrl: './events-window.component.html',
  styleUrls: ['./events-window.component.scss']
})
export class EventsWindowComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  @Input() dialogShow: boolean = false;
  @Output() dialogShowChange = new EventEmitter<boolean>();
  private _devices!: Array<any>;

  @Input()
  set devices(devices: Array<any>) {
    this._devices = devices;
    this.populateDeviceLaneInfos();
  }

  get devices() {
    return this._devices;
  }

  list_occupancy!: Occupancy[];
  list_averageSpeed!: Average[];
  list_averageFlow!: AverageFlow[];
  checked: boolean = false;
  checkedTwo: boolean = false;
  checkedThree: boolean = false;
  flowEvents: any[] = [];
  weighingsEvents: any[] = [];
  detailsFilter: any;
  details: any = [];
  isInfoWindowOpen = false;
  openDetails = false;
  openAlertDetails = false;
  occupancyRate!: any[];
  occupancyRateData: any;
  occupancyRateTime: any;
  averageSpeedData: any;
  averageSpeedDay: any;
  loading: boolean = false;
  averageSpeedTime: any;
  averageSpeed: any;
  averageSpeedFlowData: any;
  averageSpeedFlow: any;
  averageSpeedFlowTime: any;
  list_select_AverageSpeed!: string[];
  list_select_AverageFlow!: string[];
  list_teste!: any[];
  selectedSpeed: any = {};
  selectTipoFiltro: string = "";
  selectItemFiltro: string = "";
  selectTipoFiltroFlow: any = "";
  selectItemFiltroFlow: string = "";
  selectTipoDispositivoflow: string = "";
  selectTipoDispositivoflowTwo: any;
  showSpinner = false;
  showSpinnerAlerts = false;

  alerts: any = [];

  tipoFiltroOptions: any[] = [
    { name: 'Dia', code: `dia` },
    { name: 'Hora', code: `hora` },
  ];

  constructor(private deviceService: DeviceService,
    private alertService: AlertService,
    private messageService: MessageService,
    private occupancyRateService: OccupancyRateService,
    private averageSpeedService: AvaregeSpeedService,
    private averageFlowService: AvaregeFlowService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
  }

  getDeviceAlerts() {
    this.devices.map((element: any) => {
      this.showSpinnerAlerts = true;
      this.alertService.getAlertByDeviceId(element.deviceId)
        .pipe(
          takeUntil(this._destroy$),
          finalize(() => this.showSpinnerAlerts = false),
        )
        .subscribe({
          next: (resultData) => {
            const newAlertData = {
              deviceName: element.deviceName,
              alerts: resultData[0].alertReport
            }
            this.alerts.push(newAlertData);
          },
          error: (error) => {},
        });
    });
  }

  tabChange(event: any) {
    if (event.index === 2 && this.alerts.length < 1) {
      this.getDeviceAlerts();
    }
  }

  getMainImage(arrayImages: any) {
    const image = arrayImages.find((e: any) => e.main === true);
    if (image !== undefined) {
      return image.img;
    } else if (arrayImages.length > 0) {
      return arrayImages[0].img;
    }
    return 'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png';
  }

  filtro(e: Event) {
    this.getAverageSpeedData(this.selectTipoDispositivoflow);
    this.getFiltroAverageSpeed(this.selectTipoDispositivoflow);
  }

  filtroAverageFlow() {
    this.getAverageFlow(this.selectTipoDispositivoflowTwo?.deviceId ?? this.selectTipoDispositivoflowTwo);
    this.getFiltroAverageFlow(this.selectTipoDispositivoflowTwo?.deviceId ?? this.selectTipoDispositivoflowTwo);
  }

  filtroAverageDevice(e: any) {
    this.getFiltroAverageFlow(this.selectTipoDispositivoflow);
  }

  gatilhoItemFiltro(e: Event) {
    this.getAverageSpeedData(this.selectTipoDispositivoflow);
  }

  gatilhoEventoDispositivo(e: any) {
    this.selectTipoDispositivoflowTwo = "";
    this.selectTipoFiltroFlow = "";
  }

  gatilhoItemFiltroFlow() {
    this.getAverageFlow(this.selectTipoDispositivoflowTwo?.deviceId ?? this.selectTipoDispositivoflowTwo);
  }

  compareDates(a: any, b: any) {
    const dateA: any = new Date(a.date);
    const dateB: any = new Date(b.date);

    if (dateA.getTime() === dateB.getTime()) {
        // Se as datas forem iguais, compare pelas horas
        return a.hour.localeCompare(b.hour);
    } else {
        // Caso contrário, compare pelas datas
        return dateA - dateB;
    }
  }

  getAverageFlow(deviceId: string) {
    this.loading = true;
    this.averageFlowService.getAverageFlow(deviceId)

      .pipe(takeUntil(this._destroy$))
      .subscribe((retorno) => {
        this.list_averageFlow = retorno;
        this.list_averageFlow.sort(this.compareDates);
        if (this.selectItemFiltroFlow != "") {
          let newListAverage: AverageFlow[] = [];
          if (this.selectTipoFiltroFlow?.code == "hora") {
            for (let i = 0; i < this.list_averageFlow.length; i++) {
              if (this.list_averageFlow[i].hour == this.selectItemFiltroFlow) {
                newListAverage.push(this.list_averageFlow[i]);
              }
            }
          } else if (this.selectTipoFiltroFlow?.code == "dia") {
            for (let i = 0; i < this.list_averageFlow.length; i++) {
              if (
                this.list_averageFlow[i].today.toString() ==
                new DatePipe('pt').transform(this.selectItemFiltroFlow, 'dd-MM-yyyy')
              ) {
                newListAverage.push(this.list_averageFlow[i]);
              }
            }
          }
          this.list_averageFlow = newListAverage;
        }
        if(this.selectItemFiltroFlow){
          this.averageSpeedFlow = this.list_averageFlow.map(
            (retorno) => retorno.flowTotal
          );
        }
        this.averageSpeedFlowTime = this.list_averageFlow.map(
          (retorno) =>{
            const formattedDate = new Date(retorno.date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
            const formattedHour = retorno.hour.split(':')[0]; // Extrai os dois primeiros dígitos da hora
            if(this.selectTipoFiltroFlow?.code == "dia"){
              return `${formattedHour}hrs`;
            }
            return `${formattedDate}`;
          }
        );
        if(this.selectItemFiltroFlow){
          this.averageSpeedFlowData = {
            labels: this.averageSpeedFlowTime,
            datasets: [
              {
                label: `Total de Passagem por Hora`,
                data: this.averageSpeedFlow,
              },
            ],
          };
        }
        this.loading = false;
      });
  }

  getFiltroAverageFlow(deviceId: string) {
    this.loading = true;
    this.list_select_AverageFlow = [];
    this.selectItemFiltroFlow = '';
    this.averageFlowService.getAverageFlow(deviceId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((retorno) => {
        if (retorno.length == 0) {
          this.messageService.add({
            key: "c",
            severity: "warn",
            summary: "Aviso",
            detail: "Registro Indisponível!",
          });
        }
        let novalist: string[] = [];
        if (this.selectTipoFiltroFlow?.code == "dia") {
          for (let i = 0; i < retorno.length; i++) {
            if (i == 0) {
              novalist.push(retorno[i].today.toString());
            } else {
              if (!novalist.includes(retorno[i].today.toString())) {
                novalist.push(retorno[i].today.toString());
              }
            }
          }
        } else if (this.selectTipoFiltroFlow?.code == "hora") {
          for (let i = 0; i < retorno.length; i++) {
            if (i == 0) {
              novalist.push(retorno[i].hour.toString());
            } else {
              if (!novalist.includes(retorno[i].hour.toString())) {
                novalist.push(retorno[i].hour.toString());
              }
            }
          }
        }
        this.list_select_AverageFlow = novalist;
        this.loading = false;
      });
  }

  getFiltroAverageSpeed(deviceId: string) {
    this.loading = true;
    this.averageSpeedService.getAverageSpeed(deviceId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((retorno: any) => {
        if (retorno.length == 0) {
          this.messageService.add({
            key: "c",
            severity: "warn",
            summary: "Aviso",
            detail: "Registro Indisponível!",
          });
        }
        let novalist: string[] = [];
        novalist.push("Selecione uma opção");
        if (this.selectTipoFiltro == "dia") {
          for (let i = 0; i < retorno.length; i++) {
            if (i == 0) {
              novalist.push(retorno[i].day.toString());
            } else {
              if (!novalist.includes(retorno[i].day.toString())) {
                novalist.push(retorno[i].day.toString());
              }
            }
          }
        } else if (this.selectTipoFiltro == "hora") {
          for (let i = 0; i < retorno.length; i++) {
            if (i == 0) {
              novalist.push(retorno[i].hora.toString());
            } else {
              if (!novalist.includes(retorno[i].hora.toString())) {
                novalist.push(retorno[i].hora.toString());
              }
            }
          }
        }
        this.list_select_AverageSpeed = novalist;
        this.loading = false;
      });
  }

  getAverageSpeedData(deviceId: string) {
    this.loading = true;
    this.averageSpeedService.getAverageSpeed(deviceId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((retorno: any) => {
        this.list_averageSpeed = retorno;
        if (this.selectItemFiltro != "") {
          let newListAverage: Average[] = [];
          if (this.selectTipoFiltro == "hora") {
            for (let i = 0; i < this.list_averageSpeed.length; i++) {
              if (this.list_averageSpeed[i].hora == this.selectItemFiltro) {
                newListAverage.push(this.list_averageSpeed[i]);
              }
            }
          } else if (this.selectTipoFiltro == "dia") {
            for (let i = 0; i < this.list_averageSpeed.length; i++) {
              if (
                this.list_averageSpeed[i].day.toString() == this.selectItemFiltro
              ) {
                newListAverage.push(this.list_averageSpeed[i]);
              }
            }
          }
          this.list_averageSpeed = newListAverage;
        }

        this.averageSpeed = this.list_averageSpeed.map(
          (retorno) => retorno.averageSpeed
        );
        this.averageSpeedTime = this.list_averageSpeed.map(
          (retorno) => retorno.hora
        );
        this.averageSpeedData = {
          labels: this.averageSpeedTime,
          datasets: [
            {
              label: "Velocidade Media",
              data: this.averageSpeed,
            },
          ],
        };
        this.loading = false;
      });
  }

  async eventsDetails(index: any, event: any, deviceName: any) {
    this.details = event;
    this.details.name = deviceName;
    if (event.event_type === "FLOW") {
      this.detailsFilter = await this.flowEvents[index];
    } else {
      this.detailsFilter = await this.weighingsEvents[index];
    }
    this.openDetails = true;
  }

  openAlertEvent(event: any) {
    this.details = event;
    this.openAlertDetails = true;
  }

  populateDeviceLaneInfos() {
    for (const device of this.devices) {
      device.completeName = `${device.deviceName ?? '-'} - Sentido: ${device.flowDirection ?? '-'}`
      device.laneDirection = '';
      from(this.apiService.getAll(device.deviceId, 5, [
        DeviceEventType.FLOW,
        DeviceEventType.WEIGHING,
      ])).subscribe(events => {
        if (events?.flowEvents.length > 0) {
          device.completeName = `${device.completeName} (Pista ${events.flowEvents[0]['lane_identification']})`
        }
      });
    }
  }

  getEvents(event: any) {
    const index = event.index;
    const device = this.devices[index];
    this.flowEvents[index] = [];
    this.weighingsEvents[index] = [];

    this.showSpinner = true;

    this.apiService.getAll(device.deviceId, 5, [
      DeviceEventType.FLOW,
      DeviceEventType.WEIGHING,
    ])
      .pipe(
        takeUntil(this._destroy$),
        map(events => {
          events.weighingsEvents = events.weighingsEvents.map((wFlow:any) => {
            (wFlow.documents as any[]).map(wFlowDoc => {
              wFlowDoc.img = this.alertService.consultaImgBucketS3(wFlowDoc.img, (imageObjectUrl: string) => wFlowDoc.img = this.sanitizer.bypassSecurityTrustUrl(imageObjectUrl));
              return wFlowDoc;
            });

            return wFlow;
          });
          return events;
        }),
        finalize(() => this.showSpinner = false),
      )
      .subscribe({
        next: (events) => {
          this.flowEvents[index] = events.flowEvents;
          if (events.weighingsEvents.length > 0) {
            this.weighingsEvents[index] = events.weighingsEvents;
          }
        },
        error: (error) => this.errorAlert(error.message),
      })
  }

  //Slide navigation
  nextPageFlow(index: any) {
    document.getElementById("fullFlow-" + index)!.scrollLeft += 150;
  }

  previousPageFlow(index: any) {
    document.getElementById("fullFlow-" + index)!.scrollLeft -= 150;
  }

  nextPageWeight(index: any) {
    document.getElementById("fullWeight-" + index)!.scrollLeft += 150;
  }

  previousPageWeight(index: any) {
    document.getElementById("fullWeight-" + index)!.scrollLeft -= 150;
  }

  closeInfoWindow() {
    this.alerts = [];
    this.isInfoWindowOpen = false;
    this.selectTipoFiltro = "";
    this.selectItemFiltroFlow = "";
    this.selectTipoFiltroFlow = "";
    this.selectItemFiltro = "";
    this.selectTipoDispositivoflow = "";
    this.list_select_AverageSpeed = [];
    this.list_averageSpeed = [];
    this.averageSpeedData = [];
    this.averageSpeedFlow = [];
    this.list_averageFlow = [];
    this.selectTipoDispositivoflowTwo = "";
  }

  closeEventsWindow() {
    this.dialogShow = false;
    this.cleanDialog();
  }

  getAlertTypeString(alert: string) {
    switch (alert) {
      case AlertType.weight:
        return 'Excesso de Peso'
      case AlertType.speed:
        return 'Excesso de Velocidade'
      case AlertType.temperature:
        return 'Temperatura Crítica'
      default:
        return ''
    }
  }

  cleanDialog() {
    this.flowEvents = [];
    this.weighingsEvents = [];
    this.list_select_AverageSpeed = [];
    this.list_averageSpeed = [];
    this.averageSpeedData = [];
    this.details = [];
    this.isInfoWindowOpen = false;
    this.dialogShowChange.emit(false);
  }

  getFlow(): Array<any> {
    return [];
  }

  errorAlert(error: any) {
    this.messageService.add({
      key: "toastError",
      severity: "error",
      summary: "Error",
      detail: error,
    });
  }
}
