import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class BaseService {

  private avoidProxy: boolean = environment.disableProxyLocal;
  constructor(public httpClient: HttpClient) {
  }

  getData(url: string): Observable<any> {
    return this.httpClient.get(url, {})
      .pipe(
        map((dados: any) => {
          return dados;
        }),
        // catchError((erro: HttpErrorResponse) => {
        //   return this.formatError(erro);
        // })
      );
  }

  putData(url: string, dados: any): Observable<any> {
    return this.httpClient.put(url, dados)
      .pipe(
        map((dados: any) => {
          return dados;
        }),
        // catchError((erro: HttpErrorResponse) => {
        //   return this.formatError(erro);
        // })
      );
  }

  postData(url: string, dados?: any): Observable<any> {
    return this.httpClient.post(url, dados || {})
      .pipe(
        map((dados: any) => {
          return dados;
        }),
        // catchError((erro: HttpErrorResponse) => {
        //   return this.formatError(erro);
        // })
      );
  }

  deleteData(url: string, data = {}): Observable<any> {
    return this.httpClient.delete(url, { body: data, observe: 'body', responseType: 'text' })
      .pipe(
        map((dados: any) => {
          return dados;
        }),
        // catchError((erro: HttpErrorResponse) => {
        //   return this.formatError(erro);
        // })
      );
  }

  buildUrlQueryParams(params: any, urlRequest: string): string {
    if (!params) return urlRequest;

    for (const key in params) {
      if (params[key] != null) {
        urlRequest = urlRequest.replaceAll("{" + key + "}", encodeURIComponent(params[key]));
      }
    }
    return urlRequest;
  }


  private formatError(error: any) {
    const erroObj: any = { status: 0, message: null };

    if (error && error.status) {
      switch (error.status) {
        case 401:
          erroObj.message = 'Sessão expirada! Efetue seu login novamente.';
          break;
        case 400:
        case 500:
          erroObj.message = 'Ocorreu um erro na comunicação com o Servidor, tente novamente mais tarde.';
          break;
        default:
          erroObj.message = 'Ocorreu uma falha na operação.';
          break;
      }
      erroObj.status = error.status;
    }
    const body: any = error.error || error.data;
    if (body) {
      if (body.mensagem || body.message || body.errors) {
        erroObj.message = body.mensagem || body.message || body.errors;
      }
    }
    return erroObj;
  }

  protected getRootEndpoint() {
    return (document.URL.indexOf("http://localhost:42") == 0 ? environment.proxy : environment.api) + "api/";
  }

  protected getLakeEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyLake : environment.apiLake) + "";
  }

  protected getFormAuthV1EndPoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyFormAuthV1 : environment.apiForAuthV1) + "";
  }

  protected getFormAuthV2EndPoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyFormAuth : environment.apiForAuth) + "";
  }

  protected getLegacyEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyLegacy : environment.apiLegacy) + "api/";
  }

  protected getReportsEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyReports : environment.apiReports);
  }

  protected getAuthEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyAuth : environment.baseApi);
  }

  protected getExportEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyExport : environment.apiExport);
  }

  protected getBuildEndpoint() {
    return (!this.avoidProxy && document.URL.indexOf("http://localhost:42") == 0 ? environment.proxyBuild : environment.apiBuild) +"api/";
  }

  public getEndpoint(endpoint: string): string {
    switch (endpoint) {
      case 'SET_TENANT':
        return this.getRootEndpoint() + "v1/context/tenant";
      case 'DEVICES':
        return this.getRootEndpoint() + "v1/device/list";
      case 'FORM':
        return this.getLakeEndpoint();
      case 'AUTHV1':
        return this.getFormAuthV1EndPoint() + "";
      case 'AUTHV2':
        return this.getFormAuthV2EndPoint() + "";
      case 'EQUIPMENT':
        return this.getRootEndpoint() + "v1/equipment/findByGroup";
      case 'GRUPOS':
        return this.getRootEndpoint() + "v1/group?maxResultCount=1000";
      case 'QFV':
        return this.getRootEndpoint() + "v1/device/tgwConfiguration/getQfv";
      case 'ALERT_CONFIGURATION':
        return this.getRootEndpoint() + "v1/device/alertConfiguration";
      case 'ALERT_CONFIGURATION_PAGINATION':
        return this.getRootEndpoint() + "v1/device/alertConfiguration/list?page={page}&size={size}";
      case 'ALERT_REPORT':
        return this.getRootEndpoint() + "v1/device/alertReport/filtered?maxResultCount={maxcount}&skipCount={skipcount}";
      case 'ALERT_REPORT_COUNT':
        return this.getRootEndpoint() + "v1/device/alertReport/getCountAlertReports";
      case 'ALERT_REPORT_BY_DEVICE':
        return this.getRootEndpoint() + "v1/device/alertReport?devicesId={deviceId}";
      case 'ALERT_REPORT_BY_ALERT_TYPE':
        return this.getRootEndpoint() + "v1/device/alertReport?alertType={alertType}&maxResultCount={maxcount}&skipCount={skipcount}";
      case 'PBT_CONFIGURATION':
        return this.getRootEndpoint() + "v1/device/tgwConfiguration/list";
      case 'GRUPOS_ID':
        return this.getRootEndpoint() + "v1/group/{id}";
      case 'DEVICES_EVENTS_GROUP':
        return this.getRootEndpoint() + "v1/event/byTypes?devicesId={deviceId}&sorting=date desc&maxResultCount={maxcount}";
      case 'DEVICES_EVENTS':
        return this.getRootEndpoint() + "v1/event/list?deviceId={deviceId}&sorting=date desc&maxResultCount={maxcount}&skipCount={skipcount}";
      case 'EVENTS_MAP':
        return this.getRootEndpoint() + "v1/event/listMap?deviceId={deviceId}&sorting=date%20desc&maxResultCount=5&skipCount=0&eventTypes=FLOW&eventTypes=WEIGHING";
      case 'EVENTS':
        return this.getRootEndpoint() + "v1/event/list?sorting=date&maxResultCount={maxcount}&skipCount={skipcount}";
      case 'EVENTS_ALERT':
        return this.getRootEndpoint() + "v1/event";
      case 'ALERT_LAST_ID':
        return this.getRootEndpoint() + "v1/overweightAlert/getLastIdAlert";
      case 'ALERT_AFTER_LAST':
        return this.getRootEndpoint() + "v1/overweightAlert/getAfterIdAlert?idAfter={idafter}";
      case 'DEVICE_METRICS':
        return this.getRootEndpoint() + "v1/deviceMetrics";
      case 'DEVICE_METRICS_ID':
        return this.getRootEndpoint() + "v1/deviceMetrics/getDetailsById";
      case 'OCCUPANCY_RATE':
        return this.getRootEndpoint() + "v1/deviceMetrics/getOccupancyRate?deviceId={deviceId}";
      case 'AVERAGE_SPEED':
        return this.getRootEndpoint() + "v1/deviceMetrics/getAverageSpeed?deviceId={deviceId}";
      case 'AVERAGE_FLOW':
        return this.getRootEndpoint() + "v1/deviceMetrics/getAverageFlow?deviceId={deviceId}";
      case 'TRUCK_OCCUPANCY_RATE':
        return this.getRootEndpoint() + "v1/deviceMetrics/getOccupancyTruck";
      case 'SPEED':
        return this.getRootEndpoint() + "v1/deviceMetrics/getSpeed";
      case 'GET_MOTIVE_OCCURRENCE':
        return this.getRootEndpoint() + "v1/motiveOccurrence?pageSize={maxcount}&pageNumber={skipcount}";
      case 'MOTIVE_OCCURRENCE':
        return this.getRootEndpoint() + "v1/motiveOccurrence";
      case 'MOTIVE_OCCURRENCE_ALL':
        return this.getRootEndpoint() + "v1/motiveOccurrence/getAll";
      case 'GET_OCCURRENCE':
        return this.getRootEndpoint() + "v1/occurrence?pageSize={maxcount}&pageNumber={skipcount}";
      case 'OCCURRENCE':
        return this.getRootEndpoint() + "v1/occurrence";
      case 'OCCURRENCE_OPERATIONS':
        return this.getLegacyEndpoint() + "v1/operation/list";
      case 'REPORTS_TYPES':
        return this.getReportsEndpoint() + "report/reports";
      case 'REPORTS_REQUEST':
        return this.getReportsEndpoint() + "report/insert";
      case 'REPORTS_LIST':
        return this.getReportsEndpoint() + "report/search?maxResultCount={maxresultcount}&skipCount={skipcount}";
      case 'REPORTS_DELETE':
        return this.getReportsEndpoint() + "report/delete?reportIds={report}";
      case 'REPORTS_GROUPS':
        return this.getReportsEndpoint() + "report/groups";
      case 'REPORTS_DOWNLOAD':
        return this.getReportsEndpoint() + "report/download";
      case 'REPORTS_DEVICES':
        return this.getReportsEndpoint() + "report/devices";
      case 'REPORTS_REPORT_LINK':
        return this.getRootEndpoint() + "v1/report/getByReference?reference={reference}";
      case 'REPORTS_PARAMETERS':
        return this.getReportsEndpoint() + "report/findByReportParameters";
      case 'USERS_ROLE':
        return this.getAuthEndpoint() + "users/role";
      case 'USERS_CONTRACT':
        return this.getAuthEndpoint() + "users/contract";
      case 'USERS_GROUPS':
        return this.getAuthEndpoint() + "users/groups";
      case 'USERS':
        return this.getAuthEndpoint() + "users";
      case 'SEND_TOKEN_LOGIN':
        return this.getAuthEndpoint() + "users/auth";
      case 'UPDATE_AUDITOR_RECORD':
        return this.getAuthEndpoint() + "users/updateAuditor";
      case 'AUTH_LOGIN':
        return this.getAuthEndpoint() + "users/login";
      case 'AUTH_LOGIN_TOKEN':
        return this.getAuthEndpoint() + 'users/getByEmail?email={email}';
      case 'AUTH_REGISTER':
        return this.getAuthEndpoint() + "users/register";
      case 'CHANGE_PASSWORD':
        return this.getAuthEndpoint() + "users/updatePassword";
      case 'FORGOT_PASS_USER':
        return this.getAuthEndpoint() + "users/resetPassword";

      case 'GET_VIOLATION':
        return this.getRootEndpoint() + "vtm/getViolation?maxResultCount={maxresultcount}&skipCount={skipcount}";
      case 'SET_VIOLATION':
        return this.getRootEndpoint() + "vtm/setStatus/violation?key={key}&statusCode={statusCode}";

      case 'GET_DENATRAN_TYPE':
        return this.getBuildEndpoint() + "denatranVehicleType/findByContract";
      case 'GET_CONTRACT':
        return this.getBuildEndpoint() + "contract/findByUser";
      case 'VEHICLE_CLASSIFICATION':
        return this.getBuildEndpoint() + "vehicleClassification?size={size}&page={page}&contract={contract}";
      case 'SAVE_VEHICLE_CLASSIFICATION':
        return this.getBuildEndpoint() + "vehicleClassification/save";
      case 'UPDATE_VEHICLE_CLASSIFICATION':
        return this.getBuildEndpoint() + "vehicleClassification/update";
      case 'DELETE_VEHICLE_CLASSIFICATION':
        return this.getBuildEndpoint() + "vehicleClassification/delete";
      case 'OPERATION_PROFILE':
        return this.getBuildEndpoint() + "operationProfile?contract={contract}&active={active}&page={page}&size={size}";

      case 'SAVE_OPERATION_PROFILE':
        return this.getBuildEndpoint() + "operationProfile/save";

      case 'DELETE_OPERATION_PROFILE':
        return this.getBuildEndpoint() + "operationProfile/delete";

      case 'FIND_BY_ALL_OPERATIONS':
        return this.getBuildEndpoint() + "operation";

      case 'PERIPHERAL_TYPE_OPTIONS':
        return this.getBuildEndpoint() + "peripheralType";
      case 'SUPPLIER':
        return this.getBuildEndpoint() + "supplier";
      case 'CAMERA_RESOLUTION_OPTIONS':
        return this.getBuildEndpoint() + "camera/findResolution";
      case 'PERIPHERAL':
        return this.getBuildEndpoint() + "peripheral?size={size}&page={page}";
      case 'SAVE_PERIPHERAL':
        return this.getBuildEndpoint() + "peripheral/save";
      case 'UPDATE_PERIPHERAL':
        return this.getBuildEndpoint() + "peripheral/update";
      case 'DELETE_PERIPHERAL':
        return this.getBuildEndpoint() + "peripheral/delete";

      case 'FRAMING_OPTIONS':
        return this.getBuildEndpoint() + "violationType/findViolationFraming";
      case 'GRAVITY_OPTIONS':
        return this.getBuildEndpoint() + "violationType/findViolationGravity";
      case 'OFFENDER_OPTIONS':
        return this.getBuildEndpoint() + "violationType/findOffenderType";
      case 'CAPTURE_POINTS':
        return this.getBuildEndpoint() + "violationType/findCapturePointDetail";
      case 'CAMERA_BY_CONTRACT':
        return this.getBuildEndpoint() + "peripheral/findCameraByContract?contractId={contractId}";
      case 'OPERATION_BY_CONTRACT':
        return this.getBuildEndpoint() + "operation/findByContract?contractId={contractId}";
      case 'DEVICE_TYPES':
        return this.getBuildEndpoint() + "deviceType";
      case 'INFRACTION_TYPE':
        return this.getBuildEndpoint() + "violationType?size={size}&page={page}&contract={contract}";
      case 'INFRACTION_TYPE_DETAIL':
        return this.getBuildEndpoint() + "violationType/findByDetails?id={id}";
      case 'UPDATE_INFRACTION_TYPE':
        return this.getBuildEndpoint() + "violationType/update";

      case 'RECTANGLE_TYPE':
        return this.getBuildEndpoint() + "stripe/findRectanglePosition";
      case 'FONT_STYLE':
        return this.getBuildEndpoint() + "stripe/findSource";
      case 'FONT_SIZE':
        return this.getBuildEndpoint() + "stripe/findFontSize";
      case 'PARAMETER_TYPE':
        return this.getBuildEndpoint() + "stripe/findStripeParameter";
      case 'IMPORT_STRIPE':
        return this.getBuildEndpoint() + "stripe/findImportStripe?contractId={contractId}";
      case 'GET_IMPORT_JSON':
        return this.getBuildEndpoint() + "stripe/findImportTargetStripe?stripeImportTargetId={stripeImportTargetId}";
      case 'IMPORT_TARGET_STRIPE':
        return this.getBuildEndpoint() + "stripe/saveTargetStripe?stripeId={stripeId}&stripeImportTargetId={stripeImportTargetId}";
      case 'STRIPE_DETAILS':
        return this.getBuildEndpoint() + "stripe/findByDetail?violationTypeId={violationTypeId}&peripheralCameraId={peripheralCameraId}&operationId={operationId}&userId={userId}&videoCamera={videoCamera}";
      case 'SAVE_STRIPE':
        return this.getBuildEndpoint() + "stripe/save";
      case 'SAVE_RECTANGLE':
        return this.getBuildEndpoint() + "stripe/saveRectangle?stripeId={stripeId}";
      case 'SAVE_RECTANGLE_FIELD':
        return this.getBuildEndpoint() + "stripe/saveRectangle?stripeId={stripeId}";
      case 'DELETE_RECTANGLE':
        return this.getBuildEndpoint() + "stripe/removeRectangle?stripeId={stripeId}";
      case 'DELETE_RECTANGLE_FIELD':
        return this.getBuildEndpoint() + "stripe/removeField?stripeId={stripeId}";
      case 'DELETE_STRIPE':
        return this.getBuildEndpoint() + "stripe/delete?id={id}";
      case 'GET_IMAGE_PERIPHERAL':
        return this.getBuildEndpoint() + "stripe/findImagePeripheral?peripheralCameraId={peripheralCameraId}";
      case 'PUT_IMAGE_PERIPHERAL':
        return this.getBuildEndpoint() + "stripe/updateViewImageStripe?peripheralCameraId={peripheralCameraId}&operationId={operationId}&violationTypeId={violationTypeId}";

      case 'GET_CITIES':
        return this.getBuildEndpoint() + "city";
      case 'GET_LOCAL_TYPE':
      return this.getBuildEndpoint() + "operationPlace/findLocalType";
      case 'OPERATION_LOCAL':
        return this.getBuildEndpoint() + "operationPlace?size={size}&page={page}&active={active}&contract={contract}";
      case 'OPERATION_LOCAL_DETAIL':
        return this.getBuildEndpoint() + "operationPlace/findByDetails?id={id}";
      case 'SAVE_OPERATION_LOCAL':
        return this.getBuildEndpoint() + "operationPlace/save";
      case 'UPDATE_OPERATION_LOCAL':
        return this.getBuildEndpoint() + "operationPlace/update";

      // EXPORTAÇÃO AIT
      case 'GET_EXPORT_AIT_PARAMETER':
        return this.getExportEndpoint() + "export/{contractIdRota}/parameter"
      case 'GET_EXPORT_AIT_GROUP':
        return this.getExportEndpoint() + "export/{contractIdRota}/group?userLegacyId={userLegacyId}";
      case 'GET_EXPORT_AIT_DEVICE':
        return this.getExportEndpoint() + "export/{contractIdRota}/device?groupId={groupId}";
      case 'GET_EXPORT_AIT_VIOLATION':
        return this.getExportEndpoint() + "export/{contractIdRota}/violationType?groupId={groupId}";
      case 'GET_EXPORT_AIT_FINDBYFILTER':
        return this.getExportEndpoint() + "export/{contractIdRota}/findByFilter";
      case 'GET_EXPORT_AIT_DOWNLOAD_FILE_ZIP':
        return this.getExportEndpoint() + 'export/{contractIdRota}/download?exportId={exportId}&contractId={contractId}'
      case 'GET_EXPORT_AIT_FINDBYRANGEDDATE':
        return this.getExportEndpoint() + "export/{contractIdRota}/findByRangedDate?rangedDate={rangedDate}&groupId={groupId}&page={page}&size={size}"
      case 'GET_EXPORT_AIT_FINDBYEXPORT_BATCHES':
        return this.getExportEndpoint() + "export/{contractIdRota}/findByExportBatches"
      case 'POST_EXPORT_AIT_EXPORT_INSERT':
        return this.getExportEndpoint() + "export/{contractIdRota}/insert"
      case 'GET_EXPORT_AIT_FINDBATCHLIMIT':
        return this.getExportEndpoint() + "export/{contractIdRota}/findExportBatchLimit?groupId={groupId}"
      case 'CANCEL_EXPORT_AIT_LOTE':
        return  this.getExportEndpoint() + "export/{contractIdRota}/cancelExport?exportId={export}&userLegacyId={user}&contractId={contractId}"

      default:
        return '';
    }
  }

}
