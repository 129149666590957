import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from "@angular/common/http";
import { DataTablePage } from '../models/data-table-page.model';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { MotiveOccurrence } from '../models/occurrence.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OccurrenceService extends BaseService {

  constructor(public override httpClient: HttpClient) {
    super(httpClient);
  }

  private occurrenceInUse: BehaviorSubject<any> = new BehaviorSubject(null);
  occurrenceInUse$: Observable<any> = this.occurrenceInUse.asObservable();

  setOccurreceInUse(occurrence: any) {
    this.occurrenceInUse.next(occurrence);
  }

  getOccurreceInUse(): Observable<any> {
    return this.occurrenceInUse$;
  }

  getMotiveOccurrence(page: DataTablePage, filters: any): Observable<Array<MotiveOccurrence>> {
    let url: string = this.buildUrlQueryParams(
      {
        maxcount: page.size,
        skipcount: page.pageNumber,
      },
      this.getEndpoint('GET_MOTIVE_OCCURRENCE')
    );
    if (filters.grupos) {
      url += '&groupId=' + filters.grupos;
    }
    if (filters.description) {
      url += '&description=' + filters.description;
    }
    if (filters.type) {
      url += '&type=' + filters.type;
    }
    if (filters.serviceTime) {
      url += '&serviceTime=' + filters.serviceTime;
    }
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            page.totalElements = response.totalElements;
            page.totalPages = page.totalElements / page.size;
            return response;
          }
          return [];
        })
      );
  }

  getAllMotiveOccurrence(groupsId: any): Observable<Array<MotiveOccurrence>>{
    let url: string = this.getEndpoint('MOTIVE_OCCURRENCE_ALL');
    if (groupsId) {
      if(typeof groupsId === "number") {
        url += '?groupId=' + groupsId
      } else {
        url += '?groupId=' + groupsId.join(',');
      }
    }
    return this.getData(url)
    .pipe(
      map((response) => {
        if (response) {
          return response;
        }
        return [];
      })
    );
  }

  deleteMotiveOccurrence(occurrenceMotiveId: number) {
    let url: string = this.getEndpoint('MOTIVE_OCCURRENCE');
    url += `/${occurrenceMotiveId}`
    return this.deleteData(url);
  }

  deleteOccurrence(occurrenceId: number) {
    let url: string = this.getEndpoint('OCCURRENCE');
    url += `/${occurrenceId}`
    return this.deleteData(url);
  }

  updateMotiveOccurrence(occurrenceData: MotiveOccurrence) {
    const url: string = this.getEndpoint('MOTIVE_OCCURRENCE');
    return this.putData(url, occurrenceData);
  }

  updateOccurrence(occurrenceData: any) {
    const url: string = this.getEndpoint('OCCURRENCE');
    if (occurrenceData.startDate && occurrenceData.endDate) {
      occurrenceData.startDate.setHours(0, 0, 0, 0);
      occurrenceData.endDate.setHours(23, 59, 59, 0);

      if (occurrenceData.startHour) {
        const startHourSplitted = String(moment(occurrenceData.startHour).format('HH:mm')).split(':');
        occurrenceData.startDate.setHours(
          (parseInt(startHourSplitted[0])),
          parseInt(startHourSplitted[1])
        );
      }
      if (occurrenceData.endHour) {
        const endHourSplitted = String(moment(occurrenceData.endHour).format('HH:mm')).split(':');
        occurrenceData.endDate.setHours(
          (parseInt(endHourSplitted[0])),
          parseInt(endHourSplitted[1])
        );
      }
      delete occurrenceData.startHour;
      delete occurrenceData.endHour;
      occurrenceData.startDate = moment(occurrenceData.startDate).format('DD/MM/YYYY HH:mm:ss');
      occurrenceData.endDate = moment(occurrenceData.endDate).format('DD/MM/YYYY HH:mm:ss');
    }

    if (occurrenceData.protocolDate) {
      occurrenceData.protocolDate.setHours(0, 0, 0, 0);
      if (occurrenceData.protocolHour) {
        const protocolHourSplitted = String(moment(occurrenceData.protocolHour).format('HH:mm')).split(':');
        occurrenceData.protocolDate.setHours(
          (parseInt(protocolHourSplitted[0])),
          parseInt(protocolHourSplitted[1])
        );
      }
    }
    delete occurrenceData.createdAt;
    delete occurrenceData.protocolHour;
    occurrenceData.protocolDate = moment(occurrenceData.protocolDate).format('DD/MM/YYYY HH:mm:ss');
    return this.putData(url, occurrenceData);
  }

  saveMotiveOccurrence(newMotiveOccurrence: MotiveOccurrence){
    const url: string = this.getEndpoint('MOTIVE_OCCURRENCE');
    return this.postData(url, newMotiveOccurrence);
  }

  getOccurrence(page: DataTablePage, filters: any): Observable<Array<any>> {
    let url: string = this.buildUrlQueryParams(
      {
        maxcount: page.size,
        skipcount: page.pageNumber,
      },
      this.getEndpoint('GET_OCCURRENCE')
    );
    if (filters.grupos) {
      url += '&groupId=' + filters.grupos;
    }
    if (filters.operation) {
      url += '&operation=' + filters.operation;
    }
    if (filters.motiveOccurrence) {
      url += '&motiveOccurrence=' + filters.motiveOccurrence;
    }
    if (filters.type) {
      url += '&type=' + filters.type;
    }
    if (filters.rejected) {
      url += '&rejected=' + filters.rejected;
    }
    if (filters.startDate && filters.endDate) {
      filters.startDate.setHours(0, 0, 0, 0);
      filters.endDate.setHours(23, 59, 59, 0);
      const startDate: string = moment(filters.startDate).format('YYYY-MM-DD HH:mm:ss');
      const endDate: string = moment(filters.endDate).format('YYYY-MM-DD HH:mm:ss');
      url += encodeURI(`&startDate=${startDate}&endDate=${endDate}`);
    }
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            page.totalElements = response.totalElements;
            page.totalPages = page.totalElements / page.size;
            return response;
          }
          return [];
        })
      );
  }

  saveOccurrence(newOccurrence: any){
    if (newOccurrence.startDate && newOccurrence.endDate) {
      newOccurrence.startDate.setHours(0, 0, 0, 0);
      newOccurrence.endDate.setHours(23, 59, 59, 0);

      if (newOccurrence.startHour) {
        const startHourSplitted = String(moment(newOccurrence.startHour).format('HH:mm')).split(':');
        newOccurrence.startDate.setHours(
          (parseInt(startHourSplitted[0])),
          parseInt(startHourSplitted[1])
        );
      }
      if (newOccurrence.endHour) {
        const endHourSplitted = String(moment(newOccurrence.endHour).format('HH:mm')).split(':');
        newOccurrence.endDate.setHours(
          (parseInt(endHourSplitted[0])),
          parseInt(endHourSplitted[1])
        );
      }
      delete newOccurrence.startHour;
      delete newOccurrence.endHour;
      newOccurrence.startDate = moment(newOccurrence.startDate).format('DD/MM/YYYY HH:mm:ss');
      newOccurrence.endDate = moment(newOccurrence.endDate).format('DD/MM/YYYY HH:mm:ss');
    }

    if (newOccurrence.protocolDate) {
      newOccurrence.protocolDate.setHours(0, 0, 0, 0);
      if (newOccurrence.protocolHour) {
        const protocolHourSplitted = String(moment(newOccurrence.protocolHour).format('HH:mm')).split(':');
        newOccurrence.protocolDate.setHours(
          (parseInt(protocolHourSplitted[0])),
          parseInt(protocolHourSplitted[1])
        );
      }
      delete newOccurrence.protocolHour;
      newOccurrence.protocolDate = moment(newOccurrence.protocolDate).format('DD/MM/YYYY HH:mm:ss');
    }
    const url: string = this.getEndpoint('OCCURRENCE');
    return this.postData(url, newOccurrence);
  }

  getOperation(groupsId: any): Observable<Array<any>>{
    let url: string = this.getEndpoint('OCCURRENCE_OPERATIONS');
    if (groupsId) {
      if(typeof groupsId === "number") {
        url += '?groupId=' + groupsId
      } else {
       url += '?groupId=' + groupsId.join(',');
      }
    }
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }
}
