import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AlertService} from 'src/app/service/alert.service';
import {AlertReportEvent} from 'src/app/models/alert-report.model';
import {DeviceEventType} from 'src/app/shared/enums/device-event-type.enum';
import {AddressDevice} from "../../../../../models/device.model";
import {finalize, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'real-time-alert-event-detail',
  templateUrl: './alert-event-detail.component.html',
  styleUrls: ['./alert-event-detail.component.scss']
})
export class AlertEventDetailComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() showDetails: boolean = false;
  @Output() showDetailsChange = new EventEmitter<boolean>();
  @Input() alertData!: AlertReportEvent;//TODO voltar com o tipo 'DeviceEventMap' quando a API estiver pronta
  @Output() alertDataChange = new EventEmitter<any>();
  fullscreenImg: boolean = false;
  imgUrl: string = '';
  detailsData: any = {}
  openDetails = false;
  showSpinner = false;

  constructor(
    private alertService: AlertService,
  ) {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngOnInit(): void {
  }

  testeConsole() {
  }

  cleanDialog() {
    this.detailsData = [];
    this.showDetailsChange.emit(false);
  }

  fullscrennImage(url: any) {
    this.fullscreenImg = true;
    this.imgUrl = url;
  }

  getAxlesPbt(axles: any[]): string {
    let ret = '';
    let i = 1;
    axles.forEach((val) => {
      ret += ('Eixo ' + i + ': ' + val.weight_axle + ' kg, ');
      i++;
    })
    ret = ret.substring(0, ret.length - 2);
    return ret;
  }

  isWheighing(t: DeviceEventType): boolean {
    return t === DeviceEventType.WEIGHING;
  }

  loadEvent() {
    this.showSpinner = true;
    this.alertService.getAlertEventDetails(this.alertData.event, this.alertData.eventType)
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.showSpinner = false),
      )
      .subscribe({
        next: (detailsData) => this.detailsData = detailsData,
      })
  }
}

export class DeviceCustom {
  name!: string;
  category!: string;
  latitude!: string;
  longitude!: string;
  originId!: number;
  serialNumber!: string;
  addresses!: AddressDevice;
  id!: number;

}

export class Status {
  id!: number;
  lastComunication!: string;
  online!: boolean
}
