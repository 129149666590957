import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'device-graph',
  templateUrl: './device-graph.component.html',
  styleUrls: ['./device-graph.component.scss']
})
export class DeviceGraphComponent {

  @Input() graphShow: boolean = false;
  @Input() dataGraphHistory: any = []
  @Input() dataGraphDataSet: any = []
  @Input() deviceId: number = 0;
  @Input() labels: string[] = [];
  @Input() isSensor: boolean = false;
  @Output() graphShowChange = new EventEmitter<boolean>();

  data: any = [];

  options: any;

  constructor() {
    this.options = {
      plugins: {
          legend: {
              labels: {
                  color: '#000000'
              }
          }
      },
      scales: {
          x: {
              ticks: {
                  color: '#000000'
              },
              grid: {
                  color: 'rgba(0,0,0,0.2)'
              }
          },
          y: {
              ticks: {
                  color: '#000000'
              },
              grid: {
                  color: 'rgba(0,0,0,0.2)'
              }
          }
      }
  };
  }

  ngOnInit(): void {  }

  closeEventsWindow() {
    this.graphShow = false;
    this.cleanDialog();
  }

  extractDataHistory(key: string) {
    const result = [];
    for (let i = 0; i < this.dataGraphHistory.length; i++) {
      result.push(this.dataGraphHistory[i][key]);
    }
    return result;
  }


  extractDataSerie(key: string){
    let result = [];
    result = this.dataGraphDataSet[key];
    return result;
  }

  createGraph() {
    this.data = {
      labels: [],
      datasets: []
    }
    if(!this.isSensor) {
      for(let i = 0; i < this.dataGraphHistory.length; i++){
        this.data.labels.push('')
      }
      const dataSerieFlow = this.extractDataHistory('totalFlows');
      const dataSerieInfraction = this.extractDataHistory('totalViolations');
      const dataSerieWeighing = this.extractDataHistory('totalWeightings');
      this.data.datasets.push(
        {
          label: 'Passagens',
          data: dataSerieFlow
        }
      )
      this.data.datasets.push(
        {
          label: 'Infrações',
          data: dataSerieInfraction
        }
      )
      this.data.datasets.push(
        {
          label: 'Pesagens',
          data: dataSerieWeighing
        }
      )
    } else {
      const dataSerieFaixa1 = this.extractDataSerie('dataSerieFaixa1');
      const dataSerieFaixa2 = this.extractDataSerie('dataSerieFaixa2');
      for(let i = 0; i < dataSerieFaixa1.length; i++){
        this.data.labels.push('')
      }
      for(let i = 0; i < dataSerieFaixa2.length; i++){
        this.data.labels.push('')
      }
      this.data.datasets.push(
        {
          label: 'Faixa 1',
          data: dataSerieFaixa1
        }
      )
      this.data.datasets.push(
        {
          label: 'Faixa 2',
          data: dataSerieFaixa2
        }
      )
    }

    }

  cleanDialog() {
    this.dataGraphDataSet = null
    this.dataGraphHistory = null
    this.graphShow = false;
    this.graphShowChange.emit(false);
  }

}
