<p-sidebar
  [showCloseIcon]="false"
  styleClass="side-bar"
  [(visible)]="visibleSidebar"
  [baseZIndex]="10000"
>

  <p-tieredMenu
    [style]="{ top: '-20px' }"
    styleClass="relative w-full h-full p-0 side-menu"
    [model]="menuItensSideMenu"
  ></p-tieredMenu>

  <ng-template pTemplate="footer">{{version}}</ng-template>

  <!--  <ng-template pTemplate="header">-->
  <!--    <img width="50%" src="/assets/images/logo.png"-->
  <!--         style="cursor: pointer;"-->
  <!--         [routerLink]="routerEnum.REAL_TIME"-->
  <!--         (click)="this.visibleSidebar = false;"-->
  <!--         alt="Logo da empresa"/>-->
  <!--  </ng-template>-->
  <!--  <div class="flex flex-column h-full">-->
  <!--    <div class="overflow-y-auto" style="height: calc(100vh - 165px) !important;">-->
  <!--      <ul class="list-none p-2 m-0">-->
  <!--        <li *ngIf="showMenu">-->
  <!--          <ul class="list-none p-0 m-0 overflow-hidden">-->
  <!--            <ng-container *ngFor="let menuItem of menuItensSideMenu">-->
  <!--              <li *ngIf="menuItem.visible">-->
  <!--                <a pRipple-->
  <!--                   *ngIf="!menuItem?.items"-->
  <!--                   [routerLink]="menuItem.routerLink"-->
  <!--                   (click)="callCommand(menuItem)"-->
  <!--                   style="text-decoration: none;"-->
  <!--                   class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">-->
  <!--                  <i [class]="menuItem.icon + ' mr-2'"></i>-->
  <!--                  <span class="font-medium">{{menuItem.label}}</span>-->
  <!--                </a>-->

  <!--                <a pRipple *ngIf="menuItem?.items"-->
  <!--                   (click)="menuItem.expanded = !menuItem.expanded;"-->
  <!--                   [ngClass]="{'expanded-menu-item': menuItem.expanded}"-->
  <!--                   class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors"-->
  <!--                   pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"-->
  <!--                   [hideOnOutsideClick]="true"-->
  <!--                   leaveActiveClass="slideup">-->
  <!--                  <i [class]="menuItem.icon + ' mr-2'"></i>-->
  <!--                  <span class="font-medium">{{menuItem.label}}</span>-->
  <!--                  <i class="pi ml-auto"-->
  <!--                     [ngClass]="{'pi-chevron-down': !menuItem.expanded, 'pi-chevron-up': menuItem.expanded}"></i>-->
  <!--                </a>-->
  <!--                <ul *ngIf="menuItem?.items"-->
  <!--                    [ngClass]="{'expanded-menu-item': menuItem.expanded}"-->
  <!--                    class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">-->
  <!--                  <li *ngFor="let menuChild of menuItem.items">-->
  <!--                    <a pRipple-->
  <!--                       [routerLink]="menuChild.routerLink"-->
  <!--                       (click)="callCommand(menuChild)"-->
  <!--                       style="text-decoration: none;"-->
  <!--                       class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors">-->
  <!--                      <i [class]="menuChild.icon + ' mr-2'"></i>-->
  <!--                      <span class="font-medium">{{menuChild.label}}</span>-->
  <!--                    </a>-->
  <!--                  </li>-->
  <!--                </ul>-->
  <!--              </li>-->
  <!--            </ng-container>-->
  <!--          </ul>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--    <div class="mt-auto">-->
  <!--      <hr class="mb-3 mx-3 border-top-1 border-none surface-border"/>-->
  <!--      <a pRipple-->
  <!--         [pTooltip]="tooltipCopiarVersao"-->
  <!--         (click)="copiarVersao()"-->
  <!--         tooltipPosition="top"-->
  <!--         class="m-3 flex align-items-center cursor-pointer pl-3 border-round text-700">-->
  <!--        <span class="font-medium">{{version}}</span>-->
  <!--        <i class="pi pi-clone ml-2"></i>-->
  <!--      </a>-->
  <!--    </div>-->
  <!--  </div>-->
</p-sidebar>
<div class="flex justify-content-between layout-topbar">
  <div class="flex justify-content-between align-items-center">
    <img
      [style]="{ height: '3rem' }"
      src="/assets/images/logo-white.png"
      alt="logo"/>
    <p-button
      *ngIf="headerService.showHeader && !checkForceChangePassword()"
      icon="pi pi-bars"
      [rounded]="true"
      [text]="true"
      severity="secondary"
      class="ml-5"
      [style]="{ 'color': '#DADCDF' }"
      (onClick)="visibleSidebar = true"
    ></p-button>
    <img
      *ngIf="showNotixPixel" alt="NOTIX"
      ngSrc="https://notix.io/mt.gif?user={{ email }}&app_id={{ notixAppId }}"
      width="1"
      height="1"
    />
  </div>
  <div class="flex align-items-center" [style.color]="'#D9E3F2'" #divOverlayMenu>

    <i class="pi pi-spin pi-spinner text-2xl"
       style="margin-right: 1rem;"
       *ngIf="!showReservadasIcon && headerService.showHeader && userService.hasRoles([role.ADMIN_FULL, role.GESTOR, role.PROCESSAMENTO_TRIAGEM])"
    ></i>
    <i class="pi pi-bell text-2xl" severity="warning"
       pBadge [value]="(triagemService | totalReservadas) + ''"
       [badgeDisabled]="(triagemService | totalReservadas) === 0"
       style="cursor: pointer; margin-right: 1rem;"
       [pTooltip]="(triagemService | totalReservadas) > 0 ? 'Você possui imagens reservadas para processar' : 'Você não possui imagens reservadas para processar'"
       *ngIf="showReservadasIcon && headerService.showHeader && userService.hasRoles([role.ADMIN_FULL, role.GESTOR, role.PROCESSAMENTO_TRIAGEM])"
       (click)="setMenuOptionsTriagem(); opMenu.toggle($event);"></i>
    <p-button (onClick)="menuOptions = userMenuActions; opMenu.toggle($event);" iconPos="right"
              [icon]="menuOptions === userMenuActions && menu.visible ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              [text]="true" size="small" [label]="userName | titlecase" [style]="{ 'color': '#DADCDF' }">
      <p-avatar icon="pi pi-user" styleClass="mr-2" shape="circle"
                [style]="{'background-color':'rgb(30 118 189)', 'color': '#ffffff'}"></p-avatar>
    </p-button>
  </div>
</div>
<p-toast key="toastMenuHeader"></p-toast>
<p-confirmDialog key="headerMenuConfirmDialog"></p-confirmDialog>
<p-overlayPanel #opMenu styleClass="overlay__menu-header">
  <p-menu #menu [model]="menuOptions"
          styleClass="menu__reservadas"
          id="menu_header"></p-menu>
</p-overlayPanel>
