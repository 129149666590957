import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MessagesModule} from 'primeng/messages';

import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {CommonModule} from "@angular/common";
import {PagesModule} from "./pages/pages.module";
import {DragToSelectModule} from "ngx-drag-to-select";

/**
 * DO NOT REMOVE!!!
 * context-filter-polyfill é UTILIZADO PELO SAFARI
 */
import 'context-filter-polyfill';
import {ToastModule} from "primeng/toast";
import {BlockUIModule} from "primeng/blockui";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import { ThemeModule } from './theme/theme.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DragToSelectModule.forRoot(),
    MessagesModule,
    CoreModule,
    SharedModule,
    PagesModule,
    ToastModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ThemeModule
  ],
  providers: [
    SharedModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
