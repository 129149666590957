<div class="card-container">
  <div class="card shadow">
    <img
      *ngIf="hasVideo" 
      src="{{ url }}"
      onerror="this.src='https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png'"
    />
    <p
      style="
        position: absolute;
        z-index: 1;
        color: white;
        background-color: black;
        font-size: larger;
      "
    >
      {{ name }}
    </p>
  </div>
</div>
