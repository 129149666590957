import { Component } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Grupo } from 'src/app/models/grupo.model';
import { GruposService } from 'src/app/service/grupos.service';
import { DataTablePage } from 'src/app/models/data-table-page.model';
import { UserService } from 'src/app/service/user.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys.enum';
import { OccurrenceService } from 'src/app/service/occurrence.service';
import { MotiveOccurrence } from 'src/app/models/occurrence.model';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-motive-occurrence',
  templateUrl: './motive-occurrence.component.html',
  styleUrls: ['./motive-occurrence.component.scss']
})
export class MotiveOccurrenceComponent {

  private _destroy$: Subject<void> = new Subject<void>();

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  page!: DataTablePage;
  loading: boolean = false;
  first: number = 0;
  totalRecords: number = 0;

  currentFilter: any = [];

  grupos!: Array<Grupo>;

  showCreateDialog = false;

  motiveOccurrenceData: MotiveOccurrence[] = [];

  motiveOccurrenceFilter: MotiveOccurrence = new MotiveOccurrence();
  newMotiveOccurrence: MotiveOccurrence = new MotiveOccurrence();

  buttonLoading: boolean = false;

  motiveOccurrenceType = [
    {name: 'Técnico', value: 'technical'},
    {name: 'Não técnico', value: 'nonTechnical'},
  ];

  editMode: boolean = false;

  notFound: boolean = false;
  tituloNotFound: string = 'Nenhum motivo ocorrência encontrado';
  descricaoNotFound: string = 'Desculpe, não encontramos nenhum motivo ocorrência para os filtros informados';


  constructor(
    private breadCrumb: BreadcrumbService,
    private occurrenceService: OccurrenceService,
    private gruposService: GruposService,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.page = new DataTablePage();
    this.page.size = 10;
    this.page.pageNumber = 0;
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Configurações', icon: 'pi pi-sliders-v'},
      {label: 'Motivo Ocorrência', icon: 'pi pi-arrow-circle-right'}
    ]);
    this.getGroups();
  }

  getGroups(): void {
    this.gruposService.getGrupos()
      .pipe(takeUntil(this._destroy$))
      .subscribe(partGroupAPI => this.grupos = partGroupAPI);
  }

  showDialogCreate() {
    this.newMotiveOccurrence = new MotiveOccurrence();
    this.editMode = false;
    this.showCreateDialog = true;
  }

  showEditDialog(item: any) {
    this.editMode = true;
    this.showCreateDialog = true;
    this.newMotiveOccurrence = JSON.parse(JSON.stringify(item));
  }

  getMotiveOccurrence(pageEvent: any, filters: any) {
    this.notFound = false;
    this.loading = true;
    this.page.pageNumber = pageEvent.first / this.page.size;
    if (pageEvent === false) {
      this.page.pageNumber = 0;
      this.first = 0;
    }
    if(filters === false){
      filters = [];
    }
    this.currentFilter = {...filters};
    if(!filters.groupId){
      let userGroupsId: any = [];
      let user = JSON.parse(this.userService.decrypt(StorageKeys.USER));
      user.userGroups.forEach(async (e: any, index: number) => {
        userGroupsId.push(e.groupId);
      });
      this.currentFilter.grupos = userGroupsId.join(',');
    } else {
      this.currentFilter.grupos = [filters.groupId]
    }
    this.occurrenceService.getMotiveOccurrence(this.page, this.currentFilter)
      .pipe(takeUntil(this._destroy$),
      finalize(() => {
        this.loading = false;
      }),
      )
      .subscribe({
        next: (data: any) => {
          this.totalRecords = this.page.totalElements;
          this.motiveOccurrenceData = data.content;
          if (!data.content || data.content.length === 0) {
            this.notFound = true;
            this.tituloNotFound = 'Nenhum motivo ocorrência encontrado';
            this.descricaoNotFound = 'Desculpe, não encontramos nenhum motivo ocorrência para os filtros informados';
          }
        }
      })
  }

  formatTechnical(res: string) {
    const find = this.motiveOccurrenceType.find(e => e.value == res);
    if(find) {
      return find.name
    } else {
      return '-'
    }
  }

  updateMotiveOccurrence() {
    this.buttonLoading = true;
    if(!(this.newMotiveOccurrence.serviceTime != null && this.newMotiveOccurrence.groupId != null && this.newMotiveOccurrence.type != null)) return;
    this.occurrenceService.updateMotiveOccurrence(this.newMotiveOccurrence)
    .pipe(takeUntil(this._destroy$),
    finalize(() => {
      this.buttonLoading = false;
    }),
    )
    .subscribe({
      next: (res) => {
        this.showCreateDialog = false;
        this.newMotiveOccurrence = new MotiveOccurrence();
        this.messageService.add({
          key: 'toastSuccess',
          severity: 'success',
          detail: 'Motivo ocorrência atualizado com sucesso!',
        });
        this.getMotiveOccurrence(false, this.currentFilter);
        //this.motiveOccurrenceData.push(res);
      },
      error: (error) => {
        this.messageService.add({
          key: 'toastError',
          severity: 'error',
          summary: 'Error',
          detail: 'Erro ao tentar atualizar o motivo ocorrência',
        });
        this.newMotiveOccurrence = new MotiveOccurrence();
      }
    })
  }

  createMotiveOccurrence(){
    this.buttonLoading = true;
    if(!(this.newMotiveOccurrence.description != null && this.newMotiveOccurrence.serviceTime != null && this.newMotiveOccurrence.groupId != null && this.newMotiveOccurrence.type != null)) return;

    this.occurrenceService.saveMotiveOccurrence(this.newMotiveOccurrence)
    .pipe(takeUntil(this._destroy$),
    finalize(() => {
      this.buttonLoading = false;
    }),
    )
      .subscribe({
        next: (res) => {
          this.showCreateDialog = false;
          this.newMotiveOccurrence = new MotiveOccurrence();
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Motivo ocorrência salvo com sucesso!',
          });
          // this.motiveOccurrenceData.push(res);
          this.getMotiveOccurrence(false, this.currentFilter)
        },
        error: (error) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar salvar motivo ocorrência',
          });
          this.newMotiveOccurrence = new MotiveOccurrence();
        }
      })
  }

  clearFilter() {
    this.motiveOccurrenceFilter = new MotiveOccurrence();
  }

  deleteMotiveOccurrence(occurrenceId: number) {
    this.occurrenceService.deleteMotiveOccurrence(occurrenceId)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Motivo de ocorrência excluído com sucesso!',
          });
          this.getMotiveOccurrence(false, this.currentFilter)
        },
        error: (e) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar excluir motivo de ocorrência',
          });
        },
      })
  }

  openDeleteConfirm(id: number, desc: string) {
    this.confirmationService.confirm({
      message: 'Você realmente deseja deletar o motivo de ocorrência: ' + desc,
      header: 'Deletar motivo de ocorrência',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => this.deleteMotiveOccurrence(id),
      reject: () => { }
    })
  }


}
