<p-dialog
  [(visible)]="isVisible"
  position="topleft"
  [closable]="false"
  [style]="{
    width: '220px',
    height: '60px',
    'border-radius': '8px',
    'margin-top': '6rem'
  }"
  [showHeader]="false"
>
  <!-- <div class="flex align-items-center mb-2">
    <p-inputSwitch (onChange)="changeWim()"></p-inputSwitch>
    <label class="ml-2">Apenas WIM</label>
  </div> -->

  <div class="flex align-items-center mb-2">
    <p-inputSwitch (onChange)="changeLayerTraffic()"></p-inputSwitch>
    <label class="ml-2">Mapa de tráfego</label>
  </div>

  <!-- <div class="flex align-items-center">
    <p-inputSwitch (onChange)="changeLayerHeat()"></p-inputSwitch>
    <label class="ml-2">Mapa de calor</label>
  </div> -->

</p-dialog>
