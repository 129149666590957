import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'real-time-data-layer',
  templateUrl: './data-layer.component.html',
  styleUrls: ['./data-layer.component.scss']
})
export class DataLayerComponent {

  isVisible = true;
  layerTraffic = false;
  layerHeat = false;
  layerTaxa = false;
  layerCaminhao = false;
  onlyWim = false;
  @Output() layerTaxaChange = new EventEmitter<boolean>();
  @Output() layerTrafficChange = new EventEmitter<boolean>();
  @Output() layerHeatChange = new EventEmitter<boolean>();
  @Output() layerCaminhaoChange = new EventEmitter<boolean>();
  @Output() onlyWimChange = new EventEmitter<boolean>();


	deviceGroups = [
		{
			id:1,
			name: 'Group 1'
		},
		{
			id:2,
			name: 'Group 2'
		},
		{
			id:3,
			name: 'Group 3'
		},
		{
			id:4,
			name: 'Group 4'
		},
	]

  constructor() { }

  ngOnInit(): void {

  }

  changeWim(){
    this.onlyWim = !this.onlyWim;
    this.onlyWimChange.emit(this.onlyWim);
  }

  changeLayerTraffic() {
    this.layerTraffic = !this.layerTraffic;
    this.layerTrafficChange.emit(this.layerTraffic);
  }

  changeLayerHeat() {
    this.layerHeat = !this.layerHeat;
    this.layerHeatChange.emit(this.layerHeat);
  }

  changeLayerTaxa() {
    this.layerTaxa = !this.layerTaxa;
    this.layerTaxaChange.emit(this.layerTaxa);
  }

  changeLayerCaminhao() {
    this.layerCaminhao = !this.layerCaminhao;
    this.layerCaminhaoChange.emit(this.layerCaminhao);
  }

}
