import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Device } from '../../models/device.model';
import { DeviceEvent } from '../../models/device-event.model';
import { finalize, from, interval, Subject, Subscription, takeUntil, zip, concatMap, tap, Observable, catchError, throwError, map } from 'rxjs';
import { DeviceService } from '../../service/devices.service';
import { EventService } from '../../service/event.service';
import { MapsService } from '../../service/maps.service';
import { RealTimeService } from '../../service/real-time.service';
import { AppEvents } from '../../shared/enums/app-events.enum';
import { Cluster, MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import { StorageKeys } from '../../shared/enums/storage-keys.enum';
import { MessageService } from 'primeng/api';
import { BreadcrumbService } from "../../service/breadcrumb.service";
import { AlertService } from 'src/app/service/alert.service';

@Component({
  selector: 'app-real-time',
  templateUrl: './real-time.component.html',
  styleUrls: ['./real-time.component.scss']
})
export class RealTimeComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  showInfoWindow = false;
  infoWindowData: any[] = [];
  isOpenLayers = false;// True = openlayer, false = google maps, alterar types/geojson para o "devDependencies"
  devices!: Array<Device>;
  devicesFilter: Device[] = [];
  devicesMarker: any[] = []
  webMap!: google.maps.Map;
  heatmap!: google.maps.visualization.HeatmapLayer;
  truckHeatMap!: google.maps.visualization.HeatmapLayer;
  cluster!: MarkerClusterer;
  markers: Array<google.maps.Marker> = [];
  idInterval: any;

  retentionPoints: any;
  loadingRetentionPoints!: boolean;
  retentionPointsPage: number = 0;
  loading = false;

  private trafficLayer!: google.maps.TrafficLayer;
  private trafficLayerSubscription!: Subscription;
  private bounds: any;

  devicesPoints: any[] = [];

  onlyWim = false;
  refreshMarkersIntervalId = 0;

  dataMetrics: any;

  @ViewChild('card', { read: ElementRef }) public card!: ElementRef<any>;

  constructor(private deviceService: DeviceService,
    private eventService: EventService,
    private mapsService: MapsService,
    private realTimeService: RealTimeService,
    private alertService: AlertService,
    private messageService: MessageService,
    private breadCrumb: BreadcrumbService) {
  }



  ngOnInit() {
    this.breadCrumb.next([
      { label: 'Monitoramento', icon: 'pi pi-fw pi-desktop' },
      { label: 'Tempo Real', icon: 'pi pi-fw pi-clock' }
    ]);
    this.getDevices().pipe(
      tap(async () => {
        await this.changeLayerTaxa(false); // generate map layers
      })
    ).subscribe()
    this.getRetentionPoints();
    this.trafficLayerSubscription = this.eventService.subscribe(AppEvents.UPDATE_MAPS_TRAFFIC_LAYER, (trafficLayerEnabled: boolean) => {
      if (this.webMap) {
        this.updateTrafficLayer(trafficLayerEnabled);
      }
    });
    this.refreshMarkers();
  }

  verifyDeviceNavigate(){
    this.alertService.getDeviceToNavigate().pipe(
      takeUntil(this._destroy$),
      tap((r) => {
        if(r != null) {
          const item: any = this.devices.find((e:any) => e.deviceId === r);
          if(item !== undefined){
            this.toLocation({lat: Number(item.latitude), lng: Number(item.longitude) });
          }
          this.alertService.setDeviceToNavigate(null);
        }
      })
    ).subscribe()

  }

  toLocation(location: any) {
    this.webMap.setCenter(location);
    this.webMap.setZoom(20);
  }

  updateData($event: any) {
    this.dataMetrics = $event;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    if (this.trafficLayerSubscription) {
      this.trafficLayerSubscription.unsubscribe();
      this.eventService.destroy(AppEvents.UPDATE_MAPS_TRAFFIC_LAYER);
    }
    if (this.idInterval) {
      clearInterval(this.idInterval);
    }
  }

  setInfoWindowData(event: any) {
    this.infoWindowData = [];
    this.infoWindowData.push(event);
  }

  // MAPA
  // ---------------------

  getDevices(): Observable<any> {
    return this.deviceService.getDeviceMetrics().pipe(
      takeUntil(this._destroy$),
      catchError((err: any) => {
        this.errorAlert(err.message)
        return throwError(err)
      }),
      tap((response) => {
        if (response) {
          this.devices = response;
          this.dataMetrics = response;
        }
      })
    )
  }

  private loadWebMap(): void {
    from(this.mapsService.loadGoogleMapsJsApi())
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (mapLoaded) => {
          if (!mapLoaded) {
            this.messageService.add({
              key: 'toastError',
              severity: 'error',
              summary: 'Error',
              detail: 'Não foi possível carregar o mapa'
            });
            return;
          }
          this.setMarkers(this.onlyWim);//preserva o filtro wim
          this.mapConfig();
          this.loadHeatMap();
          this.loadTruckHeatMap();
        },
        error: () => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Não foi possível carregar o mapa'
          });
        }
      })
  }

  loadHeatMap() {
    let pointsCompare: any[] = [];
    for (let i = 0; i < this.devicesFilter.length; i++) {
      const devices: any = this.devicesFilter.filter(res => res.latitude === this.devicesFilter[i].latitude);
      if (devices.length === 1) {
        this.devicesPoints.push({
          location: new google.maps.LatLng(Number(devices[0].latitude), Number(devices[0].longitude)),
          weight: Number(devices[0].taxaOcupacao)
        });
      } else {
        if (!pointsCompare.find(res => res.latitude === devices[0].latitude)) {
          pointsCompare.push(devices[0]);
          this.devicesPoints.push({
            location: new google.maps.LatLng(Number(devices[0].latitude), Number(devices[0].longitude)),
            weight: Number(devices[0].taxaOcupacao)
          });
        }
      }
    }
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.devicesPoints,
      maxIntensity: 10,
    });
    this.heatmap.setMap(null);
    this.heatmap.set("radius", this.heatmap.get("radius") ? null : 30);
  }

  loadTruckHeatMap() {
    this.deviceService.getOccupancyTruck()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (truckOccupancy) => {
          const truckMap: any[] = [];
          for (let i = 0; i < truckOccupancy.length; i++) {
            truckMap.push({
              location: new google.maps.LatLng(Number(truckOccupancy[i].latitude), Number(truckOccupancy[i].longitude)),
              weight: Number(truckOccupancy[i].taxaDeCaminhao)
            });
          }
          this.truckHeatMap = new google.maps.visualization.HeatmapLayer({
            data: truckMap,
            maxIntensity: 10
          });
          this.truckHeatMap.setMap(null);
          this.truckHeatMap.set("radius", this.heatmap.get("radius") ? null : 30);
        }
      });
  }

  setMarkers(onlyWim = false) {
    this.markers.splice(0, this.markers.length);
    this.bounds = new google.maps.LatLngBounds();
    let _devices = this.devices;
    if (onlyWim)
      _devices = this.devices.filter(d => d.category === 'WIM');
    _devices.forEach((d: any) => {
      if (d.latitude && d.longitude
        && Number(d.latitude) < 10 && Number(d.latitude) > -53
        && Number(d.longitude) < -29 && Number(d.longitude) > -80) {
        this.devicesFilter.push(d);
        const marker = this.createMarker(d);
        this.markers.push(marker);
        this.bounds.extend(marker.getPosition());
      }
    });
  }

  createMarker(d: any) {
        // 0 - off
    // 1 - amarelo - sem passagem
    // 2 - on
    // 3 - suspenso
    // outro - cinza escuro
    const marker: google.maps.Marker = new google.maps.Marker({
      position: {
        lat: Number(d.latitude),
        lng: Number(d.longitude)
      },
      // optimized: false,
      // icon: "/assets/images/pin_radar.png"
      icon:
      d.statusOnline == 0 ? '/assets/images/pin1_red.png' :
      d.statusOnline == 1 ? '/assets/images/pin1_yellow.png' :
      d.statusOnline == 2 ? '/assets/images/pin1_green.png' :
      d.statusOnline == 3 ? '/assets/images/pin1_orange.png'
      : '/assets/images/pin1_gray.png'

    });
    marker['device'] = d;
    marker.addListener("click", () => {
      this.infoWindowData = [];
      this.loading = true;
      this.deviceService.getDeviceMetricsbyId(d.deviceId)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (metricsById) => {
            this.devicesMarker = [d];
            this.infoWindowData.push(metricsById);
            this.loading = false;
            this.showInfoWindow = true;
          },
          error: (err) => this.messageService.add({ key: 'toastError', severity: 'error', summary: 'Error', detail: err })
        })
    });
    return marker;
  }

  mapConfig() {
    const mapEle: any = document.getElementById("map") as HTMLElement;
    this.webMap = new google.maps.Map(mapEle, {
      center: {
        lat: -25.4321587,
        lng: -49.2796673
      },
      zoom: 15,
      zoomControl: true,
      maxZoom: 16,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false
    });
    this.loadTrafficLayer();
    google.maps.event.addListenerOnce(this.webMap, 'tilesloaded', () => {
      // A função dentro deste bloco será executada após o mapa ser totalmente renderizado
      this.verifyDeviceNavigate();
    });

  }

  loadTrafficLayer() {
    this.trafficLayer = new google.maps.TrafficLayer();
    this.trafficLayer.setMap(null);

    setTimeout(() => {
      this.webMap.fitBounds(this.bounds);
      this.addCluster();
      const trafficLayerEnabled: boolean = localStorage.getItem(StorageKeys.TRAFFIC_LAYER_ENABLED) != null;
      this.updateTrafficLayer(trafficLayerEnabled);
    });
  }

  changeLayerTraffic(event: any) {
    if (event) {
      this.trafficLayer.setMap(this.trafficLayer.getMap() ? null : this.webMap);
    } else {
      this.trafficLayer?.setMap(null);
    }
  }

  changeLayerHeat(event: any) {
    if (event) {
      this.changeLayerCaminhao(false);
      this.heatmap.setMap(this.heatmap.getMap() ? null : this.webMap);
    } else {
      this.heatmap?.setMap(null);
    }
  }

  changeLayerCaminhao(event: any) {
    if (event) {
      this.changeLayerHeat(false);
      this.truckHeatMap.setMap(this.truckHeatMap.getMap() ? null : this.webMap);
    } else {
      this.truckHeatMap?.setMap(null);
    }
  }

  changeLayerTaxa(event: any) {
    this.markers = [];
    this.isOpenLayers = event;
    if (!this.isOpenLayers) {
      const googleMapsRemove = document.getElementById('map');
      googleMapsRemove?.remove();
      const googleMapsDiv = document.createElement('div');
      const mainDiv = document.getElementById('mainDiv');
      googleMapsDiv.setAttribute('id', 'map');
      googleMapsDiv.setAttribute('class', 'map-content');
      // googleMapsDiv.style.width = '100vw';
      googleMapsDiv.style.height = '89vh';
      mainDiv?.appendChild(googleMapsDiv);
      this.loadWebMap();
    } else {
      const googleMapsRemove = document.getElementById('map');
      googleMapsRemove?.remove();
    }
  }

  getIconByMarkers(markers: any): { url: string } {
    // 0 - off
    // 1 - amarelo - sem passagem
    // 2 - on
    // 3 - suspenso
    // outro - cinza escuro
    let iconSrc = { url: '/assets/images/pin_green.png' }; //all markers are online
    const offlineMarkers = markers.filter((m: any) => m.device.statusOnline === 0);
    const delayedMarkers = markers.filter((m: any) => m.device.statusOnline === 1);
    const onlineMarkers = markers.filter((m: any) => m.device.statusOnline === 2);
    const suspendedMarkers = markers.filter((m: any) => m.device.statusOnline === 3);
    const otherMarkers = markers.filter((m: any) =>
      m.device.statusOnline != 0
      && m.device.statusOnline != 1
      && m.device.statusOnline != 2
      && m.device.statusOnline != 3
    );

    if (otherMarkers.length > 0)
      iconSrc.url = "/assets/images/pin_gray.png";
    if (delayedMarkers.length > 0)
      iconSrc.url = "/assets/images/pin_yellow.png";
    if (suspendedMarkers.length > 0)
      iconSrc.url = "/assets/images/pin_orange.png";
   if (offlineMarkers.length > 0)
      iconSrc.url = "/assets/images/pin_red.png";

    return iconSrc;
  }

  onClusterClick(markers: any) {
    if (Number(this.webMap.getZoom()) >= 16) {
      this.loading = true;
      this.devicesMarker = markers?.map((res: any) => res.device);
      const httpRequests = [];

      this.infoWindowData = [];
      let devicesName: any = []
      for (const d of this.devicesMarker) {
        if (!devicesName.some((item: any) => item === d.deviceName)) {
          httpRequests.push(this.deviceService.getDeviceMetricsbyId(d.deviceId));
          devicesName.push(d.deviceName);
        }
      }
      zip(httpRequests)
        .pipe(
          takeUntil(this._destroy$),
          finalize(() => this.loading = false),
        )
        .subscribe({
          next: (metricsByIdList) => {
            metricsByIdList.forEach(metricsById => this.infoWindowData.push(metricsById));
            this.showInfoWindow = true;
          },
          error: (err) => this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: err
          }),
        });
    } else {
    }
  }

  private addCluster(): void {
    const renderer: Renderer = {
      render: ({ count, position, markers }: Cluster) => {
        const marker = new google.maps.Marker({
          position,
          icon: this.getIconByMarkers(markers),
          label: {
            text: String(count),
            className: 'cluster-number-pin',
          }
        });
        marker.addListener("click", () => {
          this.onClusterClick(markers);
        });
        return marker;
      }

    };

    this.cluster = new MarkerClusterer({
      map: this.webMap,
      markers: this.markers,
      renderer,
    });
  }

  private updateTrafficLayer(trafficLayerEnabled: boolean): void {
    if (!this.trafficLayer) {
      this.trafficLayer = new google.maps.TrafficLayer();
    }
    if (trafficLayerEnabled) {
      this.trafficLayer.setMap(this.webMap);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

  // Gráficos e tabelas
  // ---------------------

  getRetentionPoints(): void {
    this.retentionPointsPage++;
    this.loadingRetentionPoints = true;
    this.realTimeService.obterPontosRetencao()
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.loadingRetentionPoints = false),
      )
      .subscribe({
        next: (retentionPoints) => this.retentionPoints = retentionPoints,
        error: (error) => {
          this.retentionPointsPage--;
          this.errorAlert(error.message);
        },
      });
  }

  errorAlert(error: any) {
    this.messageService.add({ key: 'toastError', severity: 'error', summary: 'Error', detail: error });
  }

  filterOnlyWim(event: any) {//refact: staticly set wim markers
    this.onlyWim = event;
    this.cluster.clearMarkers();
    if (event) {
      this.setMarkers(true);
    } else {
      this.setMarkers(false);
    }
    this.cluster.addMarkers(this.markers);
  }

  setMarkersByDevices(_devices: any) {
    this.markers = [];
    this.bounds = new google.maps.LatLngBounds();
    _devices.forEach((d: any) => {
      if (d.latitude && d.longitude
        && Number(d.latitude) < 10 && Number(d.latitude) > -53
        && Number(d.longitude) < -29 && Number(d.longitude) > -80) {
        this.devicesFilter.push(d);
        const marker = this.createMarker(d);
        this.markers.push(marker);
        this.bounds.extend(marker.getPosition());
      }
    });
  }

  private refreshMarkers() {
    interval(600000) // 600000
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.getDevices().pipe(
            tap((res) => {
              if (res.length == 0) return;
              this.cluster.clearMarkers();
              this.setMarkersByDevices(res);
              this.cluster.addMarkers(this.markers);
            })
          ).subscribe()
        }),
      )
      .subscribe();
  }

}
