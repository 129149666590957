import { Component, Input } from '@angular/core';

@Component({
  selector: 'row-expansion',
  templateUrl: './row-expansion.component.html',
  styleUrls: ['./row-expansion.component.scss']
})
export class RowExpansionComponent {

  @Input() device: any;

}
