import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, finalize, takeUntil, tap } from 'rxjs';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Grupo } from 'src/app/models/grupo.model';
import { GruposService } from 'src/app/service/grupos.service';
import { DataTablePage } from 'src/app/models/data-table-page.model';
import { UserService } from 'src/app/service/user.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys.enum';
import { OccurrenceService } from 'src/app/service/occurrence.service';
import { MotiveOccurrence } from 'src/app/models/occurrence.model';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@Component({
  selector: 'app-occurrence',
  templateUrl: './new-occurrence.component.html',
  styleUrls: ['./new-occurrence.component.scss']
})
export class NewOccurrenceComponent implements OnInit, OnDestroy {

  private _destroy$: Subject<void> = new Subject<void>();

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};



  grupos!: Array<Grupo>;
  motives: MotiveOccurrence[] = [];
  operations: any = [];

  newOccurrence: any = {};

  rangeDates: Date[] = [];

  isEdit: boolean = false;

  occurrenceType = [
    {name: 'Técnico', value: 'technical'},
    {name: 'Não técnico', value: 'nonTechnical'},
  ];

  buttonLoading: boolean = false;


  constructor(
    private breadCrumb: BreadcrumbService,
    private occurrenceService: OccurrenceService,
    private gruposService: GruposService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.getGroups();
    this.breadCrumb.next([
      {label: 'Configurações', icon: 'pi pi-sliders-v'},
      {label: 'Ocorrência', icon: 'pi pi-history'}
    ]);
  }

  loadEditMode() {
    this.occurrenceService.getOccurreceInUse().pipe(
      takeUntil(this._destroy$),
      tap((r) => {
        if(r != null) {
          this.newOccurrence = r
          this.isEdit = true;
          this.rangeDates = [];
          this.rangeDates[0] = new Date(this.newOccurrence.startDate);
          this.rangeDates[1] = new Date(this.newOccurrence.endDate);
          this.getMotives();
          this.getOperations();
          this.newOccurrence.startDate = new Date(this.newOccurrence.startDate);
          this.newOccurrence.startHour = new Date(this.newOccurrence.startDate);
          this.newOccurrence.endDate = new Date(this.newOccurrence.endDate);
          this.newOccurrence.endHour = new Date(this.newOccurrence.endDate);
          this.newOccurrence.protocolDate = new Date(this.newOccurrence.protocolDate);
          this.newOccurrence.protocolHour = new Date(this.newOccurrence.protocolDate);
        }
      })
    ).subscribe()
    this.breadCrumb.next([
      {label: 'Configurações', icon: 'pi pi-sliders-v'},
      {label: 'Ocorrência', icon: 'pi pi-history'},
      {label: this.isEdit? 'Editar Ocorrência': 'Nova Ocorrência', icon: this.isEdit? 'pi pi-pencil': 'pi pi-plus'}
    ]);
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete();
    this.occurrenceService.setOccurreceInUse(null)
  }

  groupChange(){
    this.newOccurrence.motiveOccurrence = undefined;
    this.newOccurrence.operation = undefined;
  }

  getGroups(): void {
    this.gruposService.getGrupos()
      .pipe(takeUntil(this._destroy$),
      tap((r) => {
        this.grupos = r
        this.loadEditMode();
      })
    ).subscribe()
  }

  getMotives(): void {
    if(this.newOccurrence.groupId) {
      this.occurrenceService.getAllMotiveOccurrence(this.newOccurrence.groupId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(r => this.motives = r);
    }
  }

  getOperations(): void {
    if(this.newOccurrence.groupId) {
      const findGroup = this.grupos.find((r: any) => r.id === this.newOccurrence.groupId);
      this.occurrenceService.getOperation(findGroup?.contractId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(r => this.operations = r);
    }
  }

  createOccurrence(){
    this.buttonLoading = true;
    if (this.rangeDates !== undefined) {
      this.newOccurrence.startDate = this.rangeDates[0];
      this.newOccurrence.endDate = this.rangeDates[1];
    }
    if(!this.newOccurrence.rejected){
      this.newOccurrence.rejected = false;
    }
    if(this.newOccurrence.operation) {
      const findOperation = this.operations.find((r: any) => r.id === this.newOccurrence.operation);
      this.newOccurrence.equipamento = findOperation.equipamento;
      this.newOccurrence.operationDescription = findOperation.operation;
    }
    if(this.isEdit) {
      this.occurrenceService.updateOccurrence(this.newOccurrence)
      .pipe(takeUntil(this._destroy$),
      finalize(() => {
        this.buttonLoading = false;
      }),
      )
      .subscribe({
        next: () => {
          this.newOccurrence = {};
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Ocorrência atualizada com sucesso!',
          });
          this.router.navigate([RoutesEnum.MONITORING_OCCURRENCE]).then(() => {
            this.occurrenceService.setOccurreceInUse(null)
          })
        },
        error: (error) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar atualizar a ocorrência',
          });
        }
      })
    } else {
      this.occurrenceService.saveOccurrence(this.newOccurrence)
      .pipe(takeUntil(this._destroy$),
      finalize(() => {
        this.buttonLoading = false;
      }),
      )
      .subscribe({
        next: () => {
          this.newOccurrence = {};
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Ocorrência salva com sucesso!',
          });
          this.router.navigate([RoutesEnum.MONITORING_OCCURRENCE])
        },
        error: (error) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar salvar ocorrência',
          });
        }
      })
    }
  }

  verifyVariables(): boolean {
    if (!this.rangeDates || this.rangeDates.length < 2) {
      return false;
    }
    if (this.rangeDates[0] === undefined || this.rangeDates[1] === undefined) {
      return false;
    }
    return this.rangeDates[0] <= this.rangeDates[1];
  }

}
