import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'real-time-data-icons',
  templateUrl: './data-icons.component.html',
  styleUrls: ['./data-icons.component.scss']
})
export class DataIconsComponent {
  @Output() dialog = new EventEmitter<boolean>();
  @Output() videoDisplay = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  showDialog() {
    this.dialog.emit(true);
  }

  openVideo() {
    this.videoDisplay.emit(true);
  }
}
