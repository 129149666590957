import { Component, Input } from '@angular/core';

@Component({
  selector: 'status-camera-card',
  templateUrl: './camera-card.component.html',
  styleUrls: ['./camera-card.component.scss']
})
export class CameraCardComponent {

  @Input() url!: string;
  @Input() name!: string;
  @Input() port!: string;
  hasVideo = false;

  // @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  // @ViewChild('videoPlayer2') videoPlayer2!: ElementRef;

  constructor() { }

  ngOnInit(): void {
    if (!this.url) {
      this.url = "https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png";
    }
  }

   // ngAfterViewInit(): void {
  //   const headers = new HttpHeaders().set('Cookie', this.video.cookie);
  //   this.http.get(this.video.url,
  //    {responseType: 'blob', withCredentials: true, headers})
  //     .subscribe(data => {
  //       const blob = new Blob([data], { type: 'video/mp4' });
  //       const url = window.URL.createObjectURL(blob);
  //       this.videoPlayer.nativeElement.src = url;
  //     });

  //   this.http.get(this.video.url,
  //     {responseType: 'blob', headers})
  //       .subscribe(data => {
  //         const blob = new Blob([data], { type: 'video/mp4' });
  //         const url = window.URL.createObjectURL(blob);
  //         this.videoPlayer2.nativeElement.src = url;
  //       });
  // }

  ngAfterContentInit(){
    document.cookie = 'port='+this.port+';Path=/;domain=appvelsis.com;SameSite=None;Secure';
      this.hasVideo = true;
  }

}
