export enum RoutesEnum {
	LOGIN = "/login",
  FORM = "/pages/form",
  DASHBOARD_SERVICOS = "/pages/dashboard-servicos",
  CHANGE_PASSWORD = 'pages/change-password',
  HOME = "/pages/home",
  REAL_TIME = '/pages/real-time',
  EVENTOS = '/pages/events',
  DASHBOARD = '/pages/dashboard',
  STATUS_EQUIPAMENTOS = '/pages/status-monitoring',
  STATUS_EQUIPAMENTOS_LIST = '/pages/status-monitoring/list',
  INVID = '/pages/status-monitoring/invid',
  RELATORIOS = '/pages/reports',
  PBT_CONFIG_ALERT_REPORT = '/pages/pbt-configuration/alert-report',
  PBT_CONFIG = '/pages/pbt-configuration',
  PBT_CONFIG_ALERT_CONFIG = '/pages/pbt-configuration/alert-configuration',
  USER_AUTH_USERS = '/pages/user-auth/users',
  USER_AUTH_EDIT_USERS = '/pages/user-auth/edit-user',
  USER_AUTH_FUNCTIONALITIES = '/pages/user-auth/functionalities',
  USER_AUTH_REGISTER_CONTRACT = '/pages/user-auth/register-contract',
  USER_AUTH_REGISTER_GROUP = '/pages/user-auth/register-group',
  MONITORING_MOTIVE_OCCURRENCE = '/pages/monitoring/motive-occurrence',
  MONITORING_OCCURRENCE = '/pages/monitoring/occurrence',
  MONITORING_NEW_OCCURRENCE = '/pages/monitoring/new-occurrence',
  PROCESSAMENTO_PRE_TRIAGEM = '/pages/triagem/pretriagem',
  PROCESSAMENTO_PRE_TRIAGEM_PROCESSAR = '/pages/triagem/processar/pretriagem',
  PROCESSAMENTO_PRIMEIRA_TRIAGEM = '/pages/triagem/primeira',
  PROCESSAMENTO_PRIMEIRA_TRIAGEM_PROCESSAR = '/pages/triagem/processar/primeira',
  PROCESSAMENTO_PRIMEIRA_TRIAGEM_EM_LOTE = '/pages/triagem/em-lote',
  PROCESSAMENTO_SEGUNDA_TRIAGEM = '/pages/triagem/segunda',
  PROCESSAMENTO_SEGUNDA_TRIAGEM_PROCESSAR = '/pages/triagem/processar/segunda',
  PROCESSAMENTO_VALIDACAO_TRIAGEM = '/pages/triagem/validacao',
  PROCESSAMENTO_VALIDACAO_TRIAGEM_PROCESSAR = '/pages/triagem/processar/validacao',
  PROCESSAMENTO_HISTORICO_TRIAGEM = '/pages/triagem/historico',
  PROCESSAMENTO_HISTORICO_TRIAGEM_PROCESSAR = '/pages/triagem/processar/historico',
  EXPORTACAO_LISTAGEM_AIT = '/pages/exportacao/ait',
  EXPORTACAO_LOTE_EXPORTADO_AIT = '/pages/exportacao/lote-exportado-ait',
  EXPORTACAO_LISTAGEM_PLACAS = '/pages/exportacao/placas',
  EXPORTACAO_LOTE_EXPORTADO_PLACAS = '/pages/exportacao/lote-exportado-placas',
  CONFIGURACAO_GERAL_CLASSIFICACAO_VEICULO = '/pages/general-config/vehicle-classification',
  CONFIGURACAO_GERAL_TIPO_PERIFERICO = '/pages/general-config/peripheral-type',
  CONFIGURACAO_GERAL_TIPO_INFRACAO = '/pages/general-config/infraction-type',
  CONFIGURACAO_GERAL_DETALHAR_TIPO_INFRACAO = '/pages/general-config/infraction-type/detail-infraction-type',
  CONFIGURACAO_GERAL_TARJA_TIPO_INFRACAO = '/pages/general-config/infraction-type/stripe-config',
  CONFIGURACAO_GERAL_LOCAL_OPERACAO = '/pages/general-config/operation-local',
  CONFIGURACAO_GERAL_DETALHAR_LOCAL_OPERACAO = '/pages/general-config/operation-local/detail-operation-local',
  CONFIGURACAO_GERAL_NOVO_LOCAL_OPERACAO = '/pages/general-config/operation-local/new-operation-local',
  OPERACAO = '/pages/general-config/operation',
  PERFIL_OPERACAO = '/pages/general-config/operating-profile',
  CONFIGURACAO_EQUIPAMENTO_CONSULTAR = '/pages/configuracao-equipamento/consultar-equipamento',
  CONFIGURACAO_EQUIPAMENTO_MANTER = '/pages/configuracao-equipamento/manter-equipamento/dados-basicos',

}
