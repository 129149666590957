<div class="text">
  <p class="item" style="margin-bottom: 0.1rem">
    Taxa Ocupação:
    <span [style]="getTextColor(25, 50, 75, 100, data[0].taxaOcupacao)">
      {{ taxa }}
    </span>
  </p>
  <p class="item">
    Vel. Média do local:
    <span style="color: #aacc00"> {{ data[0].velocidadeMedia }}Km/h </span>
  </p>
  <p *ngIf="data[0].mediaVeiculosHora > 0"  class="item">
    Média de Veículos p/hora:
    <span style="color: #aacc00">
      {{ data[0].mediaVeiculosHora }}
    </span>
  </p>
</div>
