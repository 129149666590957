import {inject, Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Occupancy} from '../models/occupancy.model';
import {ReportType} from "../models/report-type.model";
import {map, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class OccupancyRateService extends BaseService {
  requestService = inject(BaseService);
  reportTypes: Array<ReportType> = [];

  constructor(public http: HttpClient) {
    super(http);
  }

  getOccupancyRate(deviceId: any): Observable<Array<Occupancy>> {
    let url: string = this.requestService.buildUrlQueryParams(
      {
        deviceId,
      },
      this.getEndpoint('OCCUPANCY_RATE')
    );
    return this.requestService.getData(url).pipe(
      map((response) => {
        if (response) {
          this.reportTypes = response;
          return response;
        }
        return [];
      })
    );
  }
}
