import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesComponent} from './pages.component';
import {RouterOutlet} from "@angular/router";
import {ThemeModule} from "../theme/theme.module";
import {PagesRoutingModule} from "./pages-routing.module";
import {RealTimeModule} from "./real-time/real-time.module";
import {EventsModule} from "./events/events.module";
import {StatusMonitoringModule} from "./status-monitoring/status-monitoring.module";
import {UserAuthModule} from "./user-auth/user-auth.module";
import { MonitoringModule } from './monitoring/monitoring.module';

import {HomeComponent} from "./home/home.component";
import { ChangePasswordModule } from './change-password/change-password.module';
import {MessageService} from "primeng/api";
import {GridsterComponent, GridsterItemComponent} from "angular-gridster2";
import { DashboardServicosComponent } from './dashboard-servicos/dashboard-servicos.component';

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    DashboardServicosComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    ThemeModule,
    PagesRoutingModule,
    ThemeModule,
    RealTimeModule,
    EventsModule,
    StatusMonitoringModule,
    UserAuthModule,
    MonitoringModule,
    ChangePasswordModule,
    GridsterComponent,
    GridsterItemComponent
  ],
  providers: [
    ThemeModule,
    RealTimeModule,
    EventsModule,
    MessageService,
    StatusMonitoringModule,
    UserAuthModule,
    ChangePasswordModule
  ]
})
export class PagesModule {
}
