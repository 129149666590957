import {Injectable} from '@angular/core';
import {Average, Speed} from '../models/average.model';
import {ReportType} from "../models/report-type.model";
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AvaregeSpeedService extends BaseService {
  reportTypes: Array<ReportType> = [];

  constructor(public http: HttpClient) {
    super(http);
  }

  getAverageSpeed(deviceId: any): Observable<Array<Average>> {
    let url: string = this.buildUrlQueryParams(
      {
        deviceId,
      },
      this.getEndpoint('AVERAGE_SPEED')
    );
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            this.reportTypes = response;
            return response;
          }
          return []
        })
      );
  }

  getSpeed(): Observable<Array<Speed>> {
    const url: string = this.getEndpoint('SPEED');
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            this.reportTypes = response;
            return response;
          }
          return []
        })
      );
  }

}
