import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";

export const headerAvoidInterceptor = 'X-Avoid-Interceptor';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(headerAvoidInterceptor)) {
      const headers = req.headers.delete(headerAvoidInterceptor);

      return next.handle(req.clone({headers}));
    }
    return next.handle(req).pipe(
      catchError((e) => {
        const msgByMethodType = this.getMethodType(req);

        return throwError(() => new Error(`Falha ao ${msgByMethodType} os dados ao serviço remoto.`, e));
      })
    );
  }

  private getMethodType(req: HttpRequest<any>): string {
    switch (req.method) {
      case 'GET':
        return 'obter';
      case 'DELETE':
        return 'excluir';
      default:
        return 'enviar';
    }
  }
}
