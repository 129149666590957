import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule} from 'primeng/image';
import { ToastModule } from 'primeng/toast';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ChangePasswordRoutingModule } from './change-password-routing.module';
import { ChangePasswordComponent } from './change-password.component';
import {CardModule} from "primeng/card";

@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,

    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    TableModule,
    DropdownModule,
    DialogModule,
    GalleriaModule,
    ImageModule,
    ToastModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    ChangePasswordRoutingModule,
    CardModule
  ],
  providers: [MessageService]
})
export class ChangePasswordModule { }
