// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://velsis-api.databuild.com.br/monitoring/',
  proxy: '/proxy/service/',
  tenantId: 'f6842ee7-8e0b-4b2e-b93d-d7e542d5c34a',
  googleMapsKey: 'AIzaSyDk1O2udZedUxNsZQOsfSGkgnZ1MC581Bs',
  apiLegacy: 'https://velsis-api.databuild.com.br/legacy/',
  proxyLegacy: '/proxy/legacy/',
  deviceBuild: 'https://velsis-api.databuild.com.br/deviceBuild/api',
  proxyDeviceBuild: '/proxy/proxyDeviceBuild/',
  apiReports: "https://velsis-api.databuild.com.br/",
  proxyReports: '/proxy/reports/',
  baseApi: "https://velsis-api.databuild.com.br/",
  proxyAuth: '/proxy/users/',
  apiProcess: "https://velsis-api.databuild.com.br/",
  proxyProcess: '/proxy/process/',
  apiExport: "https://velsis-api.databuild.com.br/",
  proxyExport: '/proxy/export/',
  apiLake: 'https://velsis-api.databuild.com.br/datalake/',
  proxyLake: '/proxy/lake/',
  apiBuild: 'https://velsis-api.databuild.com.br/deviceBuild/',
  proxyBuild: '/proxy/build/',

  apiForAuth: 'https://staging.api.pegae.xyz/v2/',
  proxyFormAuth: '/proxy/form/auth/',

  apiForAuthV1: 'https://staging.api.pegae.xyz/v1/',
  proxyFormAuthV1: '/proxy/form/authv1/',

  apiForAuthV2: 'https://staging.api.pegae.xyz/v2/',
  proxyFormAuthV2: '/proxy/form/authv2/',

  features: {
    vitrine: false,
    button_export_ait: true,
    triagemPermiteRecortePlaca: false,
    device_config: true,
  },
  disableProxyLocal: true,
  version: 'Versão 1.3.54 D'
};
