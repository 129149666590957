<p-dialog
  [showHeader]="false"
  (onHide)="cleanDialog()"
  [modal]="true"
  [(visible)]="dialogShow"
  [style]="{ width: '80%', height: '90%' }"
  [baseZIndex]="10000"
>
  <i
    class="close pi pi-times"
    style="font-size: 1rem"
    (click)="closeEventsWindow()"
  ></i>

  <div class="grid content">
    <div class="col-3">
      <div class="grid">
        <div class="col-8">
          <h2>{{ device.deviceName }}</h2>
        </div>
        <div class="col-4">
          <p-badge
            class="col-5 status-badge"
            [value]="device.online ? 'Online' : 'Offline'"
            size="large"
            size="large"
            [severity]="device.online ? 'success' : 'danger'"
          ></p-badge>
        </div>
        <div class="col-12">
          <p>{{ device.address }}</p>
        </div>
      </div>

      <div
        class="grid"
        (click)="
          openGraph(
            ['Passagens', 'Infrações', 'Pesagens'], false
          )
        "
      >
        <div
          class="col-12 m-o h-3rem flex flex-row align-content-between info-card"
        >
          <p class="my-auto mr-1">Passagens:</p>
          <p-badge
            class="my-auto"
            value="{{ formatDataNumber(device.flowings) }}"
            severity="info"
          ></p-badge>
        </div>
        <div class="col-12 m-0 h-3rem flex info-card">
          <p class="my-auto mr-1">Infrações:</p>
          <p-badge
            class="my-auto"
            value="{{ formatDataNumber(device.infractions) }}"
            severity="info"
          ></p-badge>
        </div>
        <div class="col-12 m-0 h-3rem flex info-card">
          <p class="my-auto mr-1">Pesagens:</p>
          <p-badge
            class="my-auto"
            value="{{ formatDataNumber(device.weighings) }}"
            severity="info"
          ></p-badge>
        </div>
      </div>
      <br />
      <div class="grid">
        <div class="col-12">
          <p-card>
            <p>Aproveitamento Captura</p>
            <p-progressBar
              [value]="device.captureUse"
              [style]="{ height: '16px' }"
            ></p-progressBar>
            <p class="pt-1">Aproveitamento OCR</p>
            <p-progressBar
              [value]="device.captureOCR"
              [style]="{ height: '16px' }"
            ></p-progressBar>
            <p class="pt-1">Aproveitamento Captura Diurno</p>
            <p-progressBar
              [value]="device.captureUseDay"
              [style]="{ height: '16px' }"
            ></p-progressBar>
            <p class="pt-1">Aproveitamento Captura Noturno</p>
            <p-progressBar
              [value]="device.captureUseNight"
              [style]="{ height: '16px' }"
            ></p-progressBar>
          </p-card>
        </div>
      </div>
    </div>

    <div class="col-8">
      <div class="grid">
        <!--<p-chart class="col-3" type="line" [data]="data" [options]="options"></p-chart>-->
        <div class="col-3">
          <card-device-status
            type="temperature"
            [redColor]="false"
            [title]="'Temperatura Equipamento'"
            [status]="formatDataNumber(device.deviceTemperature)"
          ></card-device-status>
        </div>
        <div class="col-3">
          <card-device-status
            type="temperature"
            [redColor]="false"
            [title]="'Temperatura Pavimento'"
            [status]="formatDataNumber(device.trackTemperature)"
          ></card-device-status>
        </div>
        <div class="col-3">
          <card-device-status
            type="date"
            [title]="'Última Falta De Energia'"
            [status]="device.lastEnergyBreak"
          ></card-device-status>
        </div>
        <div class="col-3">
          <card-device-status
            type="date"
            [title]="'Ultima Vez Porta Aberta'"
            [status]="device.lastOpenedDoor"
          ></card-device-status>
        </div>
      </div>
      <div class="grid">
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="MLR 1.5.6"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          (click)="openGraph(['Sensor FX 1'], true)"
          class="cursor-pointer col-3 model-badge"
          value="Sensor Fx1"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          (click)="openGraph(['Sensor FX 2'], true)"
          class="cursor-pointer col-3 model-badge"
          value="Sensor Fx2"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="Pan 01"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="MLR 2.32.6"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="Cam Fx1"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="Cam Fx2"
          size="large"
          severity="success"
        ></p-badge>
        <p-badge
          class="cursor-pointer col-3 model-badge"
          value="WIM"
          size="large"
          severity="success"
        ></p-badge>
      </div>
    </div>

    <div class="mt-2 col-1 grid h-2rem">
      <i class="col-12 pi pi-globe"></i>
      <i class="col-12 pi pi-info-circle"></i>
    </div>
  </div>
</p-dialog>
<device-graph
  [(graphShow)]="showGraphWindow"
  [dataGraphHistory]="dataGraphHistory"
  [dataGraphDataSet]="dataGraphDataSet"
  [isSensor]="isSensor"
  [labels]="labels"
  [deviceId]="deviceId"
>
</device-graph>
