import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViolationService extends BaseService {


  constructor(http: HttpClient) {
    super(http);
  }

  public getViolation(groupId: number): Observable<any[]> {
    const url: string = this.getEndpoint('GET_VIOLATION');

    return this.getData(url);
  }

  public setViolation(violationKey: string , statusCode: string): Observable<any>{
    const url: string = this.buildUrlQueryParams({
      key: violationKey,
      statusCode: statusCode
    }, this.getEndpoint('SET_VIOLATION'));

    return this.getData(url)
  }
}
