<p-dialog
  (onShow)="createGraph()"
  [showHeader]="false"
  (onHide)="cleanDialog()"
  [modal]="true"
  [(visible)]="graphShow"
  [style]="{ width: '70%', height: '80%' }"
  [baseZIndex]="10000"
>
  <i
    class="close pi pi-times"
    style="font-size: 1rem"
    (click)="closeEventsWindow()"
  ></i>
  <p-chart type="line" [data]="data" [options]="options"></p-chart>
</p-dialog>
