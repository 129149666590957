import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, finalize, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { Grupo } from 'src/app/models/grupo.model';
import { GruposService } from 'src/app/service/grupos.service';
import { DataTablePage } from 'src/app/models/data-table-page.model';
import { UserService } from 'src/app/service/user.service';
import { StorageKeys } from 'src/app/shared/enums/storage-keys.enum';
import { OccurrenceService } from 'src/app/service/occurrence.service';
import { MotiveOccurrence } from 'src/app/models/occurrence.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';
import { NavigationExtras, Router } from '@angular/router';
import { OccurrenceFilterModel } from 'src/app/models/occurrence-filter.model';

@Component({
  selector: 'app-occurrence',
  templateUrl: './occurrence.component.html',
  styleUrls: ['./occurrence.component.scss']
})
export class OccurrenceComponent implements OnInit, OnDestroy {

  private _destroy$: Subject<void> = new Subject<void>();

  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  page!: DataTablePage;
  loading: boolean = false;
  first: number = 0;
  totalRecords: number = 0;

  currentFilter: any = [];
  rangeDates: Date[] = [];

  grupos!: Array<Grupo>;
  operations!: any;

  occurrenceData: any = [];

  occurrenceFilter: any = new OccurrenceFilterModel();
  motives: MotiveOccurrence[] = []

  occurrenceType = [
    {name: 'Técnico', value: 'technical'},
    {name: 'Não técnico', value: 'nonTechnical'},
  ];

  notFound: boolean = false;
  tituloNotFound: string = 'Nenhuma ocorrência encontrada';
  descricaoNotFound: string = 'Desculpe, não encontramos nenhuma ocorrência para os filtros informados';

  constructor(
    private breadCrumb: BreadcrumbService,
    private occurrenceService: OccurrenceService,
    private gruposService: GruposService,
    private router: Router,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {
    this.page = new DataTablePage();
    this.page.size = 10;
    this.page.pageNumber = 0;
  }

  ngOnInit(): void {
    this.breadCrumb.next([
      {label: 'Configurações', icon: 'pi pi-sliders-v'},
      {label: 'Ocorrência', icon: 'pi pi-history'}
    ]);
    this.getGroups();
    this.occurrenceService.setOccurreceInUse(null);
    this.rangeDates[0] = this.occurrenceFilter.startDate;
    this.rangeDates[1] = this.occurrenceFilter.endDate;
    //this.currentFilter = {...this.occurrenceFilter}; //>>>>> esperar consertar bug da API ao filtrar por data
  }

  getGroups(): void {
    this.gruposService.getGrupos()
      .pipe(takeUntil(this._destroy$))
      .subscribe(partGroupAPI => this.grupos = partGroupAPI);
  }

  getMotives(): void {
    if(this.occurrenceFilter.groupId) {
      this.occurrenceService.getAllMotiveOccurrence(this.occurrenceFilter.groupId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(r => this.motives = r);
    }
  }

  getOperations(): void {
    if(this.occurrenceFilter.groupId) {
      const findGroup = this.grupos.find((r: any) => r.id === this.occurrenceFilter.groupId);
      this.occurrenceService.getOperation(findGroup?.contractId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(r => this.operations = r);
    }
  }

  getOccurrence(pageEvent: any, filters: any) {
    this.notFound = false;
    this.loading = true;
    this.page.pageNumber = pageEvent.first / this.page.size;
    if (pageEvent === false) {
      this.page.pageNumber = 0;
      this.first = 0;
      if (this.rangeDates !== undefined) {
        this.occurrenceFilter.startDate = this.rangeDates[0];
        this.occurrenceFilter.endDate = this.rangeDates[1];
      }
      if (
        this.rangeDates === undefined &&
        this.occurrenceFilter.startHour !== undefined &&
        this.occurrenceFilter.endHour !== undefined
      ) {
        this.occurrenceFilter.startDate = new Date(this.occurrenceFilter.startHour);
        this.occurrenceFilter.endDate = new Date(this.occurrenceFilter.endHour);
      }
    }
    if(filters === false){
      filters = [];
    }
    this.currentFilter = {
      ...filters,
    };
    if(!filters.groupId){
      let userGroupsId: any = [];
      let user = JSON.parse(this.userService.decrypt(StorageKeys.USER));
      user.userGroups.forEach(async (e: any, index: number) => {
        userGroupsId.push(e.groupId);
      });
      this.currentFilter.grupos = userGroupsId.join(',');
    } else {
      this.currentFilter.grupos = [filters.groupId]
    }
    this.occurrenceService.getOccurrence(this.page, this.currentFilter)
      .pipe(takeUntil(this._destroy$),
      finalize(() => {
        this.loading = false;
      }),
      )
      .subscribe({
        next: (data: any) => {
          this.totalRecords = this.page.totalElements;
          this.occurrenceData = data.content;
          if (!data.content || data.content.length === 0) {
            this.notFound = true;
            this.tituloNotFound = 'Nenhuma ocorrência encontrada';
            this.descricaoNotFound = 'Desculpe, não encontramos nenhuma ocorrência para os filtros informados';
          }
        }
      })
  }

  openEdit(occurrence: any){
    occurrence.motiveOccurrence = occurrence.motiveOccurrence.id
    this.occurrenceService.setOccurreceInUse(occurrence);
    this.router.navigate([RoutesEnum.MONITORING_NEW_OCCURRENCE]).then(() => {
    })
  }

  openDeleteConfirm(id: number, desc: string) {
    this.confirmationService.confirm({
      message: 'Você realmente deseja deletar a ocorrência: ' + desc,
      header: 'Deletar ocorrência',
      icon: 'pi pi-times',
      acceptLabel: 'Confirmar',
      rejectLabel: 'Cancelar',
      accept: () => this.deleteOccurrence(id),
      reject: () => { }
    })
  }

  deleteOccurrence(occurrenceId: number) {
    this.occurrenceService.deleteOccurrence(occurrenceId)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            key: 'toastSuccess',
            severity: 'success',
            detail: 'Ocorrência excluída com sucesso!',
          });
          this.getOccurrence(false, false);
        },
        error: (e) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar excluir ocorrência',
          });
        },
      })
  }

  goToNewOccurrence(){
    this.router.navigate([RoutesEnum.MONITORING_NEW_OCCURRENCE])
  }

  clearFilter() {
    this.occurrenceFilter = new OccurrenceFilterModel();
    this.rangeDates = [];
    this.rangeDates[0] = this.occurrenceFilter.startDate;
    this.rangeDates[1] = this.occurrenceFilter.endDate;
    this.motives = [];
  }

  verifyVariables(): boolean {
    if (this.rangeDates !== undefined && this.rangeDates !== null) {
      if(this.rangeDates.length===0){
        return true;
      }
      if(this.rangeDates[1]===null){
        return false;
      }
    }
    return true;
  }

  returnRejectedLabel(rejected: any){
    if(rejected === true){
      return 'Sim'
    }else if(rejected === false){
      return 'Não'
    }else{
      return '-'
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
