<div class="card cursor-pointer">
  <div class="container" (click)="openInfo()" [style]="equipmentData.online ? {background: 'rgba(153, 255, 191, 0.21)' } : { background: '#FFE7E7'}">
    <div class="row">
      <p class="title">{{ equipmentData.deviceName }}</p>
      <i class="pi pi-star-fill" aria-hidden="true" [style]="star ? starStyle : { color: '#999'}"></i>
    </div>
    <div class="address">
      <p>{{ equipmentData.address }}</p>
    </div>

    <div class="row">
      <p class="warning">
        {{ equipmentData.infractions ? equipmentData.infractions : 0 }}
         /
         {{ equipmentData.flowings ? equipmentData.flowings : 0 }}
      </p>
      <div class="actions">
        <!-- <i class="pi pi-exclamation-triangle"></i> -->
        <!-- <i class="pi pi-globe" (click)="openVideo($event)"></i> -->
        <button pButton icon="pi pi-camera" class="p-button p-1" (click)="openVideo($event)"></button>
        <button pButton icon="pi pi-link" class="p-button p-1" (click)="openLink($event, equipmentData.urlAdminAccess,equipmentData.urlAccessPort)"></button>
      </div>
    </div>
  </div>
  <!-- <div class="status" [style]="equipmentData.trackTemperature >= 39 ? {'color': '#F59E0B'} : { 'color': '#22C55E'}"> -->
  <div class="status" [style]="{ 'color': '#22C55E'}">
    <div class="stat">
      <i class="pi pi-camera"></i>
      <p class="cam">{{ equipmentData.captureUse ? equipmentData.captureUse + '%' : '-' }}</p>
    </div>
    <div class="stat">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#999999" viewBox="0 0 256 256"><path d="M180,150.69V56A52,52,0,0,0,76,56v94.69a64,64,0,1,0,104,0ZM128,228a40,40,0,0,1-30.91-65.39,12,12,0,0,0,2.91-7.83V56a28,28,0,0,1,56,0v98.77a12,12,0,0,0,2.77,7.68A40,40,0,0,1,128,228Zm24-40a24,24,0,1,1-36-20.78V92a12,12,0,0,1,24,0v75.22A24,24,0,0,1,152,188Z"></path></svg>
      <p>{{ getTemperature() }}</p>
    </div>
  </div>

</div>
<device-info
 [(dialogShow)]="showInfoWindow"
 [device] = "equipmentData"
>
</device-info>
