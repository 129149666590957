import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {finalize, Subject, takeUntil} from 'rxjs';
import {User} from 'src/app/models/user.model';
import {BreadcrumbService} from 'src/app/service/breadcrumb.service';
import {UserService} from 'src/app/service/user.service';
import {RoutesEnum} from 'src/app/shared/enums/routes.enum';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  stylePattern = {'min-width': '100%', 'max-width': '100%', 'height': '40px'};

  private _destroy$: Subject<void> = new Subject<void>();

  currentPassword: string = ''

  newPassword: string = '';

  confirmPassword: string = '';

  user: User = new User();
  loading: boolean = false;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private breadCrumb: BreadcrumbService
  ) {
  }

  ngOnInit() {
    this.user = this.userService.user;
    this.breadCrumb.next([
      {label: 'Alterar Senha', icon: 'pi pi-key'},
    ]);

  }

  verifyPassword(password: any): boolean {
    const tamanhoMinimo = 8;
    const regexMaiuscula = /[A-Z]/;
    const regexMinuscula = /[a-z]/;
    const regexNumero = /\d/;
    const regexEspecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!password) {
      return true;
    }

    if (password.length < tamanhoMinimo) {
      return false;
    }

    if (!regexMaiuscula.test(password)) {
      return false;
    }

    if (!regexMinuscula.test(password)) {
      return false;
    }

    if (!regexEspecial.test(password)) {
      return false;
    }

    return regexNumero.test(password);
  }

  changePassword() {
    const data = {
      userId: this.user.id,
      currentPassword: this.currentPassword,
      newPassword: this.newPassword
    }
    this.loading = true;
    this.userService.changePassword(data)
      .pipe(
        takeUntil(this._destroy$),
        finalize(() => this.loading = false)
      ).subscribe({
      next: (response) => {
        if (response.success === true) {
          this.userService.loginToastMessage.next({
            key: 'loginToast',
            severity: 'success',
            detail: 'Senha de usuário alterada com sucesso!',
          });
          this.updateOnForce();
          this.router.navigate([RoutesEnum.LOGIN])
        } else {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            summary: 'Error',
            detail: 'Erro ao tentar alterar senha de usuário',
          });
        }
      },
      error: (e) => {

      },
      complete: () => {
        this.currentPassword = ''
        this.newPassword = '';
        this.confirmPassword = '';
      }
    });
  }

  updateOnForce() {
    if (this.user.forceChangePassword) {
      delete this.user.password;
      delete this.user.accessToken;
      delete this.user.refreshToken;
      delete this.user.groups;
      this.user.forceChangePassword = false;
      this.userService.update(this.user)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () => {
          },
          error: (e) => {
          },
          complete: () => this.user = new User()
        });
    }
  }
}
