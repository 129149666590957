import {Injectable} from '@angular/core';
import moment from 'moment';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private mapLoaded!: boolean;

  constructor() {
  }

  async loadGoogleMapsJsApi(): Promise<boolean> {
    if (this.mapLoaded) return true;

    return new Promise((resolve) => {
      try {
        //@ts-ignore
        window['mapInit'] = () => {
          this.mapLoaded = true;
          resolve(true);
        }
        const script: HTMLScriptElement = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleMapsKey + '&libraries=visualization&v=3.45&callback=mapInit';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      } catch (error) {
        console.error(error);
        resolve(false);
      }
    });
  }


  // INFO WINDOW TEMPLATES
  // ----------------------------------

  getLoadingInfoWindow(dispositivo: any): string {
    let content: string =
      '<ion-card class="device-infowindow">' +
      '<p class="font-bold font-size-medium black ion-margin">' + dispositivo.nome + '</p>' +
      '<div class="divider"></div>' +
      '<ion-card-content>' +
      '<ion-spinner></ion-spinner>' +
      '</ion-card-content>' +
      '</ion-card>';
    return content;
  }

  getNoEventFoundInfoWindow(dispositivo: any): string {
    let content: string =
      '<ion-card class="device-infowindow">' +
      '<p class="font-bold font-size-medium black ion-margin">' + dispositivo.nome + '</p>' +
      '<div class="divider"></div>' +
      '<ion-card-content>' +
      '<p>Nenhum evento encontrado</p>' +
      '</ion-card-content>' +
      '</ion-card>';
    return content;
  }

  getHtmlInfoWindow(device: any, events: any): string {
    let content: string =
      '<ion-card class="device-infowindow">' +
      '<p class="font-bold font-size-medium black ion-margin">' + device.nome + '</p>' +
      '<div class="divider"></div>';
    // Passagens
    if (events.passagens && events.passagens.length > 0) {
      content +=
        '<ion-card-content>' +
        '<p class="font-normal font-size-small gray">Últimas passagens</p>' +
        '<ion-slides pager="' + (events.passagens.length > 1) + '">';
      events.passagens.forEach((p: any) => {
        content +=
          '<ion-slide>' +
          '<ion-item id="passagem-' + p.id + '" button lines="none" detail="false">' +
          '<img slot="start" src="' + (p.fotos ? p.fotos[0] : '') + '" />' +
          '<div>' +
          '<ion-label class="font-bold font-size-small data">' + moment(p.dataHora).format("DD/MM/YYYY HH:mm") + '</ion-label>' +
          '<ion-label class="font-normal font-size-small gray">Velocidade: <span class="black">' + p.velocidadeConsiderada + '</span></ion-label>' +
          '<ion-label class="font-normal font-size-small gray">Placa: <span class="black">' + p.placa + '</span></ion-label>' +
          '<ion-label class="font-normal font-size-small gray">Pista: <span class="black">' + p.pista + '</span></ion-label>' +
          '</div>' +
          '</ion-item>' +
          '</ion-slide>';
      });
      content +=
        '</ion-slides>' +
        '</ion-card-content>';

      if (events.pesagens && events.pesagens.length > 0) content += '<div class="divider"></div>';
    }
    // Pesagens
    if (events.pesagens && events.pesagens.length > 0) {
      content +=
        '<ion-card-content>' +
        '<p class="font-normal font-size-small gray">Últimas pesagens</p>' +
        '<ion-slides pager="' + (events.pesagens.length > 1) + '">';
      events.pesagens.forEach((p: any) => {
        content +=
          '<ion-slide>' +
          '<ion-item id="pesagem-' + p.id + '" button lines="none" detail="false">' +
          '<img slot="start" src="' + (p.fotos ? p.fotos[0] : '') + '" />' +
          '<div>' +
          '<ion-label class="font-bold font-size-small data">' + p.dataHora + '</ion-label>' +
          '<ion-label class="font-normal font-size-small gray">PBT (Kg): <span class="black">' + p.peso + '</span></ion-label>' +
          '<ion-label class="font-normal font-size-small gray">Placa: <span class="black">' + p.placa + '</span></ion-label>' +
          '<ion-label class="font-normal font-size-small gray">Pista: <span class="black">' + p.pista + '</span></ion-label>' +
          '</div>' +
          '</ion-item>' +
          '</ion-slide>';
      });
      content +=
        '</ion-slides>' +
        '</ion-card-content>';
    }
    '</ion-card>';
    return content;
  }
}
