import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserAuthRoutingModule} from './user-auth-routing.module';

import { InputMaskModule } from 'primeng/inputmask';
import {ToastModule} from 'primeng/toast';
import {InputTextModule} from 'primeng/inputtext';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {ListboxModule} from 'primeng/listbox';
import {FormsModule} from '@angular/forms';
import { MultiSelectModule } from "primeng/multiselect";
import {UsersComponent} from './users/users.component';
import {EditUserComponent} from './edit-user/edit-user.component';
import {CheckboxModule} from 'primeng/checkbox';
import {FunctionalitiesComponent} from './functionalities/functionalities.component';
import {TableModule} from 'primeng/table';
import {InputSwitchModule} from 'primeng/inputswitch';
import {MessageService} from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { RegisterContractComponent } from './register-contract/register-contract.component';
import { DropdownModule } from 'primeng/dropdown';
import { RegisterGroupComponent } from './register-group/register-group.component';

@NgModule({
  declarations: [
    UsersComponent,
    EditUserComponent,
    FunctionalitiesComponent,
    RegisterContractComponent,
    RegisterGroupComponent
  ],
  imports: [
    CommonModule,
    UserAuthRoutingModule,
    InputTextModule,
    ButtonModule,
    ToastModule,
    DialogModule,
    ListboxModule,
    FormsModule,
    TableModule,
    InputSwitchModule,
    CheckboxModule,
    MultiSelectModule,
    CalendarModule,
    InputMaskModule,
    DropdownModule
  ],
  providers: [
    MessageService
  ]
})

export class UserAuthModule{ }
