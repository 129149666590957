import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceStatusService } from '../../../service/device-status.service';
import { Subject, catchError, takeUntil, tap, throwError } from "rxjs";
import { BreadcrumbService } from "../../../service/breadcrumb.service";
import { UserService } from 'src/app/service/user.service';
import { group } from '@angular/animations';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/service/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-monitoring-access',
  templateUrl: './monitoring-access.component.html',
  styleUrls: ['./monitoring-access.component.scss']
})
export class MonitoringAccessComponent implements OnInit, OnDestroy {

  private _destroy$: Subject<void> = new Subject<void>();

  devices: any[] = [];
  devicesFiltered: any[] = [];

  page: number = -1;

  hasMoreData: boolean = true;

  timerId: any = null;

  filter: string = '';

  stylePattern = { 'min-width': '100%', 'max-width': '100%', 'height': '40px' };
  groups: any[] = [];
  groupselected!: number;

  videoDisplay = false;

  dataDevice: any;


  constructor(
    private seviceStatusService: DeviceStatusService,
    private breadCrumb: BreadcrumbService,
    private userService: UserService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.breadCrumb.next([
      { label: 'Monitoramento', icon: 'pi pi-fw pi-desktop' },
      { label: 'Status Equipamento', icon: 'pi pi-fw pi-exclamation-circle' }
    ]);
    this.loadGroups();
    this.seviceStatusService.groupSelected$.pipe(
      takeUntil(this._destroy$),
      tap((r) => {
        if(r != null) {
          this.groupselected = r;
          this.loadDevices();
        }
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  sendListEquipment() {
    this.router.navigate(['pages','status-monitoring', 'list']);
  }

  checkScroll() {
    const element: any = document.documentElement;
    const hasScrollbar = element.scrollHeight > element.clientHeight;
    if (!hasScrollbar && this.hasMoreData) {
      this.loadDevices();
    }
  }

  async search(event: any) {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
    this.devices = [];
    this.devicesFiltered = [];
    if (event.target.value == '') {
      this.page = 0;
      this.filter = '';
      this.loadDevices();
      return;
    }
    this.timerId = setTimeout(async () => {
      this.page = 0;
      this.filter = event.target.value
      this.loadDevicesFiltered(false);
    }, 1000);
  }

  loadDevicesFiltered(concat: boolean): void {
    if (!this.groupselected) return;
    this.loaderService.showLoader()
    this.seviceStatusService.getDeviceListFiltered(this.filter, this.groupselected, this.page)
      .pipe(
        takeUntil(this._destroy$),
        catchError((err) => {
          this.loaderService.hideLoader();

          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            detail: 'Erro ao realizar a consulta',
          });
          return throwError(err);
        }),
        tap((res: any) => {
          const list = res.content
          this.loaderService.hideLoader();
          this.checkLengthArray(list, res);
          if (res.numberOfElements == 0) {
            this.hasMoreData = false;
            return;
          }
          if (list.length > 0) {
            if (concat) {
              const old = this.devicesFiltered.concat(list);
              this.devicesFiltered = old;
              this.hasMoreData = true;
              this.checkScroll();
              return;
            }
            this.hasMoreData = true;
            this.devicesFiltered = list;
            this.checkScroll();
          } else {
            this.hasMoreData = false;
          }
        })
      )
      .subscribe()
  }

  showDevice(data: any) {
    this.dataDevice = data;
    this.videoDisplay = true;
  }

  loadGroups(): void {
    this.seviceStatusService.getGroups(this.userService.user.id!).subscribe(r => this.groups = r)
  }

  onChangeGroup($event: any): void {
    this.page = -1;
    this.devices = [];
    this.devicesFiltered = [];
    this.seviceStatusService.updateGroup(this.groupselected);
    this.loadDevices();
  }

  loadDevices() {
    if (!this.groupselected) return;
    this.page++;
    if (this.filter != '') {
      this.loadDevicesFiltered(true);
      return;
    }
    this.loaderService.showLoader();
    this.seviceStatusService.getDeviceStatusList(this.groupselected, this.page)
      .pipe(
        takeUntil(this._destroy$),
        catchError((err) => {
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            detail: 'Erro ao realizar a consulta',
          });
          this.loaderService.hideLoader();
          return throwError(err);
        }),
        tap((res: any) => {
          const list = res.content
          this.loaderService.hideLoader();
          this.checkLengthArray(list, res);
          if (res.numberOfElements == 0) {
            this.hasMoreData = false;
            return;
          }
          if (list.length > 0) {
            this.devices = this.devices.concat(list);
            this.devicesFiltered = this.devices;
            this.hasMoreData = true;
            this.checkScroll();
          } else {
            this.hasMoreData = false;
          }
        })
      )
      .subscribe()
  }

  checkLengthArray(array: Array<any>, objFull: any) {
    if (array.length == 0 && this.devicesFiltered.length == 0) {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        detail: 'Não foi encontrado nenhum dispositivo.',
      });
      return;
    }
    if (objFull.numberOfElements == 0) {
      this.messageService.add({
        key: 'toastError',
        severity: 'success',
        detail: 'Todo os dispositivos foram carregados',
      });
      return;
    }
  }
}
