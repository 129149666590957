import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DeviceEvent } from 'src/app/models/device-event.model';
import { AddressDevice } from 'src/app/models/device.model';
import { GaleriaCommandsService } from 'src/app/service/galeria-commands.service';
import { DeviceEventType } from 'src/app/shared/enums/device-event-type.enum';
import { VIOLATION_ACTION } from 'src/app/shared/enums/violation.enum';

@Component({
  selector: 'app-violation-dialog',
  templateUrl: './violation-dialog.component.html',
  styleUrls: ['./violation-dialog.component.scss']
})
export class ViolationDialogComponent {

  @Input() violation: any = null;

  @Input() showViolations: boolean = false;
  @Output() showViolationsChange = new EventEmitter<boolean>();

  @Output() violationAction: EventEmitter<VIOLATION_ACTION> = new EventEmitter<VIOLATION_ACTION>();

  @Output() clearViolationsStackEvent = new EventEmitter();

  fullscreenImg: boolean = false;
  imgUrl: string = '';
  openDetails = false;
  indexSelecionado: number = 0;

  constructor(public galeriaService: GaleriaCommandsService) {
  }

  ngOnInit(): void {
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.fullscreenImg) {
      if (event.key === 'ArrowRight') {
        this.indexSelecionado = this.indexSelecionado === this.violation.imagesAndVideos.documents.length - 1 ? 0 : this.indexSelecionado + 1;
      } else if (event.key === 'ArrowLeft') {
        this.indexSelecionado = this.indexSelecionado === 0 ? this.violation.imagesAndVideos.documents.length - 1 : this.indexSelecionado - 1;
      } else if (event.key === 'Escape') {
        this.fullscreenImg = false;
      }
    }
  }


  ignore() {
    this.showViolationsChange.emit(false);
    this.violationAction.emit(VIOLATION_ACTION.IGNORE);
  }

  valid() {
    this.showViolationsChange.emit(false);
    this.violationAction.emit(VIOLATION_ACTION.VALID);
  }

  reset() {
    this.showViolationsChange.emit(false);
    this.violationAction.emit(VIOLATION_ACTION.RESET);
  }

  invalid() {
    this.showViolationsChange.emit(false);
    this.violationAction.emit(VIOLATION_ACTION.INVALID);
  }

  cleanDialog() {
    this.clearViolationsStackEvent.emit();
  }

  fullscrennImage(url: any, index: number) {
    this.indexSelecionado = index;
    this.fullscreenImg = true;
    this.imgUrl = url;
  }

  getAxlesPbt(axles: any[]): string {
    let ret = '';
    let i = 1;
    axles.forEach((val) => {
      ret += ('Eixo ' + i + ': ' + val.weight_axle + ' kg, ');
      i++;
    })
    ret = ret.substring(0, ret.length - 2);
    return ret;
  }

  isWheighing(t: DeviceEventType): boolean {
    return t === DeviceEventType.WEIGHING;
  }

  getInfractionData(infractionId: number): { description: string, framework: number } {
    const f_89 = {
      description: "Conduzir motocicleta, motoneta ou ciclomotor sem usar capacete de segurança.",
      framework: 70301
    }
    const f_90 = {
      description: "Parar na área de cruzamento de vias.",
      framework: 56300
    }

    if(infractionId == 89) {
      return f_89
    } else if(infractionId == 90) {
      return f_90
    } else {
      return {
        description: "-",
        framework: 0
      }
    }

  }

}

