import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, tap, throwError, timer } from "rxjs";
import { BaseService } from "./base.service";
import { User } from "../models/user.model";

export interface ITransporterLogin {
  email?: string;
  password?: string;
  token?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor() { }

  private loginSubject: BehaviorSubject<ITransporterLogin | null> = new BehaviorSubject<ITransporterLogin | null>(null);
  login$ = this.loginSubject.asObservable();

  updateTransporter(changes: Partial<ITransporterLogin>): void {
    this.loginSubject.next({
    ...this.loginSubject.value,
    ...changes
    });
  }

  getValues(): ITransporterLogin {
    return this.loginSubject.getValue() || {} as ITransporterLogin;
  }

  reset(): void {
    this.loginSubject.next(null);
  }

}
