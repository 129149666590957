import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-not-found-component',
  template: `
    <div class="surface-card shadow-2 border-round p-4" style="margin-bottom: 1rem;">
      <div class="text-center not-found__404">
          <span class="bg-white text-blue-500 font-bold text-2xl inline-block px-3">404</span></div>
      <div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">
          {{titulo}}
      </div>
      <p class="text-700 text-3xl mt-0 mb-6 text-center">
          {{descricao}}
      </p>
    </div>
  `,
  styleUrls: ['./not-found-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponentComponent {

  @Input() titulo: string = '';
  @Input() descricao: string = '';
}
