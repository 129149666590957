import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DeviceStatus} from '../models/device-status.model';
import {BaseService} from "./base.service";

@Injectable({
  providedIn: 'root'
})
export class DeviceStatusService extends BaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  private groupSelectedSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  groupSelected$: Observable<any> = this.groupSelectedSubject.asObservable();

  updateGroup(group: any){
    this.groupSelectedSubject.next(group);
  }

  getDeviceStatusList(groupId: number, page: number, pageSize: number = 40): Observable<DeviceStatus[]> {
    return this.http.get<DeviceStatus[]>(
      this.getRootEndpoint() + 'v1/deviceStatus/getDeviceStatus?groupId=' + groupId + '&pageSize=' + pageSize + '&pageNumber=' + page
    );
  }

  getDeviceListFiltered(filtered: string, groupId: number, page: number, pageSize: number = 40): Observable<DeviceStatus[]> {
    return this.http.get<DeviceStatus[]>(
      this.getRootEndpoint() + `v1/deviceStatus/searchDevice/?groupId=${groupId}&pageNumber=${page}&pageSize=${pageSize}&deviceName=${filtered}`
    );
  }

  getGroups(userId: number): Observable<any> {
    let url: string = this.getEndpoint('GRUPOS');
    url+=`&userId=${userId}`

    return this.http.get(url)
  }

  getDeviceDataHistory(deviceId: number): Observable<DeviceStatus[]> {
    return this.http.get<DeviceStatus[]>(
      this.getRootEndpoint() + 'v1/deviceStatus/getDeviceStatusHistory/?deviceId=' + deviceId
    );
  }

  getDeviceDataSet(deviceId: number): Observable<any> {
    return this.http.get<any>(
      this.getRootEndpoint() + 'v1/deviceStatus/getDadosSensores/?deviceId=' + deviceId
    );
  }

}
