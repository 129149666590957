import {Pipe, PipeTransform} from "@angular/core";
import {UserService} from "../../service/user.service";
import {Role} from "../enums/role.enum";

@Pipe({
  name: 'validaFuncionalidadePorPerfil',
})
export class ValidaFuncionalidadePorPerfilPipe implements PipeTransform {

  transform(userService?: UserService, perfisPermitidos?: Role | Role[]): boolean {

    if (!userService || !perfisPermitidos || !userService.user)
      return false;

    if (!Array.isArray(perfisPermitidos))
      perfisPermitidos = [perfisPermitidos];

    return userService.hasRoles(perfisPermitidos);
  }

}
