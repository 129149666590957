import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, catchError, takeUntil, tap, throwError } from 'rxjs';
import { DataTablePage } from 'src/app/models/data-table-page.model';
import { BreadcrumbService } from 'src/app/service/breadcrumb.service';
import { DeviceStatusService } from 'src/app/service/device-status.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class MonitoringListComponent implements OnInit, AfterViewInit {
  dataDevice: any;
  showDeviceDialog: boolean = false;

  timerId: any;

  stylePattern = { 'min-width': '100%', 'max-width': '100%', 'height': '40px' };

  groups: any[] = [];
  groupselected: any;
  filter: string = '';

  loading: boolean = false;

  devices: any[] = [];
  devicesFiltered: any[] = [];

  pageData: DataTablePage = new DataTablePage();
  totalRecords: number = 0;
  first: number = 0;
  rows: number = 10;
  totalPages: number = 0;
  userFavorites: Array<number> = [];
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private breadCrumb: BreadcrumbService,
    private messageService: MessageService,
    private seviceStatusService: DeviceStatusService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadGroups();
    this.breadCrumb.next([
      {label: 'Status Equipamento', icon: 'pi pi-exclamation-circle'},
    ]);
    const data = localStorage.getItem(`favoriteDevicesInStatusMonitoring${this.userService.user.id!}`);
    if(data){
      this.userFavorites = JSON.parse(data);
    }
  }

  ngAfterViewInit(): void {
    this.loadGroups();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadGroups(): void {
    this.seviceStatusService.getGroups(this.userService.user.id!)
    .pipe(takeUntil(this.destroy$))
    .subscribe(r => this.groups = r)
  }

  onChangeGroup($event: any): void {
    this.pageData.pageNumber = 0;
    this.devices = [];
    this.devicesFiltered = [];
    this.loadDevicesLazy(false);
  }

  async search(event: any) {
    if (this.timerId) {
      clearTimeout(this.timerId)
    }
    this.loading = true;

    this.devices = [];
    this.devicesFiltered = [];
    if (event.target.value == '') {
      this.pageData.pageNumber = 0;
      this.filter = '';
      return;
    }
    this.timerId = setTimeout(async () => {
      this.pageData.pageNumber = 0;
      this.filter = event.target.value
      this.loadDevicesLazy(false);
    }, 1000);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.pageData.pageNumber = event.first === 0 ? 0 : event.first / event.rows;
  }

  checkLengthArray(array: Array<any>, objFull: any) {
    if (array.length == 0 && this.devicesFiltered.length == 0) {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        detail: 'Não foi encontrado nenhum dispositivo.',
      });
      return;
    }
    if (objFull.numberOfElements == 0) {
      this.messageService.add({
        key: 'toastError',
        severity: 'success',
        detail: 'Todo os dispositivos foram carregados',
      });
      return;
    }
  }

  loadDevicesLazy(pageEvent: any) {
    if(this.groupselected == undefined) return;
    this.loading = true;
    this.pageData.pageNumber = pageEvent.first / this.rows;

    if (pageEvent === false) {
      this.pageData.pageNumber = 0;
      this.first = 0;
    }
    if(this.filter != '') {
      this.seviceStatusService.getDeviceListFiltered(this.filter, this.groupselected, this.pageData.pageNumber, this.rows)
      .pipe(
        catchError((err) => {
          this.loading = false;

          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            detail: 'Erro ao realizar a consulta',
          });
          return throwError(err);
        }),
        tap((res: any) => {
          const list = res.content
          this.checkLengthArray(list, res);
          this.devices = list;
          this.devicesFiltered = this.devices;
          this.loading = false;

          this.totalPages = res.totalPages;
          this.totalRecords = res.totalElements;
        })
      )
      .subscribe()
    } else {
      this.seviceStatusService.getDeviceStatusList(this.groupselected, this.pageData.pageNumber, this.rows)
      .pipe(
        catchError((err) => {
          this.loading = false;
          this.messageService.add({
            key: 'toastError',
            severity: 'error',
            detail: 'Erro ao realizar a consulta',
          });
          return throwError(err);
        }),
        tap((res: any) => {
          const list = res.content
          this.checkLengthArray(list, res);
          this.devices = list;
          this.devicesFiltered = this.devices;
          this.loading = false;

          // this.pageSelected = res.page;
          this.totalPages = res.totalPages;
          this.totalRecords = res.totalElements;
        })
      )
      .subscribe()
    }

  }
  //A TELA DOS CARDS EM STATUS EQUIPAMENTO ESTÁ DESATIVADA
  // back() {
  //   this.router.navigate(['pages', 'status-monitoring'])
  // }

  openVideo(event: Event, equipmentData: any) {
    event.stopPropagation();
    if (equipmentData.urlAccessPort && equipmentData.urlAccessPort > 0 && equipmentData.urlCamAccess !== null) {
      this.setCookie(equipmentData.urlAccessPort);
      this.dataDevice = equipmentData;
      this.showDeviceDialog = true;
    } else {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipamento sem acesso',
      });
    }
  }

  setCookie(port: number) {
    document.cookie = 'port=' + port + ';Path=/;domain=appvelsis.com;SameSite=None;Secure';
  }

  openLink(event: any, url: string, port: number) {
    event.stopPropagation();
    if(port == null || url == null) {
      this.messageService.add({
        key: 'toastError',
        severity: 'error',
        summary: 'Error',
        detail: 'Equipamento sem acesso',
      });
      return;
    }
    this.setCookie(port);
    window.open(`${url}:${port}`,'_blank');
  }

  addFavoriteDevice(id: number){
    let userFavoriteDevices = [];
    const data = localStorage.getItem(`favoriteDevicesInStatusMonitoring${this.userService.user.id!}`);
    if(data){
      userFavoriteDevices = JSON.parse(data);
    }
    userFavoriteDevices.push(id);
    this.userFavorites = userFavoriteDevices;
    localStorage.setItem(`favoriteDevicesInStatusMonitoring${this.userService.user.id!}`, JSON.stringify(userFavoriteDevices));
  }

  removeFavoriteDevice(id: number){
    let userFavoriteDevices = [];
    const data = localStorage.getItem(`favoriteDevicesInStatusMonitoring${this.userService.user.id!}`);
    if(data){
      userFavoriteDevices = JSON.parse(data);
    }
    const index =  userFavoriteDevices.indexOf(id);

    userFavoriteDevices.splice(index, 1);
    this.userFavorites = userFavoriteDevices;
    localStorage.setItem(`favoriteDevicesInStatusMonitoring${this.userService.user.id!}`, JSON.stringify(userFavoriteDevices));
  }

}
