import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RealTimeComponent } from './real-time.component';
import { InfoWindowComponent } from './info-window/info-window.component';
import { HeatMap3dComponent } from './heat-map3d/heat-map3d.component';
import { DataLayerComponent } from './data-layer/data-layer.component';
import { MessagesModule } from 'primeng/messages';


import { AccordionModule } from "primeng/accordion";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { InputSwitchModule } from "primeng/inputswitch";
import { ChartModule } from "primeng/chart";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { ButtonModule } from "primeng/button";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TableModule } from "primeng/table";
import { PanelModule } from "primeng/panel";
import { TooltipModule } from "primeng/tooltip";
import { RippleModule } from "primeng/ripple";
import { SidebarModule } from "primeng/sidebar";
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { GalleriaModule } from 'primeng/galleria';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataIconsComponent } from './info-window/components/data-icons/data-icons.component';
import { EventsDetailsWindowComponent } from './info-window/components/events-details-window/events-details-window.component';
import { EventsWindowComponent } from './info-window/components/events-window/events-window.component';
import { InfoWindowTextComponent } from './info-window/components/info-window-text/info-window-text.component';
import { SliderComponent } from './info-window/components/slider/slider.component';
import { RealTimeRoutingModule } from './real-time-routing.module';
import { MessageService } from 'primeng/api';
import { AlertEventDetailComponent } from './info-window/components/alert-event-detail/alert-event-detail.component';
import {CalendarModule} from "primeng/calendar";
import { DevicesLayerComponent } from './devices-layer/devices-layer.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ThemeModule } from 'src/app/theme/theme.module';

@NgModule({
  declarations: [
    RealTimeComponent,
    InfoWindowComponent,
    HeatMap3dComponent,
    DataLayerComponent,
    DataIconsComponent,
    EventsDetailsWindowComponent,
    EventsWindowComponent,
    InfoWindowTextComponent,
    SliderComponent,
    AlertEventDetailComponent,
    DevicesLayerComponent
  ],
  imports: [
    CommonModule,

    HttpClientModule,
    DialogModule,
    AccordionModule,
    ProgressSpinnerModule,
    ButtonModule,
    ToastModule,
    TabViewModule,
    TabMenuModule,
    TableModule,
    PanelModule,
    TooltipModule,
    RippleModule,
    InputSwitchModule,
    GalleriaModule,
    MultiSelectModule,
    ChartModule,
    DropdownModule,
    MessagesModule,
    SidebarModule,
    InputTextModule,
    BadgeModule,
    SharedModule,
    ThemeModule,

    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,

    RealTimeRoutingModule,
    CalendarModule,
  ],
  exports: [
    HttpClientModule,
    DialogModule,
    AccordionModule,
    ProgressSpinnerModule,
    ButtonModule,
    ToastModule,
    TabViewModule,
    TabMenuModule,
    TableModule,
    PanelModule,
    TooltipModule,
    RippleModule,
    InputSwitchModule,
    MultiSelectModule,
    ChartModule,
    DropdownModule,
    MessagesModule,

    RealTimeRoutingModule,
  ],
  providers: [
    MessageService
  ]
})
export class RealTimeModule { }
