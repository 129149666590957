export class User {
  id?: number;
  userName: string;
  email: string;
  phone: string;
  password?: string;
  blocked?: boolean;
  accessToken?: string;
  refreshToken?: string;
  groups?: Group[];
  roles?: RoleModel[];
  userGroups?: UserGroup[];
  forceChangePassword?: boolean;
  enableMFA?: boolean;
  auditorRecord?: number;
  legacyUserId?: any;

  constructor() {
    this.userName = '';
    this.phone = '';
    this.email = '';
    this.userGroups = [];
    this.forceChangePassword = false;
    this.enableMFA = false;
    this.blocked = false;

  }
}

export class RoleModel {
  id?: number;
  active?: boolean;
  roleName?: string;
  roleRout?: string;

  constructor() {
  }
}

export class UserGroup {
  id?: number;
  userId?: number;
  groupId?: number;
  roles?: RoleModel[];

  constructor() {
  }
}

export interface Group {
  centralWebSync?: boolean;
  contract?: any;
  contractId?: number;
  description?: string;
  hashKey?: string;
  id?: number;
  lastUpdate?: any;
  showOnMap?: boolean;
  syncNewDataOnly?: boolean;
}
