import {Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, Observable, Subject, takeUntil} from 'rxjs';
import {MenuItem} from 'primeng/api';
import {ActivationEnd, Router} from "@angular/router";

@Injectable({providedIn: "root"})
export class BreadcrumbService {
  private _destroy$: Subject<void> = new Subject<void>();
  private homeMenu: MenuItem = {icon: 'pi pi-home', routerLink: '/'};
  private itemsSource: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([{label: 'sadasd'}]);

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationEnd),
        filter((event2: any) => event2.snapshot.data['breadcrumb']),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((event) => {
        setTimeout(() => {
          this.next(event.snapshot.data['breadcrumb'])
        });
      });
  }

  value(): Observable<MenuItem[]> {
    return this.itemsSource.asObservable();
  }

  next(items: MenuItem[]) {
    this.itemsSource.next([this.homeMenu].concat(...items));
  }

  unsubscribeAll() {
    this._destroy$.next();
    this._destroy$.complete();
    this.itemsSource.unsubscribe();
  }

  /**
   * @description Método responsável por tratar o click do breadcrumb caso NAO tenha rota
   * @param item
   */
  clickEvent(item: MenuItem) {
    if (!item.routerLink && item?.command)
      item.command({item});
  }

}
