import { DeviceEventType } from "../shared/enums/device-event-type.enum";
import { Device } from "./device.model";
import { Document } from "./document.model";

export class DeviceEvent {
  date!: string;
  type!: DeviceEventType;
  device!: Device;
  documents!: Document[];
  id!: number;
  speed!: number;
  weighing!: Weighing;
  vehicleLenght!: number;
  plate!: string;
  totalGrossWeight!: number;
  distanceFromPreviousVehicle!: number;
  originId!: number;
  event!: string;
  mainPhoto!: Document;
  photos?: Array<Document>;
  vehicle_category?: string;
}

export class DeviceEventMap {
  name!: string;
  city!: string;
  country!: string;
  date!: string;
  device!: number;
  device_lane_id!: number;
  distance_from_previous_vehicle!: number
  documents!: Array<Document>;
  event_type!: DeviceEventType;
  flow_direction!: string;
  groupId!: number;
  id!: number;
  lane_id!: number;
  lane_identification!: string;
  plate!: string;
  speed!: number;
  state!: string;
  street_name!: string;
  vehicle_lenght!: number;
  total_gross_weight!: number; //<<<<<<<<<
  zip_code!: string;
}

export class DeviceEventGrouped {
  flowEvents!: Array<DeviceEvent>;
  weighingsEvents!: Array<DeviceEvent>;
}

export class AlertEvent {
  id!: number;
  title!: string;
  description!: string;
  kg!: number;
  kgMeasured!: number;
  alertConfiguration!: number;
  eventWeighing!: number;
}

export interface Weighing {
  axleCount: number;
  axles: Array<Axle>;
  id: number;
  pnctClass: string | null;
  overweight: number;
  pbtLimit: string | number;
  qfvClass: string;
}
export interface Axle {
  weight_axle: number;
}
