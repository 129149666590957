import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MonitoringAccessComponent } from './monitoring-access/monitoring-access.component';
import { CamerasScreenComponent } from './invid/cameras-screen/cameras-screen.component';
import { AuthGuard } from 'src/app/core/guards/auth-guard';
import { Role } from 'src/app/shared/enums/role.enum';
import { MonitoringListComponent } from './list/list.component';

const routes: Routes = [
  //A TELA DOS CARDS EM STATUS EQUIPAMENTO ESTÁ DESATIVADA
  // {
  //   path: '',
  //   canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.STATUS_EQUIPAMENTOS])],
  //   component: MonitoringAccessComponent
  // },
  {
    path: 'list',
    canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.STATUS_EQUIPAMENTOS])],
    component: MonitoringListComponent
  },
  {
    path: 'invid',
    canActivate: [() => AuthGuard([Role.ADMIN_FULL, Role.GESTOR, Role.INVID])],
    component: CamerasScreenComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatusMonitoringRoutingModule { }
