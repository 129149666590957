<div class="px-6">
  <p-toast position="top-right" key="toastError"></p-toast>
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-card styleClass="my-3">
    <p-fieldset legend="{{ isEdit ? 'Editar Nova Ocorrência' : 'Criar Nova Ocorrência'}}">
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [scrollHeight]="'30rem'"
              [style]="stylePattern"
              [options]="grupos"
              inputId="filtroGrupos"
              [class]="!newOccurrence.groupId ? 'ng-invalid ng-dirty' : ''"
              [required]="true"
              [(ngModel)]="newOccurrence.groupId"
              optionLabel="description"
              optionValue="id"
              [filter]="true"
              [showClear]="true"
              placeholder="Selecionar"
              (onChange)="getMotives();getOperations();groupChange()"
            >
              <ng-template let-grupos pTemplate="item">
                <div class="font-semibold text-center">
                  {{ grupos.description }}
                </div>
              </ng-template>
            </p-dropdown>
            <label htmlFor="filtroGrupos">Grupos</label>
          </span>
        </div>
        <div class="col-6">
          <span class="p-float-label">
            <p-dropdown
              [(ngModel)]="newOccurrence.motiveOccurrence"
              [filter]="true"
              [showClear]="true"
              [class]="!newOccurrence.motiveOccurrence ? 'ng-invalid ng-dirty' : ''"
              [options]="motives"
              optionLabel="description"
              optionValue="id"
              [style]="stylePattern"
              inputId="filterMotivo"
              placeholder="Selecionar"
              [disabled]="!newOccurrence.groupId"
            ></p-dropdown>
            <label htmlFor="filterMotivo">Motivo</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-12">
          <span class="p-float-label">
            <p-dropdown
            [(ngModel)]="newOccurrence.operation"
            [filter]="true"
            [showClear]="true"
            [class]="!newOccurrence.operation ? 'ng-invalid ng-dirty' : ''"
            [options]="operations"
            optionLabel="operation"
            optionValue="id"
            [style]="stylePattern"
            inputId="filterOperacao"
            placeholder="Selecionar"
            [disabled]="!newOccurrence.groupId"
            ></p-dropdown>
            <label htmlFor="filterOperacao">Operação</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-12">
          <span class="p-float-label">
            <input
            [class]="!newOccurrence.description ? 'min-w-full ng-invalid ng-dirty' : 'min-w-full'"
            [style]="stylePattern"
            type="text"
            pInputText
            htmlFor="filterDescricao"
            [(ngModel)]="newOccurrence.description"
            [required]="true"
            />
            <label htmlFor="filterDescricao">Descrição</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              selectionMode="range"
              [readonlyInput]="true"
              inputId="filterRange"
              [class]="!verifyVariables() ? 'ng-invalid ng-dirty' : ''"
              [(ngModel)]="rangeDates"
            ></p-calendar>
            <label htmlFor="filterRange">Intervalo</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              [readonlyInput]="true"
              [timeOnly]="true"
              [showTime]="true"
              hourFormat="24"
              inputId="filterHoraInit"
              placeholder="hh:mm"
              [(ngModel)]="newOccurrence.startHour"
            ></p-calendar>
            <label htmlFor="filterHoraInit">Hora Início</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              [readonlyInput]="true"
              inputId="filterHoraFim"
              [timeOnly]="true"
              [showTime]="true"
              hourFormat="24"
              placeholder="hh:mm"
              [(ngModel)]="newOccurrence.endHour"
            ></p-calendar>
            <label htmlFor="filterHoraFim">Hora Fim</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-6">
          <span class="p-float-label">
            <input
            [class]="!newOccurrence.deliveryProtocol ? 'min-w-full ng-invalid ng-dirty' : 'min-w-full'"
            [style]="stylePattern"
            type="text"
            pInputText
            inputId="filterDeliveryProtocol"
            [(ngModel)]="newOccurrence.deliveryProtocol"
            [required]="true"
            />
            <label htmlFor="filterDeliveryProtocol">Protocolo de entrega</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [class]="!newOccurrence.protocolDate ? 'ng-invalid ng-dirty' : ''"
              [style]="stylePattern"
              [(ngModel)]="newOccurrence.protocolDate"
              [readonlyInput]="true"
              [showClear]="true"
              inputId="protocolDate"
            ></p-calendar>
            <label htmlFor="protocolDate">Data Protocolo</label>
          </span>
        </div>
        <div class="col-3">
          <span class="p-float-label">
            <p-calendar
              [style]="stylePattern"
              [readonlyInput]="true"
              inputId="protocolHour"
              [timeOnly]="true"
              [showTime]="true"
              hourFormat="24"
              placeholder="hh:mm"
              [(ngModel)]="newOccurrence.protocolHour"
            ></p-calendar>
            <label htmlFor="protocolHour">Hora Protocolo</label>
          </span>
        </div>
      </div>
      <div class="grid mt-3">
        <div class="col-3">
          <p-checkbox label="Rejeitado Pelo Orgão" [(ngModel)]="newOccurrence.rejected" [binary]="true"></p-checkbox>
        </div>
        <div class="col-2 text-align: right;">
          <p-button
            class="block relative top-100 -translate-y-100"
            [style]="{ height: '40px' }"
            (onClick)="createOccurrence()"
            [loading]="buttonLoading"
            label="Salvar"
            size="small"
            [disabled]="!verifyVariables() || !this.newOccurrence.description || !this.newOccurrence.groupId || !this.newOccurrence.motiveOccurrence
            || !this.newOccurrence.operation || !this.newOccurrence.protocolDate || !this.newOccurrence.deliveryProtocol || buttonLoading"
            icon="pi pi-save"
          ></p-button>
        </div>
      </div>
    </p-fieldset>
  </p-card>
</div>
