import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, tap, throwError } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';
import { TriagemService } from 'src/app/service/triagem.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-from-central',
  templateUrl: './from-central.component.html',
  styleUrls: ['./from-central.component.scss']
})
export class FromCentralComponent implements OnInit {

  allow: string[] =
  [
    'triagem',
    'pre-triagem',
    'exportacao'
  ];

  constructor(
    private messageService: MessageService,
    private loaderService: LoaderService,
    private route: Router,
    private userService: UserService,
    private triagemService: TriagemService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loaderService.showLoader()
    const params = this.route.url.split('/') as any[];
    const token = params[3];
    const email = params[4];

    if(params.length > 3) {
      if (token) {
        this.userService.setAuthToken(token);
        this.userService.loginFromToken(token, email).pipe(
          tap((r) => {
            this.loadSession()
          }),
          catchError((err) => {
            this.messageService.add({
              key: 'toastInfo',
              severity: 'error',
              summary: 'Erro',
              detail: 'Token ou Email inválido.',
            });
            return throwError(err);
          })
        ).subscribe()
      }
    } else {
      this.messageService.add({
        key: 'toastInfo',
        severity: 'error',
        summary: 'Erro',
        detail: 'Parametros inválidos.',
      });
      this.loaderService.hideLoader()
    }
  }

  loadSession() {
    const params = this.route.url.split('/') as any[];

    const url = params[2];
    const authToken = this.userService.getToken();
    if(authToken && this.hasService(url)) {
      this.triagemService.loadContratosAndDomains();
      this.router.navigate(['pages', 'triagem', 'validacao']);
      this.loaderService.hideLoader()
      return;
    } else {
      this.messageService.add({
        key: 'toastInfo',
        severity: 'error',
        summary: 'Erro',
        detail: 'Sessão ou Serviço inválido.',
      });
      this.loaderService.hideLoader()
      return;
    }
  }

  hasService(path: string) {
    const childern = this.router.config.find((c) => c.children);
    if(childern) {
      const service = childern.children?.find((c) => c.path == path);
      if(service) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

}
