<div class="row-expansion-content scale-in-ver-top" *ngIf="device">
  <div class="group">
    <div class="item">
      <ng-container *ngIf="device.online; else offlineTag">
        <p-tag styleClass="mr-2" icon="fas fa-check-circle" severity="success" value="Online"></p-tag>
    </ng-container>
    <ng-template #offlineTag>
        <p-tag icon="fas fa-times-circle" severity="danger" value="Offline"></p-tag>
    </ng-template>
    </div>

    <div class="item">
      <h2>Equipamento</h2>
      <p>{{ device.deviceName }}</p>
    </div>

    <div class="item">
      <h2>Endereço</h2>
      <p>{{ device.address }}</p>
    </div>

    <div class="item">
      <h2>Infrações</h2>
      <p>{{ device.infractions }}</p>
    </div>

    <div class="item">
      <h2>Passagens</h2>
      <p>{{ device.flowings }}</p>
    </div>

    <div class="item">
      <h2>Pesagens</h2>
      <p>{{ device.weighings }}</p>
    </div>
  </div>

  <div class="group">

    <div class="item">
      <h2>Temperatura Equipamento</h2>
      <p>{{ device.deviceTemperature == 0 ? '-' : device.deviceTemperature + " ºC" }}</p>
    </div>

    <div class="item">
      <h2>Temperatura Pavimento</h2>
      <p>{{ device.trackTemperature == 0 ? '-' : device.trackTemperature + " ºC" }}</p>
    </div>

    <div class="item">
      <h2>Última Falta De Energia</h2>
      <p>{{ device.lastEnergyBreak || '-'}}</p>
    </div>
    <div class="item">
      <h2>Ultima Vez Porta Aberta</h2>
      <p>{{ device.lastOpenedDoor || '-'}}</p>
    </div>

    <div class="item">
      <p-badge severity="success" [value]="'MLR 1.5.6'"></p-badge>
      <p-badge severity="success" [value]="'Sensor Fx1'"></p-badge>
      <p-badge severity="success" [value]="'Sensor Fx2'"></p-badge>
      <p-badge severity="success" [value]="'MLR 1.5.6'"></p-badge>
    </div>
    <div class="item">
      <p-badge severity="success" [value]="'Pan 01'"></p-badge>
      <p-badge severity="success" [value]="'MLR 2.32.6'"></p-badge>
      <p-badge severity="success" [value]="'Cam Fx1'"></p-badge>
      <p-badge severity="success" [value]="'Cam Fx2'"></p-badge>
      <p-badge severity="success" [value]="'WIM'"></p-badge>
    </div>
  </div>

  <div class="group">
    <div class="grid">
      <div class="col-12">
        <p-card>
          <p>Aproveitamento Captura</p>
          <p-progressBar
            [value]="device.captureUse"
            [style]="{ height: '16px' }"
          ></p-progressBar>
          <p class="pt-1">Aproveitamento OCR</p>
          <p-progressBar
            [value]="device.captureOCR"
            [style]="{ height: '16px' }"
          ></p-progressBar>
          <p class="pt-1">Aproveitamento Captura Diurno</p>
          <p-progressBar
            [value]="device.captureUseDay"
            [style]="{ height: '16px' }"
          ></p-progressBar>
          <p class="pt-1">Aproveitamento Captura Noturno</p>
          <p-progressBar
            [value]="device.captureUseNight"
            [style]="{ height: '16px' }"
          ></p-progressBar>
        </p-card>
      </div>
    </div>
  </div>
</div>
