import { Injectable } from '@angular/core';
import { DeviceEvent, DeviceEventGrouped, DeviceEventMap } from '../models/device-event.model';
import { BaseService } from './base.service';
import { Device } from '../models/device.model';
import { DeviceEventType } from '../shared/enums/device-event-type.enum';
import { Filters } from '../models/filters.model';
import { DataTablePage } from '../models/data-table-page.model';
import moment from 'moment';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseService {

  urlLegacyAPI: string = `${environment.baseApi}legacy/api`;
  urlViolationList: string = `/eventViolation/list?maxResultCount={maxcount}&skipCount={skipcount}`;

  constructor(
    public override httpClient: HttpClient,
    private userService: UserService) {
    super(httpClient);
  }

  public getDeviceCam(deviceId: any): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.api + "api/v1/device/getDeviceCams/?deviceId=" + deviceId);
  }

  getDevices(): Observable<Array<Device>> {
    const url: string = this.getEndpoint('DEVICES') + "?sorting=id asc&maxresultcount=1000";
    return this.getData(url)
      .pipe(
        map((response) => {
          if (response) {
            return response;
          }
          return [];
        })
      );
  }

  getDevicesGroupId(groupId: number) {
    const url: string = this.getEndpoint('DEVICES') + '?' + 'groupId=' + groupId;
    return this.getData(url);
  }

  getEquipmentGroupId(groupId: number) {
    const url: string = this.getEndpoint('EQUIPMENT') + '?' + 'groupId=' + groupId;
    return this.getData(url);
  }

  getDeviceGroupEvents(
    deviceId: number,
    maxcount: number,
    eventTypes: Array<DeviceEventType>
  ): Observable<DeviceEventGrouped> {
    let url: string = this.buildUrlQueryParams(
      {
        deviceId,
        maxcount,
        skipcount: 0,
      },
      this.getEndpoint('DEVICES_EVENTS')
    );

    if (eventTypes) {
      eventTypes.forEach((ev) => {
        url += '&eventTypes=' + ev;
      });
    }

    return this.getData(url)
      .pipe(
        map((response) => {
          const events: DeviceEventGrouped = {
            flowEvents: [],
            weighingsEvents: [],
          };
          if (response && response.items) {
            const flowEvents: Array<DeviceEvent> = (
              response.items as DeviceEvent[]
            ).filter((ev) => ev.type == DeviceEventType.FLOW);
            (flowEvents || []).forEach((ev) => {
              events.flowEvents.push(this.prepareEvent(ev));
            });
            const weighingsEvents: Array<DeviceEvent> = response.items.filter(
              (ev: any) => ev.type == DeviceEventType.WEIGHING
            );
            (weighingsEvents || []).forEach((ev) => {
              events.weighingsEvents.push(this.prepareEvent(ev));
            });
          }
          return events;
        })
      );
  }

  getDeviceEventsMap(
    deviceId: number,
    maxcount: number,
    eventTypes: Array<DeviceEventType>
  ): Observable<DeviceEventGrouped> {
    let url: string = this.buildUrlQueryParams(
      {
        deviceId,
      },
      this.getEndpoint('EVENTS_MAP')
    );

    if (eventTypes) {
      eventTypes.forEach((ev) => {
        url += '&eventTypes=' + ev;
      });
    }

    return this.getData(url)
      .pipe(
        map((response) => {
          const events: DeviceEventGrouped = {
            flowEvents: [],
            weighingsEvents: [],
          };
          if (response) {
            const flowEvents: Array<DeviceEventMap> = (
              response as DeviceEventMap[]
            ).filter((ev) => ev.event_type == DeviceEventType.FLOW);
            (flowEvents || []).forEach((ev) => {
              events.flowEvents.push(this.prepareEvent(ev));
            });
            const weighingsEvents: Array<DeviceEvent> = response.filter(
              (ev: any) => ev.event_type == DeviceEventType.WEIGHING
            );
            (weighingsEvents || []).forEach((ev) => {
              events.weighingsEvents.push(this.prepareEvent(ev));
            });
          }
          return events;
        })
      );
  }

  getDeviceEvents(
    page: DataTablePage,
    filters: Filters
  ): Observable<Array<DeviceEvent>> {
    let url: string = this.buildUrlQueryParams(
      {
        maxcount: page.size,
        skipcount: page.pageNumber,
      },
      this.getEndpoint('EVENTS')
    );

    if (filters.grupos) {
      url += '&groupId=' + filters.grupos.id;
    }

    if (filters.qfvClass) {
      if (Array.isArray(filters.qfvClass)) {
        filters.qfvClass.forEach(qfv => {
          url += '&qfvClass=' + qfv;
        })
      } else {
        url += '&qfvClass=' + filters.qfvClass;
      }
    }

    if (filters.type) {
      url += '&eventTypes=' + filters.type;
    }
    if (filters.device) {
      if (filters.device.length > 0) {
        url += '&equipmentsId=' + filters.device.join(',');
      }
    }
    if (filters.plate) {
      url += '&plate=' + filters.plate;
    }
    if (filters.lane) {
      url += '&lane=' + filters.lane;
    }
    if (filters.images) {
      url += '&images=' + filters.images;
    }

    if (filters.startDate && filters.endDate) {
      filters.startDate.setHours(0, 0, 0, 0);
      filters.endDate.setHours(23, 59, 59, 0);

      if (filters.startHour) {
        const startHourSplitted = String(moment(filters.startHour).format('HH:mm')).split(':');
        filters.startDate.setHours(
          (parseInt(startHourSplitted[0])),
          parseInt(startHourSplitted[1])
        );
      }
      if (filters.endHour) {
        const endHourSplitted = String(moment(filters.endHour).format('HH:mm')).split(':');
        filters.endDate.setHours(
          (parseInt(endHourSplitted[0])),
          parseInt(endHourSplitted[1])
        );
      }
      const startDate: string = moment(filters.startDate).format('YYYY-MM-DD HH:mm:ss');
      const endDate: string = moment(filters.endDate).format('YYYY-MM-DD HH:mm:ss');
      url += `&dataInicio=${startDate}&dataFim=${endDate}`;
    }

    return this.getData(url)
      .pipe(
        map((response) => {
          const events: Array<DeviceEvent> = [];
          if (response && response.itens) {
            response.itens.forEach((ev: any) => {
              events.push(this.prepareEvent(ev));
            });
          }
          page.totalElements = response.totalCount;
          page.totalPages = page.totalElements / page.size;
          return events;
        })
      );

  }

  getDeviceViolationEvents(
    page: DataTablePage,
    filters: Filters
  ): Observable<Array<any>> {
    let url: string = this.buildUrlQueryParams(
      {
        maxcount: page.size,
        skipcount: page.pageNumber,
      },
      this.urlLegacyAPI + '/eventViolation/list?maxResultCount={maxcount}&skipCount={skipcount}'
    );

    if (filters.grupos) {
      url += '&groupId=' + filters.grupos.contractId;
    }

    if (filters.device) {
      if (filters.device.length > 0) {
        url += '&equipmentsId=' + filters.device.join(',');
      }
    }
    if (filters.plate) {
      url += '&plate=' + filters.plate;
    }
    // novos imputs (retirar comentário ao validar e commitar)
    if (filters.lane) {
      url += '&lane=' + filters.lane;
    }

    if (filters.startDate && filters.endDate) {
      filters.startDate.setHours(0, 0, 0, 0);
      filters.endDate.setHours(23, 59, 59, 0);

      if (filters.startHour) {
        const startHourSplitted = String(moment(filters.startHour).format('HH:mm')).split(':');
        filters.startDate.setHours(
          (parseInt(startHourSplitted[0])),
          parseInt(startHourSplitted[1])
        );
      }
      if (filters.endHour) {
        const endHourSplitted = String(moment(filters.endHour).format('HH:mm')).split(':');
        filters.endDate.setHours(
          (parseInt(endHourSplitted[0])),
          parseInt(endHourSplitted[1])
        );
      }
      const startDate: string = moment(filters.startDate).format('YYYY-MM-DD HH:mm:ss');
      const endDate: string = moment(filters.endDate).format('YYYY-MM-DD HH:mm:ss');
      url += `&dataInicio=${startDate}&dataFim=${endDate}`;
    }

    return this.getData(url)
      .pipe(
        map((response) => {
          const events: Array<any> = [];
          if (response && response.items) {
            response.items.forEach((ev: any) => {
              events.push(ev);
            });
          }
          page.totalElements = response.totalCount;
          page.totalPages = page.totalElements / page.size;
          return events;
        })
      );

  }

  getEvent(weighingId: number): Observable<DeviceEvent> {
    let url: string = this.buildUrlQueryParams(
      {
        maxcount: 1,
        skipcount: 0,
      },
      this.getEndpoint('EVENTS')
    );

    url += '&WeighingId=' + weighingId;

    return this.getData(url)
      .pipe(
        map((response) => {
          let event: any = null;
          if (response && response.items) {
            event = this.prepareEvent(response.items[0]);
          }
          return event;
        })
      );
  }

  private prepareEvent(event: any): DeviceEvent {
    if (event.documents && event.documents.length > 0) {
      event.mainPhoto = event.documents.find((d: any) => d.mainDocument);
      event.photos = event.documents.filter(
        (d: any) => d.documentType == 'JPG'
      );
    }
    return event;
  }

  getDeviceMetrics() {
    const user = this.userService.user;
    let url: string = this.getEndpoint('DEVICE_METRICS');
    url += `?userId=${user.id}`
    return this.getData(url);
  }

  getDeviceMetricsbyId(deviceId: any) {
    let url: string = this.getEndpoint('DEVICE_METRICS_ID');
    url += '?deviceId=' + deviceId;
    return this.getData(url);
  }

  getOccupancyTruck() {
    let url: string = this.getEndpoint('TRUCK_OCCUPANCY_RATE');
    return this.getData(url);
  }
}
