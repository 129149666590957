import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EditUserComponent} from './edit-user/edit-user.component';
import {UsersComponent} from './users/users.component';
import {FunctionalitiesComponent} from './functionalities/functionalities.component';
import { RegisterContractComponent } from './register-contract/register-contract.component';
import { RegisterGroupComponent } from './register-group/register-group.component';

const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'edit-user',
    component: EditUserComponent,
  },
  {
    path: 'functionalities', component:
    FunctionalitiesComponent,
  },
  {
    path: 'register-contract', component:
    RegisterContractComponent,
  },
  {
    path: 'register-group', component:
    RegisterGroupComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAuthRoutingModule {
}
