<div class="dialog" *ngIf="dialogShow">
  <p-dialog
    [showHeader]="false"
    (onShow)="closeInfoWindow()"
    (onHide)="cleanDialog()"
    [modal]="true"
    [(visible)]="dialogShow"
    [style]="{ width: '75%', height: '70%' }"
    [baseZIndex]="10000"
  >
    <i
      class="pi pi-times"
      style="font-size: 1rem"
      (click)="closeEventsWindow()"
    ></i>
    <p-tabView (onChange)="tabChange($event)">
      <p-tabPanel header="Dispositivos">
        <p-accordion (onOpen)="getEvents($event)" class="accordion">
          <p-accordionTab *ngFor="let device of devices; let i = index">
            <div class="loading">
              <p-progressSpinner *ngIf="showSpinner"></p-progressSpinner>
            </div>
            <p-header id="header-tab-{{ i }}">{{device.completeName}}</p-header>
            <p
              *ngIf="
                flowEvents[i]?.length === 0 && weighingsEvents[i]?.length === 0
              "
            >
              Sem eventos
            </p>
            <p *ngIf="flowEvents[i]?.length > 0" class="header">
              Últimas passagens
            </p>
            <div *ngIf="flowEvents[i]?.length > 0" class="rFull">
              <p-button
                icon="pi pi-arrow-left"
                (click)="previousPageFlow(i)"
                id="previousPage"
                class="previous"
              ></p-button>
              <div class="full" id="fullFlow-{{ i }}">
                <div
                  class="card"
                  *ngFor="let flow of flowEvents[i]; let i = index"
                  id="card"
                  (click)="eventsDetails(i, flow, device.deviceName)"
                >
                  <img
                    *ngIf="flow.documents?.length > 0"
                    src="{{ getMainImage(flow.documents) }}"
                  />
                  <img
                    *ngIf="flow.documents?.length === 0"
                    src="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png"
                    alt="teste"
                  />
                  <div class="text">
                    <p class="date">
                      {{ flow.date | date : "dd/MM/yyyy, HH:mm:ss" }}
                    </p>
                    <p *ngIf="flow.plate != ''">Placa: {{ flow.plate }}</p>
                    <p *ngIf="flow.plate === ''">Placa: -</p>
                    <p>Velocidade: {{ flow.speed }}Km/h</p>
                  </div>
                </div>
              </div>
              <p-button
                icon="pi pi-arrow-right"
                (click)="nextPageFlow(i)"
                id="nextPage"
                class="next"
              ></p-button>
            </div>
            <p *ngIf="weighingsEvents[i]?.length > 0" class="header">
              Últimas pesagens
            </p>
            <div *ngIf="weighingsEvents[i]?.length > 0" class="rFull">
              <p-button
                icon="pi pi-arrow-left"
                (click)="previousPageWeight(i)"
                id="previousPage"
                class="previous"
              ></p-button>
              <div class="full" id="fullWeight-{{ i }}">
                <div
                  class="card"
                  *ngFor="let weighings of weighingsEvents[i]; let i = index"
                  id="card"
                  (click)="eventsDetails(i, weighings, device.deviceName)"
                >
                  <img
                    *ngIf="weighings.documents.length > 0"
                    src="{{ getMainImage(weighings.documents) }}"
                  />
                  <img
                    *ngIf="weighings.documents.length === 0"
                    src="https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png"
                  />
                  <div class="text" *ngIf="weighings.length != 0">
                    <p class="date">
                      {{ weighings.date | date : "dd/MM/yyyy, HH:mm:ss" }}
                    </p>
                    <p *ngIf="weighings.plate != ''">
                      Placa: {{ weighings.plate }}
                    </p>
                    <p *ngIf="weighings.plate === ''">Placa: -</p>
                    <p>PBT: {{ weighings.pbt }}Kg</p>
                  </div>
                </div>
              </div>
              <p-button
                icon="pi pi-arrow-right"
                (click)="nextPageWeight(i)"
                id="nextPage"
                class="next"
              ></p-button>
            </div>
          </p-accordionTab>
        </p-accordion>
      </p-tabPanel>
      <p-tabPanel header="Total de Passagem por Hora">
        <div class="switch">
          <i class="pi pi-list icon-switch"></i
          ><p-inputSwitch [(ngModel)]="checkedThree"></p-inputSwitch
          ><i class="pi pi-chart-bar icon-switch"></i>
        </div>
        <div class="div-grafico">
          <div class="dropdown">
            <span class="p-float-label">
              <p-dropdown [options]="devices" [(ngModel)]="selectTipoDispositivoflowTwo" optionLabel="deviceName"
                          inputId="dispositivos_select_item"
                          (onClear)="gatilhoEventoDispositivo($event); selectItemFiltroFlow = ''"
                          [filter]="true" filterBy="completeName" [showClear]="true" placeholder="Dispositivos">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectTipoDispositivoflowTwo">
                    <div>{{selectTipoDispositivoflowTwo?.completeName}}</div>
                  </div>
                </ng-template>
                <ng-template let-device pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ device.completeName }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="dispositivos_select_item">Dispositivos</label>
            </span>

            <span class="p-float-label" *ngIf="selectTipoDispositivoflowTwo && selectTipoDispositivoflowTwo != ''">
              <p-dropdown [options]="tipoFiltroOptions" [(ngModel)]="selectTipoFiltroFlow"
                          optionLabel="name"
                          inputId="tipo_filtro_select_item"
                          (onChange)="filtroAverageFlow()"
                          (onClear)="selectItemFiltroFlow = ''"
                          [filter]="false" [showClear]="true" placeholder="Tipo Filtro">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectTipoFiltroFlow">
                    <div>{{selectTipoFiltroFlow?.name}}</div>
                  </div>
                </ng-template>
                <ng-template let-tipo pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ tipo.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="tipo_filtro_select_item">Tipo Filtro</label>
            </span>


            <span class="p-float-label"
                  *ngIf="selectTipoDispositivoflowTwo && selectTipoDispositivoflowTwo != '' && selectTipoFiltroFlow?.code === 'hora'">
              <p-dropdown [options]="list_select_AverageFlow"
                          [(ngModel)]="selectItemFiltroFlow"
                          inputId="item_filtro_select"
                          (onChange)="gatilhoItemFiltroFlow()"
                          (onClear)="selectItemFiltroFlow = ''"
                          [filter]="false" [showClear]="true" placeholder="Item do Filtro">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="selectItemFiltroFlow">
                    <div>{{selectItemFiltroFlow}}</div>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>{{ item }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="item_filtro_select">Item do Filtro</label>
            </span>

            <span class="p-float-label"
                  *ngIf="selectTipoDispositivoflowTwo && selectTipoDispositivoflowTwo != '' && selectTipoFiltroFlow?.code === 'dia'">
              <p-calendar [(ngModel)]="selectItemFiltroFlow"
                          [readonlyInput]="true"
                          (onSelect)="gatilhoItemFiltroFlow()"
                          [showClear]="true"
                          (onClear)="selectItemFiltroFlow = ''"
                          inputId="filtroDia"
                          dateFormat="dd.mm.yy"></p-calendar>
              <label for="filtroDia">Data</label>
            </span>

          </div>
        </div>
        <div
          class="table-reports-container"
          *ngIf="!checkedThree"
          style="position: relative"
        >
          <div class="loading-table" *ngIf="loading">
            <p-progressSpinner [style]="{ color: 'blue' }"></p-progressSpinner>
          </div>
          <div class="table-div">
            <p-table [value]="list_averageFlow">
              <ng-template
                pTemplate="header"
                style="width: 100%; text-align: center"
              >
                <tr>
                  <th>Hora</th>
                  <th>Periodo Anterior</th>
                  <th>Total Passagem</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-results
                style="width: 100%"
                let-rowIndex="rowIndex"
              >
                <tr>
                  <td>
                    <label>{{ results.hour }}</label>
                  </td>
                  <td>
                    <label>{{ results.today }}</label>
                  </td>
                  <td>
                    <label>{{ results.flowTotal }}</label>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div *ngIf="!selectItemFiltroFlow" class="graficoall">
          <h4>Preencha os filtros</h4>
        </div>
        <div *ngIf="checkedThree && selectItemFiltroFlow" class="graficoall">
          <p-chart
            type="line"
            [data]="averageSpeedFlowData"
            [style]="{ width: '60%' }"
          ></p-chart>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Alertas">
        <div *ngFor="let deviceAlert of alerts">
          <h2 class="font-semibold my-3">{{ deviceAlert.deviceName }}</h2>
          <p *ngIf="deviceAlert.alerts.length < 1">Dispositivo sem alertas</p>
          <div *ngIf="deviceAlert.alerts.length > 0" class="grid">
            <div
              *ngFor="let alert of deviceAlert.alerts"
              (click)="openAlertEvent(alert)"
              class="p-1 col-4"
            >
              <div
                class="flex align-items-center justify-content-around h-6rem text-sm font-semibold border-solid border-1 border-200 m-1 p-1"
              >
                <div class="w-4">
                  <img
                    class="mr-1"
                    [src]="
                      alert.urlImage
                        ? alert.urlImage
                        : 'https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled.png'
                    "
                    width="100"
                  />
                  <!-- <i *ngIf="alert.icon" [class]="alert.icon" [style]="{'font-size': '2rem','text-align':'center','width':'100px'}"></i> -->
                </div>
                <div class="flex flex-column justify-content-between ml-1 w-8">
                  <p class="my-1">{{ alert.createdAt | date : "dd/MM/yyyy"}}</p>
                  <p class="my-1">Placa: - {{ alert.plate }}</p>
                  <p class="my-1">{{getAlertTypeString(alert.alertType)}}</p>
                  <p
                    *ngIf="alert.alertType === 'ALERTA_EXCESSO_VELOCIDADE'"
                    class="my-1"
                  >
                    Velocidade: {{ alert.speed }}Km/h
                  </p>
                  <p
                    *ngIf="alert.alertType === 'ALERTA_EXCESSO_PESO'"
                    class="my-1"
                  >
                    PBT: {{ alert.overweight }}Kg
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="loading">
          <p-progressSpinner *ngIf="showSpinnerAlerts"></p-progressSpinner>
        </div>
      </p-tabPanel>
    </p-tabView>
  </p-dialog>
</div>

<real-time-events-details-window
  *ngIf="openDetails"
  [(showDetails)]="openDetails"
  [(detailsData)]="details"
></real-time-events-details-window>

<real-time-alert-event-detail
  [(showDetails)]="openAlertDetails"
  [(alertData)]="details"
></real-time-alert-event-detail>

<p-toast position="top-right" key="c"></p-toast>
<p-toast position="top-right" key="d"></p-toast>
