import {HostListener, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GaleriaCommandsService {

  containerStyle = { 'max-width': '85%' };

  responsiveOptions: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  constructor() { }

}
