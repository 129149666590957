<p-dialog
  [(visible)]="showDetails"
  (onHide)="cleanDialog()"
  [modal]="true"
  [closeOnEscape]="false"
  styleClass="w-full h-full"
  *ngIf="detailsData"
>
  <div class="grid">
    <div [ngClass]="{'col-6': detailsData.urlImage, 'col-12': !detailsData.urlImage}">
      <div class="grid">
        <div class="col-12 col-only-padding-left-right">
          <h4>Infração: {{ detailsData.id || '-' }}
          </h4>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Cidade: {{ detailsData.city || "-" }}</p>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Direção: {{ detailsData.sense || "-" }}</p>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Rua: {{ detailsData.address|| "-" }}</p>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Numero da faixa: {{ detailsData.lane || "-" }}</p>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="grid">
        <div class="col-12 col-only-padding-left-right">
          <p>Enquadramento: {{ detailsData.violationType || "-" }}</p>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Data: {{ detailsData.violationDate | date : "dd/MM/yyyy, HH:mm:ss" }}</p>
        </div>
        <div class="col-6 col-only-padding-left-right">
          <p>Vel. Medida: {{ detailsData.speed ? (detailsData.speed + ' km/h') : "-" }}</p>
        </div>
      </div>
      <p-divider></p-divider>
      <div class="grid">
        <div class="col-6 col-only-padding-left-right">
          <p>Tipo veículo: {{ detailsData.vehicleClassification || "-"}}</p>
        </div>
      </div>
      <p-divider></p-divider>
    </div>
    <div class="col-6">
      <p-galleria
        *ngIf="loaded"
        [value]="detailsData.urlImage || []"
        [attr.id]="detailsData.id"
        thumbnailsPosition="right"
        [showIndicators]="false"
        [showThumbnails]="!!detailsData.urlImage && detailsData.urlImage.length > 1"
        [(activeIndex)]="indexSelecionado"
        #carousel
        [circular]="false"
      >
        <ng-template pTemplate="item" let-item>
          <div>
            <div class="border-1 surface-border border-round m-2 text-center py-3 px-3">
              <img class="w-full cursor-pointer" (click)="fullscrennImage(item, indexSelecionado)" [src]="item" alt="image"/>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="grid grid-nogutter justify-content-center">
            <img [src]="item" width="80" height="60"
                tooltipPosition="left"
                alt="thumbnail"/>
          </div>
        </ng-template>
      </p-galleria>
    </div>
  </div>
</p-dialog>

<p-galleria
  [value]="detailsData.urlImage || []"
  [(visible)]="fullscreenImg"
  [activeIndex]="indexSelecionado"
  [responsiveOptions]="galeriaService.responsiveOptions"
  [containerStyle]="galeriaService.containerStyle"
  [circular]="true"
  [fullScreen]="true"
  [showItemNavigators]="true"
  [showThumbnails]="false"
>
  <ng-template pTemplate="item" let-item>
    <img [src]="item" class="w-full" alt="image"/>
  </ng-template>
</p-galleria>
