import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';

import Hls from 'hls.js';

@Component({
  selector: 'shared-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss']
})
export class VideoDialogComponent {
  @Input() videoDisplay: boolean = false;

  @Input() urlCam!: string;
  @Input() port!: number;

  @Output() videoDisplayChange = new EventEmitter<boolean>();
  isVideoDisplay = false;
  videoUrl = 'https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png';
  sanitizeUrl!: SafeResourceUrl;
  showError = false;

  hasVideo = false;
  loading = true;

  constructor(
    private messageService: MessageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {

    this.hasVideo = false;
    this.loading = true;

    if (this.videoDisplay === true) {
      setTimeout(() => this.loadVideo(), 1000);
    }
  }

  closeVideo() {
    this.videoUrl = '';
    window.stop();
    setTimeout(() => {
      this.videoDisplayChange.emit(false);
    }, 10);
  }


  openVideoAnyway() {
      window.open(this.urlCam, '_blank');
  }

  loadVideo() {
    this.loading = false;

    if(!!this.port && !!this.urlCam) {
      this.hasVideo = true;
      document.cookie = 'port=' + this.port + ';Path=/;domain=appvelsis.com;SameSite=None;Secure';
      this.videoUrl = this.urlCam + '&cache_port=' + this.port;
    }
    else {
      this.videoUrl = 'https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png';
      this.hasVideo = false;
    }
  }


}
