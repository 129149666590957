<div class="px-6">
  <p-toast position="top-right" key="toastSuccess"></p-toast>
  <p-toast position="top-right" key="toastError"></p-toast>
  <div class="grid my-2 ">
    <div class="col-8">
      <button (click)="openNewUser()" pButton type="button" label="Novo usuário" ></button>
    </div>
  </div>
  <p-table
  [value]="users"
  [tableStyle]="{'min-width': '100%'}"
  [loading]="loading"
  [paginator]="true"
  [rows]="20"
  >
  <ng-template pTemplate="header">
      <tr [style]="{'cursor':'default'}">
        <th class="text-center">Nome</th>
        <th style="width:10%" class="text-center"></th>
        <th style="width:10%" class="text-center">Status</th>
        <th style="width:10%"  class="text-center"></th>
        <th class="text-center">Ação</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
      <tr [style]="{'cursor':'default'}">
        <td class="text-center">{{user.userName}}</td>
        <td class="text-right">Bloqueado</td>
        <td class="text-center table-toggle">
          <p-inputSwitch (onChange)="blockedChange($event, user)" [(ngModel)]="!user.blocked"></p-inputSwitch>
        </td>
        <td class="text-left">Liberado</td>
        <td class="flex justify-content-around">
          <button (click)="openEdit(user)" class="p-button-warning" pButton type="button" label="Editar" ></button>
          <button (click)="deleteUser(user.id)" class="p-button-danger" pButton type="button" label="Excluir" ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
