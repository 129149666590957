import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'real-time-devices-layer',
  templateUrl: './devices-layer.component.html',
  styleUrls: ['./devices-layer.component.scss']
})
export class DevicesLayerComponent implements OnInit, OnChanges {
  isVisible = true;
  activateAnimation = false;


  styleDisabled = {
    width: '210px',
    height: '60px'
  }

  open: boolean = false;


  styleActived = {
    width: '30%',
    height: '80vh'
  }

  @Input() dataMetrics: any;
  @Output() goToLocation: EventEmitter<any> = new EventEmitter();

  dataMetricsFiltered: any[] = [];
  dataMetricToGetStatus: any[] = [];

  style = this.styleDisabled;

  timerOut: any;
  filter: any = '';

  filterByStatus: any = (d: any) => d;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataMetricsFiltered = changes['dataMetrics']['currentValue']
    this.dataMetricsFiltered = this.orderByLocale(this.dataMetrics)
    if(this.dataMetricToGetStatus.length === 0) this.dataMetricToGetStatus = JSON.parse(JSON.stringify(this.dataMetricsFiltered));
  }

  getStatus() {
    // 0 - off
    // 1 - amarelo - sem passagem
    // 2 - on
    // 3 - suspenso
    // outro - cinza escuro

    const devicesStatus = this.dataMetricToGetStatus.map((d: any) => d.statusOnline) as any[];
    const off = devicesStatus.filter((d: any) => d == 0).length
    const noPass = devicesStatus.filter((d: any) => d == 1).length
    const on = devicesStatus.filter((d: any) => d == 2).length
    const suspended = devicesStatus.filter((d: any) => d == 3).length
    const other = devicesStatus.filter((d: any) => d != 0 && d != 1 && d != 2 && d != 3).length
    const total = devicesStatus.length


    return { off, on, suspended, noPass, other, total }
  }

  setFilterStatus(type: 'on' | 'suspended'| 'other' | 'no-pass' | 'total' | 'off') {
    if(type == 'off') {
      this.filterByStatus = (d: any) => d.statusOnline == 0
    } else if(type == 'no-pass') {
      this.filterByStatus = (d: any) => d.statusOnline == 1
    } else if(type == 'on') {
      this.filterByStatus = (d: any) => d.statusOnline == 2
    } else if(type == 'suspended') {
      this.filterByStatus = (d: any) => d.statusOnline == 3
    } else if(type == 'other') {
      this.filterByStatus = (d: any) => (d.statusOnline != 0 && d.statusOnline != 1 && d.statusOnline != 2 && d.statusOnline != 3);
    }else if(type == 'total') {
      this.filterByStatus = (d: any) => d
    }

    this.dataMetricsFiltered = this.orderByLocale(this.dataMetrics).filter((data: any) =>
    data.deviceName.toLowerCase().includes(this.filter.toLowerCase())
    || data.streetName.toLowerCase().includes(this.filter.toLowerCase())
  )
  }



  selectDevice(item: any): void {
    this.goToLocation.emit({ lat: Number(item.latitude), lng: Number(item.longitude) })
  }

  orderByLocale(data: any[]) {
    let formated = data.reduce((devices: any, obj: any) => {
      const index = devices.findIndex((obj2: any) => obj2.deviceName === obj.deviceName);
      if (index === -1) {
        devices.push(obj);
      }
      return devices;
    }, []);

    let sort = formated.sort((a: any, b: any) => {
      const aCase = a.deviceName.toLowerCase();
      const bCase = b.deviceName.toLowerCase();

      return aCase.localeCompare(bCase);
    });

    sort = sort.sort((a: any, b: any) => {
      const isA = a.statusOnline;
      const isB = b.statusOnline;

      if (isA && !isB) {
        return -1;
      } else if (!isA && isB) {
        return 1;
      } else {
        return 0;
      }
    });
    formated = formated.filter(this.filterByStatus)
    return formated
  }

  async search(event: any) {
    if (this.timerOut) {
      clearTimeout(this.timerOut)
    }
    if (event.target.value == '') {
      this.filter = '';
      this.dataMetricsFiltered = this.orderByLocale(this.dataMetrics)
      return;
    }
    this.timerOut = setTimeout(async () => {
      this.filter = event.target.value
      this.dataMetricsFiltered = this.orderByLocale(this.dataMetrics).filter((data: any) =>
        data.deviceName.toLowerCase().includes(this.filter.toLowerCase())
        || data.streetName.toLowerCase().includes(this.filter.toLowerCase())
      )
    }, 1000);
  }


  updateStyle() {
    this.open ? this.style = this.styleDisabled : this.style = this.styleActived
    this.open = !this.open;
    this.activateAnimation = !this.activateAnimation;
  }

}
