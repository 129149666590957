import {inject} from "@angular/core";
import {UserService} from "../../service/user.service";
import {Role} from "../../shared/enums/role.enum";

export const AuthGuard = (roles?: Role | Role[]) => {
  const authService = inject(UserService);
  if (!authService.isLogged()) {
    authService.efetuarLogout();
    return false;
  }

  if (roles && authService.checkForcePassword()) {
    authService.redirectToChangePassword();
    return false;
  }

  if (roles && !authService.hasRoles(roles)) {
    // authService.efetuarLogout();
    authService.redirectToHome();
    return false;
  }


  return true;
};
