<div class="videoDialog">
  <p-dialog
  id="videoDialogStyle"
  class="videoDialog"
  [showHeader]="false"
  [closable]="false"
  [(visible)]="videoDisplay"
  position="bottomleft"
  >
    <p><i class="pi pi-times" style="font-size: 1rem" (click)="closeVideo()"></i><p>
    <div>
      <p-progressSpinner class="loading" *ngIf="loading"></p-progressSpinner>
      <img *ngIf="!loading && hasVideo" onerror="this.src='https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png'" width="600" controls src="{{ videoUrl }}" />

      <img *ngIf="!loading && !hasVideo" width="300"

      src="https://prod-salvador.s3.us-west-2.amazonaws.com/defaultpic.png" />
    </div>
  </p-dialog>
</div>
