<div class="layer" [ngStyle]="style" [class.scale-in-tr]="activateAnimation">
  <div class="flex align-items-center mt-1 justify-content-center gap-2" (click)="updateStyle()">
    <label class="ml-2">Mostrar dispositivos</label>
    <i class="pi pi-chevron-down"></i>
  </div>

  <ng-container *ngIf="open">
    <span class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input type="text" [value]="filter" pInputText placeholder="Nome do Equipamento" (input)="search($event)" />
    </span>

    <div class="actions">
      <p>Filtrar</p>
      <div class="badges">
        <div class="badge total" tooltipPosition="top" pTooltip="Todos os Equipamentos" (click)="setFilterStatus('total')"><i class="fas fa-list mr-1"></i> {{ getStatus().total }}</div>
        <div class="badge" tooltipPosition="top" pTooltip="Equipamentos Online" (click)="setFilterStatus('on')"><i class="fas fa-check-circle mr-1"></i> {{ getStatus().on }}</div>
        <div class="badge nopass" tooltipPosition="top" pTooltip="Equipamentos Sem Passagem" (click)="setFilterStatus('no-pass')"><i class="fas fa-exclamation-circle mr-1"></i> {{ getStatus().noPass }}</div>
        <div class="badge danger" tooltipPosition="top" pTooltip="Equipamentos Offline" (click)="setFilterStatus('off')"><i class="fas fa-times-circle mr-1"></i> {{ getStatus().off }}</div>
        <div class="badge warn" tooltipPosition="top" pTooltip="Equipamentos Suspensos" (click)="setFilterStatus('suspended')"><i class="fas fa-ban mr-1"></i> {{ getStatus().suspended }}</div>
        <div class="badge other" tooltipPosition="top" pTooltip="Outros Equipamentos" (click)="setFilterStatus('other')"><i class="fas fa-ellipsis-h mr-1"></i> {{ getStatus().other }}</div>
      </div>
    </div>

    <div class="items" *ngIf="open">
      <div class="item" *ngFor="let item of dataMetricsFiltered; let i = index" (click)="selectDevice(item)"

      [class.nopass]="item.statusOnline == 1"
      [class.disabled]="item.statusOnline == 0"
      [class.warn]="item.statusOnline == 3"
      [class.other]="item.statusOnline != 0 && item.statusOnline != 1 && item.statusOnline != 2 && item.statusOnline != 3">
        <h6>{{ item.deviceName }} - {{ item.streetName }}</h6>
        <i class="fa-solid fa-location-dot" style="font-size: 0.8rem"></i>
      </div>
    </div>

  </ng-container>
</div>
