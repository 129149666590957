import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NotixService {

  constructor() {
  }

  createNotix(): void {
    if (!environment.production)
      return;

    const s = document.createElement("script");
    s.src = "https://notix.io/ent/current/enot.min.js";
    s.onload = function (sdk) {
      // @ts-ignore
      sdk.startInstall({
        appId: "100595b66642e265aba1a22a1520933",
        loadSettings: true,
      });
    };
    document.head.append(s);
  }
}
