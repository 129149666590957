<ng-container *ngIf="violation != null">
  <p-dialog
  header="Análise de Infração - {{violation.nomeExternoEquipamento}}"
  [(visible)]="showViolations"
  [closable]="false"
  (onHide)="cleanDialog()"
  [modal]="true"
  [closeOnEscape]="false"
  [style]="{ width: '70%', height: '80%' }"
  [draggable]="false"
  [resizable]="false"
  *ngIf="showViolations"
 >
 <i class="pi pi-times" style="font-size: 1.5rem" (click)="reset()"></i>

  <div class="full"
  [ngStyle]="violation.imagesAndVideos.length > 1 ? { 'flex-direction': 'column' } : { 'justify-content': 'space-between' }"
  >
    <div class="violation">

      <div *ngIf="violation.imagesAndVideos && violation.imagesAndVideos.length > 0"  class="imageCard">
        <div
          class="image"
          *ngFor="let image of violation.imagesAndVideos; let index = index;"
          (click)="fullscrennImage(image.caminho, index)"
        >
          <img src="{{ image.caminho }}" width="{{ violation.imagesAndVideos.length > 1 ? '250' : '400' }}" />
        </div>
      </div>
      <p class="name" *ngIf="violation.nomeExternoEquipamento">{{ violation.nomeExternoEquipamento }}</p>
      <label *ngIf="violation.flowModel.city">
          {{ violation.flowModel.street_name }},
          {{ violation.flowModel.city }} -
          {{ violation.flowModel.state }},
          {{ violation.flowModel.country }}
      </label>

      <div class="footer">
        <button
          pButton
          pRipple
          icon="pi pi-check"
          type="button"
          label="Válida"
          (click)="valid()"
          class="p-button-outlined p-button-success p-button-normal"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-times"
          type="button"
          label="Inválida"
          (click)="invalid()"
          class="p-button-danger p-button-outlined p-button-normal"
        ></button>
        <button
          pButton
          pRipple
          icon="pi pi-trash"
          type="button"
          label="Ignorar"
          (click)="ignore()"
          class="p-button-outlined p-button-warning p-button-normal"
        ></button>
      </div>
    </div>

    <div class="detail">
      <div class="item">

        <label>Data e Hora</label>
        <p>{{violation.dataInfracao | date : "dd/MM/yyyy, HH:mm:ss"}}</p>
      </div>
      <div class="item">
        <label>Direção</label>
        <p>{{ violation.flowModel.flow_direction || "-" }}</p>
      </div>
      <div class="item">
        <label>Pista</label>
        <p>{{ violation.flowModel.lane_identification || "-" }}</p>
      </div>
      <div class="item">
        <label>Placa</label>
        <p>{{  violation.flowModel.plate || "-" }}</p>
      </div>
      <div class="item">
        <label>Serial</label>
        <p>{{  violation.serialEquipamento || "-" }}</p>
      </div>

      <div class="item">
        <label>Descrição da Infração</label>
        <p>{{  getInfractionData(violation.tipoInfracao).description || "-" }}</p>
      </div>

      <div class="item">
        <label>Enquadramento</label>
        <p>{{  getInfractionData(violation.tipoInfracao).framework || "-" }}</p>
      </div>

      <div class="item">
        <label>Velocidade Regulamentada</label>
        <p>{{  violation.velocidadeRegulamenta + ' km/h' || "-" }}</p>
      </div>

      <div class="item">
        <label>Velocidade da infração</label>
        <p>{{  violation.velocidadeInfracao + ' km/h' || "-" }}</p>
      </div>

      <div class="item">
        <label>Velocidade Medida</label>
        <p>{{  violation.velocidadeMedida + ' km/h' || "-" }}</p>
      </div>

      <div class="item">
        <label>Velocidade Considerada</label>
        <p>{{  violation.velocidadeConsiderada + ' km/h' || "-" }} </p>
      </div>

      <div class="item">
        <label>Comprimento</label>
        <p>{{ violation.flowModel.vehicle_lenght > 0 ? (violation.flowModel.vehicle_lenght + ' cm') : "-" }}</p>
      </div>

      <div class="item">
        <label [ngClass]="violation.flowModel.distance_from_previous_vehicle == 0 ? 'esconder' : ''">Distância veículo anterior</label>
        <p>
          {{
            violation.flowModel.distance_from_previous_vehicle == 0
          ? " - "
          : violation.flowModel.distance_from_previous_vehicle
          }}
        </p>
      </div>

      <!-- <div class="item" *ngIf="!!violation.flowModel.pbt">
        <label>PBT</label>
        <p>{{ !!violation.flowModel.pbt ? violation.flowModel.pbt+' kg' : "-" }}</p>
      </div>
      <div class="item" *ngIf="!!violation.flowModel.classifications_pbt_limit">
        <label>Limite PBT da Classificação</label>
        <p>{{ !!violation.flowModel.classifications_pbt_limit ? violation.flowModel.classifications_pbt_limit +' kg' : "-" }}</p>
      </div>
      <div class="item" *ngIf="!!violation.flowModel.vehicle_classification">
        <label>Classificação do Veículo</label>
        <p>{{ violation.flowModel.vehicle_classification || "-" }}</p>
      </div>
      <div class="item" *ngIf="!!violation.flowModel.wheighing_classification">
        <label>Classificação da Pesagem</label>
        <p>{{ violation.flowModel.wheighing_classification || "-" }}</p>
      </div>
      <div class="item" *ngIf="!!violation.flowModel.axleJson && violation.flowModel.axleJson.length > 0">
        <label>Peso de cada eixo</label>
        <p>{{ violation.flowModel.axleJson.length > 0 ? getAxlesPbt(violation.flowModel.axleJson) : "-" }}</p>
      </div>
      <div class="item" *ngIf="!!violation.flowModel.pbt_excess">
        <label>Excesso PBT</label>
        <p>{{ !!violation.flowModel.pbt_excess ? violation.flowModel.pbt_excess > 0 ? violation.flowModel.pbt_excess+' kg' : "0 kg" : '-' }}</p>
      </div> -->
    </div>
  </div>
</p-dialog>

<p-galleria
    *ngIf="!!violation?.imagesAndVideos"
  [value]="violation?.imagesAndVideos ?? []"
  [(visible)]="fullscreenImg"
  [activeIndex]="indexSelecionado"
  [responsiveOptions]="galeriaService.responsiveOptions"
  [containerStyle]="galeriaService.containerStyle"
  [circular]="true"
  [fullScreen]="true"
  [showItemNavigators]="true"
  [showThumbnails]="false"
>
  <ng-template pTemplate="item" let-item>
    <img [src]="item.caminho" style="width: 100%; display: block;" alt="image"/>
  </ng-template>
</p-galleria>

<div class="fullscreen" *ngIf="false">
  <p-dialog
    [(visible)]="fullscreenImg"
    [modal]="true"
    [style]="{ width: '100%', height: '100%' }"
  >
    <img src="{{ imgUrl }}" />
  </p-dialog>
</div>

</ng-container>
