import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeviceEventMap } from 'src/app/models/device-event.model';
import { AddressDevice } from 'src/app/models/device.model';
import { GaleriaCommandsService } from 'src/app/service/galeria-commands.service';

class TmpDeviceEventMap extends DeviceEventMap {
  pbt!: string;
  classifications_pbt_limit!: number;
  vehicle_classification!: any;
  wheighing_classification!: any;
  axis_wheighing!: number;
  pbt_excess!: number;
  axleJson: any;
}

export class DeviceCustom {
  name!: string;
  category!: string;
  latitude!: string;
  longitude!: string;
  originId!: number;
  serialNumber!: string;
  addresses!: AddressDevice;
  id!: number;
}

export class Status {
  id!: number;
  lastComunication!: string;
  online!: boolean
}

@Component({
  selector: 'real-time-events-details-window',
  templateUrl: './events-details-window.component.html',
  styleUrls: ['./events-details-window.component.scss']
})
export class EventsDetailsWindowComponent {
  @Input() showDetails: boolean = false;
  @Output() showDetailsChange = new EventEmitter<boolean>();
  @Input() detailsData!: TmpDeviceEventMap;//TODO voltar com o tipo 'DeviceEventMap' quando a API estiver pronta
  @Output() detailsDataChange = new EventEmitter<any>();
  fullscreenImg: boolean = false;
  imgUrl: string = '';
  openDetails = false;
  selectedIndex: number = 0;
  constructor(public galeriaService: GaleriaCommandsService) { }

  ngOnInit(): void {

  }

  cleanDialog() {
    this.showDetailsChange.emit(false);
  }


  getAxlesPbt(axles: any[]): string{
    let ret = '';
    let i = 1;
    axles.forEach( (val) => {
      ret += ( 'Eixo '+i+': '+val.weight_axle + ' kg, ' );
      i++;
    })
    ret = ret.substring(0, ret.length - 2);
    return ret;
  }

  fullscrennImage(url: any, index: number) {
    this.selectedIndex = index;
    this.fullscreenImg = true;
    this.imgUrl = url;
  }
}
